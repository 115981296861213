import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import axios from '../../services/api'
import Input from '../Formik/Input'
import InputWrapper from '../Formik/InputWrapper'
import Label from '../Formik/Label'
import Button from '../Buttons/Button'
import Spinner from '../UI/Spinner'
import { useTranslation } from 'react-i18next'
import FormikErrorText from '../Formik/FormikErrorText'
import useWidth from '../../hooks/useWidth'
import FormikForm from '../Formik/FormikForm'
import { useAuthContext } from '../../context/auth/auth'

const ResetPasswordForm = () => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const { width } = useWidth()
  const { token, authClient } = useAuthContext()

  const initialValues = {
    password: '',
    passwordConfirmation: '',
  }

  const validationSchema = yup.object({
    password: yup
      .string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&.*])(?=.{8,})/,
        t('passwordMustBeAtLeast8CharactersAndContainAtLeastOneUppercaseLetterAndOneNumber')
      )
      .required(t('requiredField')),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref('password'), null], t('passwordsMustMatch'))
      .required(t('requiredField')),
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async values => {
      try {
        setIsLoading(true)
        const response = await axios.patch(
          `/auth/user/${authClient?.id}/`,
          {
            password: values.password,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )

        if (response.status === 200) {
          setIsLoading(false)
          toast.success(t('yourPasswordHasBeenChanged'))
          navigate('/login')
        }
      } catch (error) {
        setIsLoading(false)
        toast.error(t('failedToChangePassword'))
      }
    },
  })

  return (
    <FormikForm onSubmitHandler={formik.handleSubmit}>
      <div className='flex flex-col md:flex-row gap-7'>
        <InputWrapper width='w-full'>
          <Label text={t('password')} />
          <Input
            id='Password'
            as='input'
            type='password'
            placeholder={t('insertPassword')}
            hasError={formik.touched.password && formik.errors.password ? true : false}
            {...formik.getFieldProps('password')}
          />
          {formik.errors.password && <FormikErrorText errorText={formik.errors.password} />}
        </InputWrapper>
        <InputWrapper width='w-full'>
          <Label text={t('confirmPassword')} />
          <Input
            id='Confirm Password'
            as='input'
            type='password'
            placeholder={t('insertPassword')}
            hasError={
              formik.touched.passwordConfirmation && formik.errors.passwordConfirmation
                ? true
                : false
            }
            {...formik.getFieldProps('passwordConfirmation')}
          />
          {formik.errors.passwordConfirmation && (
            <FormikErrorText errorText={formik.errors.passwordConfirmation} />
          )}
        </InputWrapper>
      </div>
      <div className='flex justify-end mt-9 border-t-[1px] pt-7 border-solid'>
        <Button
          type='submit'
          text={isLoading ? 'Loading...' : t('changePassword')}
          width={width && width <= 640 ? 'min-w-full' : 'w-full'}
        >
          {isLoading && <Spinner spinner='form' />}
        </Button>
      </div>
    </FormikForm>
  )
}

export default ResetPasswordForm
