import * as yup from 'yup'
import { useFormik } from 'formik'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useStepContext } from '../../context/steps/StepsProvider'
import { useDataProviderContext, DataProviderType } from '../../context/dataProvider/DataProvider'
import { incidentOnGoingOptions } from '../../helpers/selectOptions'
import StepsButtons from './StepsButtons'
import FormStepTitle from '../UI/FormStepTitle'
import FormikErrorText from '../Formik/FormikErrorText'
import Input from '../Formik/Input'
import InputWrapper from '../Formik/InputWrapper'

const Step2 = () => {
  const { t } = useTranslation()
  const reportData = useDataProviderContext()
  const { step, setStep } = useStepContext()

  const validationSchema = yup.object({
    incidentDate: yup.string().trim().required(t('requiredField')),
    ongoing: yup.string().trim().required(t('requiredField')),
    involved: yup.string().trim().required(t('requiredField')),
    incidentOccur: yup.string().trim().required(t('requiredField')),
    description: yup.string().trim().required(t('requiredField')),
  })

  interface _Values {
    incidentDate: DataProviderType['incidentDate']
    ongoing: string
    involved: DataProviderType['involved']
    incidentOccur: DataProviderType['incidentOccur']
    description: DataProviderType['description']
  }

  const handleSubmit = (values: _Values) => {
    reportData.setters.setIncidentDate(values.incidentDate)
    reportData.setters.setOngoing(values.ongoing)
    reportData.setters.setInvolved(values.involved.trim())
    reportData.setters.setIncidentOccur(values.incidentOccur.trim())
    reportData.setters.setDescription(values.description.trim())
    setStep(step + 1)
  }

  const formik = useFormik({
    initialValues: {
      incidentDate: reportData.incidentDate || '',
      ongoing: reportData.ongoing || '',
      involved: reportData.involved || '',
      incidentOccur: reportData.incidentOccur || '',
      description: reportData.description || '',
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className=' my-3'>
        <div className='items-center my-6'>
          <FormStepTitle as='h1' text={t('questions')} />
        </div>

        <div className='md:grid-cols-2 grid gap-6 my-9'>
          <div>
            <FormStepTitle as='h2' text={t('whenDidTheIncidentOccur')} />
            <Input
              id='incidentDate'
              type='date'
              max={moment().format('YYYY-MM-DD')}
              layout='reporter'
              hasError={formik.touched.incidentDate && formik.errors.incidentDate ? true : false}
              {...formik.getFieldProps('incidentDate')}
            />
            {formik.errors.incidentDate && formik.touched.incidentDate && (
              <FormikErrorText errorText={formik.errors.incidentDate} />
            )}
          </div>
          <div>
            <FormStepTitle as='h2' text={t('isTheIncidentStillOngoing')} />
            <Input
              as='select'
              layout='reporter'
              selectOptions={incidentOnGoingOptions}
              id='ongoing'
              hasError={formik.touched.ongoing && formik.errors.ongoing ? true : false}
              {...formik.getFieldProps('ongoing')}
            />
            {formik.errors.ongoing && formik.touched.ongoing && (
              <FormikErrorText errorText={formik.errors.ongoing} />
            )}
          </div>
        </div>

        <InputWrapper type='reporter'>
          <FormStepTitle as='h2' text={t('whereDidTheIncidentOccur')} />
          <Input
            type='text'
            id='incidentOccur'
            placeholder={t('writeThePlaceOfTheIncident')}
            layout='reporter'
            hasError={formik.errors.incidentOccur && formik.touched.incidentOccur ? true : false}
            {...formik.getFieldProps('incidentOccur')}
          />
          {formik.errors.incidentOccur && formik.touched.incidentOccur && (
            <FormikErrorText errorText={formik.errors.incidentOccur} />
          )}
        </InputWrapper>
        <InputWrapper type='reporter'>
          <FormStepTitle as='h2' text={t('whoIsInvolvedAndWhatIsTheirCapacity')} />
          <p className='text-[rgba(102,102,102,1)]'>{t('nameSurnameAndTheirCapacity')}</p>

          <Input
            type='text'
            id='involved'
            layout='reporter'
            hasError={formik.errors.involved && formik.touched.involved ? true : false}
            placeholder={t('addTheNamesOfThePeopleInvolved')}
            {...formik.getFieldProps('involved')}
          />
          {formik.errors.involved && formik.touched.involved && (
            <FormikErrorText errorText={formik.errors.involved} />
          )}
        </InputWrapper>
        <InputWrapper type='reporter'>
          <FormStepTitle as='h2' text={t('fullDescriptionOfTheIncident')} />
          <Input
            as='textarea'
            id='description'
            placeholder={t('describeIncident')}
            rows={8}
            layout='reporter'
            hasError={formik.errors.description && formik.touched.description ? true : false}
            {...formik.getFieldProps('description')}
          />
          {formik.errors.description && formik.touched.description && (
            <FormikErrorText errorText={formik.errors.description} />
          )}
        </InputWrapper>
      </div>

      <StepsButtons
        hasNotError={
          formik.errors.incidentDate ||
          formik.errors.ongoing ||
          formik.errors.incidentOccur ||
          formik.errors.involved ||
          formik.errors.description
            ? true
            : false
        }
      />
    </form>
  )
}

export default Step2
