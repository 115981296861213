import { createContext, useContext, useEffect, useState } from 'react'
import useAxios from '../../hooks/useAxios'
import { OrganizationTypes } from '../../interfaces/organization'
import { ReportTypes } from '../../interfaces/report'
import { useAuthContext } from '../auth/auth'
import { Props } from '../auth/auth.interfaces'

interface ReportsCtxTypes {
  reports: ReportTypes[]
  selectedOrg: OrganizationTypes | null
  adminCompanyCode: string | null
  reportersReport: ReportTypes | null
  isReportLoading: boolean
  setReportersReport: (value: ReportTypes | null) => void
}

const reportsCtx = createContext<ReportsCtxTypes>({
  reports: [],
  selectedOrg: null,
  adminCompanyCode: null,
  reportersReport: null,
  isReportLoading: false,
  setReportersReport: () => null,
})

export const useReportsContext = (): ReportsCtxTypes => useContext(reportsCtx)

const ReportsProvider = ({ children }: Props) => {
  const { authClient } = useAuthContext()
  const [reportersReport, setReportersReport] = useState<ReportTypes | null>(null)
  const [selectedOrg, setSelectedOrg] = useState<OrganizationTypes | null>(null)
  const [adminCompanyCode, setAdminCompanyCode] = useState<string | null>(null)
  const { data: reports, isLoading: isReportLoading } = useAxios({
    url: '/report/report/',
    swrKey: `reports`,
  })

  const { data: organizations } = useAxios({ url: '/organizations/organization', swrKey: 'org' })

  // GET CURRENT ORGANIZATION
  useEffect(() => {
    if (authClient) {
      const selectedOrg: OrganizationTypes = organizations?.find(
        (org: OrganizationTypes) => org.id === authClient?.organization
      )
      setSelectedOrg(selectedOrg)
      setAdminCompanyCode(selectedOrg?.code)
    }
  }, [authClient, organizations])

  const ctx = {
    reports,
    selectedOrg,
    adminCompanyCode,
    reportersReport,
    isReportLoading,
    setReportersReport,
  }

  return <reportsCtx.Provider value={ctx}>{children}</reportsCtx.Provider>
}

export default ReportsProvider
