import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useSWRConfig } from 'swr'
import { useAuthContext } from '../../context/auth/auth'
import { useReportsContext } from '../../context/reports/reports'
import axios from '../../services/api'
import InputWrapper from '../Formik/InputWrapper'
import Spinner from '../UI/Spinner'

const UploadLogo = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [logo, setLogo] = useState<File | null>(null)
  const { t } = useTranslation()
  const { mutate } = useSWRConfig()
  const { selectedOrg } = useReportsContext()
  const { token } = useAuthContext()

  const updateLogoHandler = useCallback(async () => {
    const logoData = new FormData()
    logoData.append('image', logo!)
    try {
      setIsLoading(true)
      const response = await axios.post(`/organizations/organization/${selectedOrg?.id}/upload/`, logoData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      if (response.status === 200) {
        toast.success(t('logoHasBeenUpdated'))
        mutate('org')
        setLogo(null)
      }
    } catch (error) {
      toast.error(t('failedToUpdateLogo'))
    } finally {
      setIsLoading(false)
    }
  }, [logo, selectedOrg?.id, token, mutate, t])

  useEffect(() => {
    if (logo) {
      updateLogoHandler()
    }
  }, [logo, updateLogoHandler])

  return (
    <div className='flex flex-col gap-4 xsm:flex-row xsm:items-center '>
      <InputWrapper>
        <label
          htmlFor='uploadLogo'
          className='flex items-center gap-2 bg-primaryBlue text-white w-max rounded-lg px-4 py-3 cursor-pointer font-medium text-[0.9375rem] hover:opacity-90 transition-opacity'
        >
          {isLoading ? (
            <>
              <Spinner spinner='form' /> <span>Updating</span>
            </>
          ) : (
            t('updateLogo')
          )}
        </label>
        <input
          id='uploadLogo'
          className='hidden'
          type='file'
          accept='image/jpeg, image/png, image/webp'
          onChange={e => setLogo(e.target.files![0])}
        />
      </InputWrapper>
      {selectedOrg?.image && (
        <img src={selectedOrg?.image} alt='' className='w-32 object-contain' title={t('tooltipLogo')} />
      )}
    </div>
  )
}

export default UploadLogo
