import { useTranslation } from 'react-i18next'
import { useStepContext } from '../../context/steps/StepsProvider'
import ActiveStep from './ActiveStep'
import Step from './Step'
import CompletedStep from './CompletedStep'

const StepsBar = () => {
  const { t } = useTranslation()
  const { step } = useStepContext()
  const showStep1 = () => step === 1
  const showStep2 = () => step === 2
  const showStep3 = () => step === 3
  const showStep4 = () => step === 4

  return (
    <>
      <div className='grid grid-cols-2  md:flex md:justify-between gap-6 md:gap-0  py-2 mx-auto'>
        {showStep1() && (
          <>
            <ActiveStep text={t('startReport')} stepNumber='1' />
            <Step text={t('questions')} stepNumber='2' />
            <Step text={t('attachments')} stepNumber='3' />
            <Step text={t('review')} stepNumber='4' />
          </>
        )}

        {showStep2() && (
          <>
            <CompletedStep text={t('startReport')} />
            <ActiveStep text={t('questions')} stepNumber='2' />
            <Step text={t('attachments')} stepNumber='3' />
            <Step text={t('review')} stepNumber='4' />
          </>
        )}

        {showStep3() && (
          <>
            <CompletedStep text={t('startReport')} />
            <CompletedStep text={t('questions')} />
            <ActiveStep text={t('attachments')} stepNumber='3' />
            <Step text={t('review')} stepNumber='4' />
          </>
        )}

        {showStep4() && (
          <>
            <CompletedStep text={t('startReport')} />
            <CompletedStep text={t('questions')} />
            <CompletedStep text={t('attachments')} />
            <ActiveStep text={t('review')} stepNumber='4' />
          </>
        )}
      </div>
      <hr className='my-7 m-auto border-gray-300' />
    </>
  )
}

export default StepsBar
