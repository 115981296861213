import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'

interface Props {
  text: string
  path?: string
  onClickHandler?: () => void
  nestedActivePath?: string
}

const SideLink = ({ path, text, nestedActivePath, onClickHandler }: Props) => {
  const { i18n } = useTranslation()
  const navigation = useNavigate()
  const { pathname } = useLocation()
  const isEl = i18n.language === 'el'

  const activeLinkClasses = 'bg-primaryBlue text-white'
  const activeLink =
    pathname === path || pathname.includes(nestedActivePath!)
      ? activeLinkClasses
      : 'bg-[#E1ECFC] text-black'

  const checkReportsPageClasses = pathname.includes('/reporter/check-report')
    ? '3xl:rounded-l-5px 3xl:rounded-tr-none'
    : 'xxl:rounded-l-5px xxl:rounded-tr-none'

  return (
    <button
      onClick={() => (path !== undefined ? navigation(path) : onClickHandler!())}
      className={`text-base font-medium xsm:rounded-t-5px  text-center py-4 hover:opacity-90 transition-opacity ${activeLink} ${checkReportsPageClasses} print:hidden ${
        isEl ? 'w-[185px]' : 'w-[175px]'
      }`}
    >
      {text}
    </button>
  )
}

export default SideLink
