import { useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useSWRConfig } from 'swr';
import { useAuthContext } from '../../context/auth/auth';
import { useModalContext } from '../../context/modal/modal';
import { resolutionOptions } from '../../helpers/selectOptions';
import { ReportTypes } from '../../interfaces/report';
import axios from '../../services/api';
import Backdrop from '../UI/Backdrop';

interface Props {
  report: ReportTypes;
  resolutionOption: string;
  setResolutionOption: (resolutionOption: string) => void;
}

const ResolutionOptionModal = ({ report, resolutionOption, setResolutionOption }: Props) => {
  const { t } = useTranslation();
  const { token } = useAuthContext();
  const { mutate } = useSWRConfig();
  const { setShowResolutionOptiontModal } = useModalContext();
  const [resolvedOption, setResolvedOption] = useState<string>('');

  const submitResolutionOption = async () => {
    if (resolvedOption === '' && resolutionOption === 'resolved') return;

    const resolutionOptionBody =
      resolutionOption === 'resolved'
        ? { status: 'CLOSED', resolved_status: resolvedOption }
        : { status: 'CLOSED' };

    try {
      const response = await axios.patch(`/report/report/${report.id}/`, resolutionOptionBody, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setShowResolutionOptiontModal(false);
        mutate('reports');
        toast.success(t('caseHasBeenClosed'));
      }
    } catch (error) {
      toast.error(t('failedToCloseCase'));
    }
  };

  const btnClasses =
    'h-[2.75rem] min-w-[9.75rem] px-2 text-sm rounded-5px font-medium hover:opacity-90 transition-opacity cursor-pointer';
  return ReactDOM.createPortal(
    <>
      <Backdrop
        onClickHandler={() => {
          setShowResolutionOptiontModal(false);
          setResolutionOption('');
        }}
      />

      <div className='text-center flex flex-col items-center fixed z-50 bg-white max-w-[360px] xsm:max-w-[31.25rem] md:max-w-[37.0625rem] w-full top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-lg px-5 py-6  xsm:py-9 xsm:px-11'>
        <h2 className='text-2xl font-bold'>{t('areYouSureWantToCloseThisCase')}</h2>
        <p className='mt-5 text-textBlack'>
          {t('youAreAboutToCloseThisCase')} #{report.id}.
        </p>
        <p className='text-textBlack mb-9'>
          {t('theCaseWillBe')}{' '}
          <span className='font-semibold text-textBlack'>{t(resolutionOption)}</span>.
        </p>

        <div>
          {resolutionOption === 'resolved' && (
            <>
              <p className='text-textBlack font-semibold  mb-2'>
                {t('howWouldYouLikeToResolveThisCase')}
              </p>
              <select
                defaultValue={''}
                onChange={e => setResolvedOption(e.target.value)}
                className='text-lightGray font-normal bg-white rounded-lg border-[1px] border-solid border-borderGray w-full mb-11'
              >
                <option disabled value=''>
                  {t('chooseResolutionOption')}
                </option>
                {resolutionOptions.map(option => (
                  <option key={option.value} value={option.value}>
                    {t(option.label)}
                  </option>
                ))}
              </select>
            </>
          )}
        </div>
        <div className='flex justify-between gap-3 flex-row'>
          <button
            onClick={submitResolutionOption}
            className={`text-white bg-[#AF0707] ${btnClasses}`}
            type='button'
          >
            {t('yesCloseTheCase')}
          </button>
          <button
            onClick={() => {
              setShowResolutionOptiontModal(false);
              setResolutionOption('');
            }}
            className={`text-textBlack bg-[#E2E2E2] ${btnClasses}`}
            type='button'
          >
            {t('noIamNotReady')}
          </button>
        </div>
      </div>
    </>,
    document.getElementById('overlay') as HTMLElement
  );
};

export default ResolutionOptionModal;
