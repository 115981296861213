interface Props {
  align?: 'center'
  text: string
  isComplianceOfficer?: boolean
}

const CaseInfoMessage = ({ isComplianceOfficer = true, align, text }: Props) => {
  return (
    <div
      className={`h-[calc(100%-132px)] flex items-center ${
        align === 'center' ? 'justify-center' : 'justify-start'
      } ${
        isComplianceOfficer
          ? 'h-[calc(100%-142px)]'
          : 'min-h-[calc(750px-142px)] lg:h-[calc(100%-198px)]'
      }`}
    >
      <p className='text-lg text-lightGray text-center'>{text}</p>
    </div>
  )
}

export default CaseInfoMessage
