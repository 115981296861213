import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useModalContext } from '../../context/modal/modal';
import { ReportTypes } from '../../interfaces/report';
import ResolutionOptionModal from '../Modals/ResolutionOptionModal';

interface Props {
  report: ReportTypes;
}

const ResolutionOption = ({ report }: Props) => {
  const { t } = useTranslation();
  const { showResolutionOptiontModal, setShowResolutionOptiontModal } = useModalContext();
  const [resolutionOption, setResolutionOption] = useState<string>('');

  const isArchived = report?.status === 'CLOSED' && report?.resolved_status === null;
  const isResolved = report?.status === 'CLOSED' && report?.resolved_status !== null;

  useEffect(() => {
    if (resolutionOption !== '') {
      setShowResolutionOptiontModal(true);
    }
  }, [resolutionOption, setShowResolutionOptiontModal]);

  return (
    <>
      {showResolutionOptiontModal && (
        <ResolutionOptionModal
          report={report}
          resolutionOption={resolutionOption}
          setResolutionOption={setResolutionOption}
        />
      )}
      <div className='print:hidden'>
        <p className='text-lg font-bold mb-2'>{t('closeCase')}</p>
        <select
          disabled={isArchived || isResolved}
          value={isResolved ? `resolved` : isArchived ? `archived` : resolutionOption}
          className='bg-white rounded-lg border-[1px] border-solid border-lightGray font-normal'
          onChange={e => setResolutionOption(e.target.value)}
        >
          <option disabled value=''>
            {t('resolutionOption')}
          </option>
          <option value='archived'>{t('archivedClosed')}</option>
          <option value='resolved'>{t('resolvedClosed')}</option>
        </select>
      </div>
    </>
  );
};

export default ResolutionOption;
