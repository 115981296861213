import { useTranslation } from 'react-i18next'

interface Props {
  as?: 'input' | 'select' | 'textarea'
  selectOptions?: { value: string; label: string }[]
  layout?: 'admin' | 'reporter'
  hasError?: boolean
  [x: string]: any
}

const Input = ({ as = 'input', layout = 'admin', selectOptions, hasError, ...restProps }: Props) => {
  const { t } = useTranslation()

  const adminInputClasses = `${
    hasError ? 'border-red-500' : 'border-borderGray'
  } bg-white rounded-lg px-4 py-3 max-w-full border-[1px] border-solid  focus:border-primaryBlue hover:border-primaryBlue transition-all outline-none placeholder:text-lightGray`

  const reporterInputClasses = `placeholder:text-lightGray bg-white  block w-full text-gray-700 border-[1px] rounded-lg hover:border-primaryBlue transition-all mt-2 ${
    hasError ? 'border-red-500' : 'border-borderGray'
  }`

  if (as === 'select' && layout === 'admin') {
    return (
      <select {...restProps} className={`${adminInputClasses} min-w-[6.25rem] max-w-[200px] pr-9`}>
        {selectOptions?.map(option => (
          <option key={option.value} value={option.value}>
            {t(option.label)}
          </option>
        ))}
      </select>
    )
  }

  if (as === 'select' && layout === 'reporter') {
    return (
      <select className={`h-12 px-4 p-0 ${reporterInputClasses}`} {...restProps}>
        {selectOptions?.map(option => (
          <option key={option.label} value={option.value}>
            {t(option.label)}
          </option>
        ))}
      </select>
    )
  }

  if (as === 'textarea' && layout === 'reporter') {
    return <textarea {...restProps} className={`p-4 ${reporterInputClasses}`} />
  }

  switch (layout) {
    case 'admin':
      return <input autoComplete='off' {...restProps} className={adminInputClasses} />

    case 'reporter':
      return <input autoComplete='off' {...restProps} className={`h-12 px-4 p-0 ${reporterInputClasses}`} />
  }
}

export default Input
