import HamburgerMenu from './UI/HamburgerMenu'
import Logo from './UI/Logo'
import LogoutBtn from './Buttons/LogoutBtn'
import LangSelector from './LangSelector/LangSelector'
import { useLocation } from 'react-router-dom'
import { useModalContext } from '../context/modal/modal'
import CompanyLogo from './UI/CompanyLogo'
import { useDataProviderContext } from '../context/dataProvider/DataProvider'

interface Props {
  type: 'admin' | 'reporter'
}

const Header = ({ type }: Props) => {
  const { companyLogo } = useDataProviderContext()
  const { isOpen } = useModalContext()
  const { pathname } = useLocation()
  const typeCssClasses =
    type === 'admin' ? 'bg-white justify-between' : 'bg-white xsm:bg-mainBg justify-between '

  return (
    <header
      className={`min-h-[72px] xl:min-h-[106px] flex items-center px-5 sm:px-[25px] xl:px-[50px] ${typeCssClasses} ${
        pathname === '/reporter' ? 'print:hidden' : 'print:block'
      } ${isOpen ? 'fixed top-0 left-0 right-0 z-40' : 'block top-0 z-0'}`}
    >
      <div
        className={`flex items-center w-full ${
          type === 'admin' ? 'justify-between' : 'justify-between  max-w-[1200px] mx-auto'
        }`}
      >
        {type === 'reporter' && <p className='w-[45px] hidden xsm:block print:xsm:hidden' />}
        {type === 'admin' && <Logo />}
        {type === 'reporter' && companyLogo ? <CompanyLogo /> : type === 'reporter' ? <Logo /> : null}
        <div className='flex items-center'>
          {type === 'reporter' && <LangSelector type='reporter' />}
          <HamburgerMenu />
        </div>
        {type === 'admin' && <LogoutBtn type='desktop' />}
      </div>
    </header>
  )
}

export default Header
