import { useState, createContext, useContext } from 'react'
import useSWR from 'swr'
import axios from '../../services/api'
import { CategoryTypes } from '../../interfaces/category'

interface Props {
  children: React.ReactNode
}

export interface DataProviderType {
  companyLogo: string | null
  companyCode: string | null
  branch: {
    id: string
    title: string
  } | null
  anonymity: 'ANONYMOUS' | 'CONFIDENTIAL'
  reporterName: string
  reporterEmail: string
  reporterPhone: string
  category: number
  incidentDate: string
  ongoing: string
  involved: string
  incidentOccur: string
  description: string
  media: any
  storeCaptchaToken: string | null
  setters: {
    setCompanyLogo: (org: DataProviderType['companyLogo']) => void
    setCompanyCode: (code: DataProviderType['companyCode']) => void
    setBranchId: (id: DataProviderType['branch']) => void
    setAnonymity: (anonymity: DataProviderType['anonymity']) => void
    setReporterName: (name: DataProviderType['reporterName']) => void
    setReporterEmail: (email: DataProviderType['reporterEmail']) => void
    setReporterPhone: (phone: DataProviderType['reporterPhone']) => void
    setCategory: (category: DataProviderType['category']) => void
    setIncidentDate: (incidentDate: DataProviderType['incidentDate']) => void
    setOngoing: (ongoing: DataProviderType['ongoing']) => void
    setInvolved: (involved: DataProviderType['involved']) => void
    setIncidentOccur: (incidentOccur: DataProviderType['incidentOccur']) => void
    setDescription: (description: DataProviderType['description']) => void
    setMedia: (media: DataProviderType['media']) => void
    setStoreCaptchaToken: (token: DataProviderType['storeCaptchaToken']) => void
  }
  resetFormData: () => void
  categories: CategoryTypes[]
}

const DataProviderCtx = createContext<DataProviderType>({
  companyLogo: null,
  companyCode: null,
  branch: null,
  anonymity: 'ANONYMOUS',
  reporterName: '',
  reporterEmail: '',
  reporterPhone: '',
  category: -1,
  incidentDate: '',
  ongoing: '',
  involved: '',
  incidentOccur: '',
  description: '',
  media: [],
  storeCaptchaToken: null,
  setters: {
    setCompanyLogo: logo => {},
    setCompanyCode: code => {},
    setBranchId: branchId => {},
    setAnonymity: anon => {},
    setReporterName: name => {},
    setReporterEmail: email => {},
    setReporterPhone: phone => {},
    setCategory: c => {},
    setIncidentDate: date => {},
    setOngoing: ong => {},
    setInvolved: inv => {},
    setIncidentOccur: inc => {},
    setDescription: desc => {},
    setMedia: med => {},
    setStoreCaptchaToken: token => {},
  },
  resetFormData: () => null,
  categories: [],
})

export function useDataProviderContext(): DataProviderType {
  return useContext(DataProviderCtx)
}

const DataProvider = ({ children }: Props) => {
  const [companyLogo, setCompanyLogo] = useState<DataProviderType['companyLogo']>(null)
  const [companyCode, setCompanyCode] = useState<DataProviderType['companyCode']>(null)
  const [branch, setBranchId] = useState<DataProviderType['branch']>(null)
  const [anonymity, setAnonymity] = useState<DataProviderType['anonymity']>('ANONYMOUS')
  const [reporterName, setReporterName] = useState<DataProviderType['reporterName']>('')
  const [reporterEmail, setReporterEmail] = useState<DataProviderType['reporterEmail']>('')
  const [reporterPhone, setReporterPhone] = useState<DataProviderType['reporterPhone']>('')
  const [category, setCategory] = useState<DataProviderType['category']>(-1)
  const [incidentDate, setIncidentDate] = useState<DataProviderType['incidentDate']>('')
  const [ongoing, setOngoing] = useState<DataProviderType['ongoing']>('')
  const [involved, setInvolved] = useState<DataProviderType['involved']>('')
  const [incidentOccur, setIncidentOccur] = useState<DataProviderType['incidentOccur']>('')
  const [description, setDescription] = useState<DataProviderType['description']>('')
  const [media, setMedia] = useState<DataProviderType['media']>([])
  const [storeCaptchaToken, setStoreCaptchaToken] = useState<DataProviderType['storeCaptchaToken']>(null)

  const resetFormData = () => {
    setCompanyLogo(null)
    setCompanyCode(null)
    setBranchId(null)
    setAnonymity('ANONYMOUS')
    setReporterName('')
    setReporterEmail('')
    setReporterPhone('')
    setCategory(-1)
    setIncidentDate('')
    setOngoing('')
    setInvolved('')
    setIncidentOccur('')
    setDescription('')
    setMedia([])
    setStoreCaptchaToken(null)
  }

  const fethCategories = async () => {
    const response = await axios.post('/report/category/organization-code/', {
      company_code: companyCode,
    })
    return response.data.data
  }

  const { data: categories } = useSWR(companyCode ? 'categories' : null, fethCategories)

  const finalCtx: DataProviderType = {
    companyLogo,
    companyCode,
    branch,
    anonymity,
    reporterName,
    reporterEmail,
    reporterPhone,
    category,
    incidentDate,
    ongoing,
    involved,
    incidentOccur,
    description,
    media,
    storeCaptchaToken,
    setters: {
      setCompanyLogo,
      setCompanyCode,
      setBranchId,
      setAnonymity,
      setReporterName,
      setReporterEmail,
      setReporterPhone,
      setCategory,
      setIncidentDate,
      setOngoing,
      setInvolved,
      setIncidentOccur,
      setDescription,
      setMedia,
      setStoreCaptchaToken,
    },
    resetFormData,
    categories,
  }

  return <DataProviderCtx.Provider value={finalCtx}>{children}</DataProviderCtx.Provider>
}

export default DataProvider
