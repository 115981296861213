interface Props {
  type: 'button' | 'submit'
  text: string
  children?: React.ReactNode
  width?: 'min-w-full' | 'w-full'
  bgColor?: 'bg-primaryBlue' | 'bg-[#E1ECFC]'
  textColor?: 'text-white' | 'text-black'
  onClickHandler?: () => void
}

const Button = ({
  type,
  text,
  children,
  bgColor = 'bg-primaryBlue',
  textColor = 'text-white',
  width = 'w-full',
  onClickHandler,
}: Props) => {
  return (
    <button
      type={type}
      className={`${width} ${bgColor} ${textColor} rounded-5px text-base font-medium py-[13px] px-7 cursor-pointer flex gap-2 justify-center items-center max-w-max hover:opacity-90 transition-opacity print:hidden`}
      onClick={onClickHandler}
    >
      {children}
      <span>{text}</span>
    </button>
  )
}

export default Button
