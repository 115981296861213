import logo from '../../assets/logo.svg'

const PoweredBy = () => {
  return (
    <p className='bg-mainBg font-medium text-lightGray flex items-center justify-center gap-2 py-4'>
      powered by <img src={logo} alt='' width={100} />
    </p>
  )
}

export default PoweredBy
