import { UserTypes } from '../../interfaces/user'
import Table from '../Table/Table'

interface Props {
  users: UserTypes[]
}

const UsersList = ({ users = [] }: Props) => {
  return <Table users={users} />
}

export default UsersList
