interface Props {
  text: string
}

const ErrorMsg = ({ text }: Props) => {
  return (
    <div className='h-full flex justify-center items-center'>
      <p className='text-xl text-lightGray font-medium'>{text}</p>
    </div>
  )
}

export default ErrorMsg
