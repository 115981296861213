import { Routes, Route } from 'react-router-dom'
import { Slide, toast } from 'react-toastify'
import { useModalContext } from './context/modal/modal'
import Layout from './containers/Layout'
import Dashboard from './pages/ComplianceOfficer/Dashboard'
import Reports from './pages/ComplianceOfficer/Reports/Reports'
import Settings from './pages/ComplianceOfficer/Settings'
import Statistics from './pages/ComplianceOfficer/Statistics'
import Users from './pages/Admin/Users'
import CreateUser from './pages/Admin/CreateUser'
import Login from './pages/Auth/Login'
import ForgotPassword from './pages/Auth/ForgotPassword'
import ProtectedRoute from './components/ProtectedRoute'
import ResetPassword from './pages/Auth/ResetPassword'
import PublicRoute from './components/PublicRoute'
import EditUser from './pages/Admin/EditUser'
import CreateCategory from './pages/Admin/CreateCategory'
import EditCategory from './pages/Admin/EditCategory'
import Report from './pages/ComplianceOfficer/Reports/Report'
import MakeReport from './pages/Reporter/MakeReport'
import CheckReport from './pages/Reporter/CheckReport'
import CompanyCode from './pages/Reporter/CompanyCode'
import AccessReportModal from './components/Modals/AccessReportModal'
import ReporterRoute from './components/ReporterRoute'
import Categories from './pages/Admin/Categories'
import Branches from './pages/Admin/Branches'
import CreateBranch from './pages/Admin/CreateBranch'
import EditBranch from './pages/Admin/EditBranch'

import 'react-toastify/dist/ReactToastify.css'
import ChangePassword from './pages/Auth/ChangePassword'

toast.configure({
  position: 'top-center',
  autoClose: 2500,
  pauseOnFocusLoss: false,
  transition: Slide,
})

const App = () => {
  const { showUniqueCodeDialog } = useModalContext()

  return (
    <>
      {showUniqueCodeDialog && <AccessReportModal />}
      <Routes>
        {/* PUBLIC ROUTES */}
        <Route path='/' element={<CompanyCode />} />
        <Route path='/login' element={<PublicRoute />}>
          <Route path='/login' element={<Login />} />
        </Route>
        <Route path='/forgot-password' element={<PublicRoute />}>
          <Route path='/forgot-password' element={<ForgotPassword />} />
        </Route>
        <Route path='/reset-password' element={<PublicRoute />}>
          <Route path='/reset-password/:token' element={<ResetPassword />} />
        </Route>

        {/* PROTECTED ROUTES */}

        {/* COMPLIANCE OFFICER */}
        <Route
          path='/compliance-officer'
          element={<ProtectedRoute requiredUserType={'COMPLIANCE_OFFICER'} />}
        >
          <Route path='/compliance-officer' element={<Layout role='COMPLIANCE_OFFICER' />}>
            <Route index element={<Dashboard />} />
            <Route path='dashboard' element={<Dashboard />} />
            <Route path='reports' element={<Reports />}>
              <Route path='report/:id' element={<Report />} />
            </Route>
            <Route path='statistics' element={<Statistics />} />
            <Route path='settings' element={<Settings />}>
              <Route path='change-password' element={<ChangePassword />} />
            </Route>
          </Route>
        </Route>

        {/* ADMINISTRATOR */}
        <Route path='/admin' element={<ProtectedRoute requiredUserType={'ADMINISTRATOR'} />}>
          <Route path='/admin' element={<Layout role='ADMINISTRATOR' />}>
            <Route index element={<Users />} />
            <Route path='users' element={<Users />}>
              <Route path='create-user' element={<CreateUser />} />
              <Route path='edit-user/:id' element={<EditUser />} />
            </Route>
            <Route path='categories' element={<Categories />}>
              <Route path='create-category' element={<CreateCategory />} />
              <Route path='edit-category/:id' element={<EditCategory />} />
            </Route>
            <Route path='branches' element={<Branches />}>
              <Route path='create-branch' element={<CreateBranch />} />
              <Route path='edit-branch/:id' element={<EditBranch />} />
            </Route>
            <Route path='settings' element={<Settings />}>
              <Route path='change-password' element={<ChangePassword />} />
            </Route>
          </Route>
        </Route>

        {/* HR */}
        <Route path='/hr' element={<ProtectedRoute requiredUserType={'HR'} />}>
          <Route path='/hr' element={<Layout role='HR' />}>
            <Route index element={<Statistics />} />
            <Route path='statistics' element={<Statistics />} />
            <Route path='settings' element={<Settings />}>
              <Route path='change-password' element={<ChangePassword />} />
            </Route>
          </Route>
        </Route>

        {/* REPORTER */}
        <Route path='/reporter' element={<ReporterRoute />}>
          <Route path='/reporter' element={<MakeReport />} />
        </Route>

        <Route path='/check-report' element={<PublicRoute />}>
          <Route path='/check-report' element={<CheckReport />}>
            <Route path=':id' element={<Report />} />
          </Route>
        </Route>
      </Routes>
    </>
  )
}

export default App
