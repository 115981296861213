import { createContext, useContext, useState } from 'react'
import { Props } from '../auth/auth.interfaces'

interface modalCtxTypes {
  isOpen: boolean
  showConfirmationDialog: boolean
  showUniqueCodeDialog: boolean
  showResolutionOptiontModal: boolean
  showSubmittedReportModal: boolean
  resourceId: number | undefined
  setShowSubmittedReportModal: (value: boolean) => void
  setShowResolutionOptiontModal: (value: boolean) => void
  isModalOpenHandler: () => void
  showConfirmationDialogHandler: (id: number) => void
  closeConfirmationDialogHandler: () => void
  showUniqueCodeDialogHandler: () => void
  closeUniqueCodeDialogHandler: () => void
}

const modalCtx = createContext<modalCtxTypes>({
  isOpen: false,
  showConfirmationDialog: false,
  showResolutionOptiontModal: false,
  showUniqueCodeDialog: false,
  showSubmittedReportModal: false,
  resourceId: undefined,
  setShowSubmittedReportModal: () => null,
  setShowResolutionOptiontModal: () => null,
  isModalOpenHandler: () => null,
  showConfirmationDialogHandler: () => null,
  closeConfirmationDialogHandler: () => null,
  showUniqueCodeDialogHandler: () => null,
  closeUniqueCodeDialogHandler: () => null,
})

export const useModalContext = (): modalCtxTypes => useContext(modalCtx)

const ModalProvider = ({ children }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false)
  const [showUniqueCodeDialog, setShowUniqueCodeDialog] = useState<boolean>(false)
  const [showSubmittedReportModal, setShowSubmittedReportModal] = useState<boolean>(false)
  const [showResolutionOptiontModal, setShowResolutionOptiontModal] = useState<boolean>(false)
  const [resourceId, setResourceId] = useState<number | undefined>(undefined)

  const isModalOpenHandler = () => setIsOpen(prevState => !prevState)

  const showConfirmationDialogHandler = (id: number = -1) => {
    setShowConfirmationDialog(true)
    if (id !== -1) {
      setResourceId(id)
    }
  }
  const closeConfirmationDialogHandler = () => {
    setShowConfirmationDialog(false)
    if (resourceId !== undefined) {
      setResourceId(undefined)
    }
  }

  const showUniqueCodeDialogHandler = () => setShowUniqueCodeDialog(true)
  const closeUniqueCodeDialogHandler = () => setShowUniqueCodeDialog(false)

  const ctx = {
    isOpen,
    showConfirmationDialog,
    showResolutionOptiontModal,
    showUniqueCodeDialog,
    showSubmittedReportModal,
    resourceId,
    setShowSubmittedReportModal,
    setShowResolutionOptiontModal,
    isModalOpenHandler,
    showConfirmationDialogHandler,
    closeConfirmationDialogHandler,
    showUniqueCodeDialogHandler,
    closeUniqueCodeDialogHandler,
  }
  return <modalCtx.Provider value={ctx}>{children}</modalCtx.Provider>
}

export default ModalProvider
