import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import jwt from 'jwt-decode'
import axios from '../../services/api'
import { useAuthContext } from '../../context/auth/auth'
import Input from '../Formik/Input'
import InputWrapper from '../Formik/InputWrapper'
import Label from '../Formik/Label'
import Button from '../Buttons/Button'
import { navigateTo } from '../../helpers/navigateTo'
import { AuthClientTypes } from '../../context/auth/auth.interfaces'
import Spinner from '../UI/Spinner'
import { useTranslation } from 'react-i18next'
import FormikForm from '../Formik/FormikForm'

const LoginForm = () => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const { storeToken } = useAuthContext()

  const initialValues = {
    username: '',
    password: '',
  }

  const validationSchema = yup.object({
    username: yup.string().required(),
    password: yup.string().required(),
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async values => {
      try {
        setIsLoading(true)
        const {
          status,
          data: { data },
        } = await axios.post('/login/', {
          username: values.username,
          password: values.password,
        })
        if (status === 200) {
          setIsLoading(false)
          const authClient: AuthClientTypes = jwt(data.access)
          storeToken(data)
          navigate(navigateTo(authClient))
        }
      } catch (error) {
        setIsLoading(false)
        toast.error(t('InvalidUsernameOrPassword'))
      }
    },
  })

  return (
    <FormikForm onSubmitHandler={formik.handleSubmit} formType='lg' paddingX='sm:px-28'>
      <div className='mb-3'>
        <h2 className='font-bold text-center text-3xl mb-4'>{t('welcome')}</h2>
        <p className='text-lightGray text-center'>{t('enterCredentials')}</p>
      </div>

      <InputWrapper>
        <Label text={t('userName')} />
        <Input
          id='Username'
          as='input'
          type='text'
          placeholder={t('insertUsername')}
          hasError={formik.touched.username && formik.errors.username ? true : false}
          {...formik.getFieldProps('username')}
        />
      </InputWrapper>
      <InputWrapper>
        <Label text={t('password')} />
        <Input
          id='Password'
          as='input'
          type='password'
          placeholder={t('insertPassword')}
          hasError={formik.touched.password && formik.errors.password ? true : false}
          {...formik.getFieldProps('password')}
        />
        <Link className='self-end text-primaryBlue text-sm mt-[0.625rem]' to='/forgot-password'>
          {t('forgotPassword')}
        </Link>
      </InputWrapper>
      <Button width='min-w-full' type='submit' text={isLoading ? 'Loading...' : t('logIn')}>
        {isLoading && <Spinner spinner='form' />}
      </Button>
    </FormikForm>
  )
}

export default LoginForm
