import { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import StepsButtons from './StepsButtons'
import { useDataProviderContext } from '../../context/dataProvider/DataProvider'
import FormStepTitle from '../UI/FormStepTitle'
import DropzoneFilePreview from '../UI/DropzoneFilePreview'
import { useTranslation } from 'react-i18next'

const Step3 = () => {
  const { t } = useTranslation()
  const reportData = useDataProviderContext()

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      const accFiles = acceptedFiles.map((file: any) => ({
        file,
        preview: URL.createObjectURL(file),
      }))
      reportData.setters.setMedia((prevState: any) => [...prevState, ...accFiles])
    },
    [reportData.setters]
  )

  const { getRootProps, getInputProps, open, isDragReject, isDragAccept } = useDropzone({
    onDrop,
    // 'image/jpeg, image/png, video/mp4, video/quicktime, video/mpeg, audio/wav, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword, application/pdf'

    accept: 'image/jpeg, image/png, video/mp4, audio/wav, application/pdf',
    noClick: true,
    noKeyboard: true,
  })

  const dropzoneBorderColor = isDragAccept
    ? 'border-green-500'
    : isDragReject
    ? 'border-[#CD4444]'
    : 'border-borderGray'
  const textColor = isDragAccept
    ? 'text-green-500'
    : isDragReject
    ? 'text-[#CD4444]'
    : 'text-lightGray'

  const deletePreviewFileHandler = (id: string) => {
    reportData.setters.setMedia((prevState: []) =>
      prevState.filter((file: { preview: string }) => file.preview !== id)
    )
  }

  const displayFilePreview =
    reportData.media?.length !== 0 &&
    reportData.media?.map((file: { file: { name: string }; preview: string }) => (
      <DropzoneFilePreview
        key={file.preview}
        preview={file.preview}
        onClickHandler={() => deletePreviewFileHandler(file.preview)}
        fileName={file.file.name}
      />
    ))

  return (
    <>
      <div className='items-center my-6'>
        <h1 className='text-3xl font-bold my-3'>{t('attachments')}</h1>

        <p className='text-xl font-semibold my-3'>{t('uploadFilesTitle')}</p>
      </div>

      <div className={`border-4 border-dashed rounded-lg p-6 ${dropzoneBorderColor}`}>
        <div {...getRootProps()} className='grid cursor-default justify-items-center'>
          <input {...getInputProps()} />
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='h-12 w-12 mb-5'
            fill='none'
            viewBox='0 0 24 24'
            stroke='currentColor'
            strokeWidth='2'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12'
            />
          </svg>
          <h6>
            <b>{t('dragHere')}</b>
          </h6>
          <p className={`my-2 ${textColor} text-center`}>
            {t('supportedFiles')} PNG, JPEG, JPG, MP4, WAV, PDF
          </p>
          <button
            type='button'
            onClick={open}
            className='px-10 py-3 rounded-lg text-black bg-white border-[1px] border-solid border-borderGray my-3 hover:text-white hover:bg-primaryBlue transition-all'
          >
            {t('addFile')}
          </button>
        </div>
      </div>
      {reportData.media?.length > 0 && (
        <div className='mt-4'>
          <FormStepTitle as='h2' text='Preview your files' />

          <div className='grid grid-cols-2 xsm:grid-cols-4 gap-7'>{displayFilePreview}</div>
        </div>
      )}
      <StepsButtons />
    </>
  )
}

export default Step3
