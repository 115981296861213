interface Props {
  hasError?: boolean
  [x: string]: any
}

const CheckBox = ({ hasError, ...restProps }: Props) => {
  return (
    <input
      {...restProps}
      className={`${hasError ? 'border-red-500' : 'border-borderGray'} rounded  outline-none`}
    />
  )
}

export default CheckBox
