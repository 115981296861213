export const translationTr = {
  // Company Code Form
  welcome: 'Hoş Geldiniz',
  companyCodeInfo:
    'Yeni bir rapor oluşturmak için Şirket kodunuzu girin veya daha önce gönderilmiş bir raporun durumunu kontrol edin.',
  companyCodeLabel: 'Şirket erişim kodu',
  companyCodeInputPlaceholder: 'Yeni Rapor oluşturmak için erişim kodunu girin',
  statusOfExistingReport: 'Mevcut Raporun Durumu',
  createNewReport: 'Yeni Rapor oluştur',
  companyCode: 'Şirket kodu',

  // Access Report Modal
  accessReportModalTitle: 'Erişim için gereken Özgün Kod',
  accessReportModalText:
    'Raporu gönderdiğiniz anda size özgün bir kod verildi. Lütfen kodu aşağıdaki giriş alanına yapıştırın.',
  accessReportModalLabel: 'Özgün Kod',
  accessReportModalPlaceholder: 'Erişim kodunu girin',
  accessReportModalCancelBtn: 'İptal',
  accessReportModalChecklBtn: 'Raporu Kontrol Et',

  // Save Token Modal
  saveTokenTitle: 'Rapor teslim edildi. Aşağıdaki kodu kaydedin:',
  saveTokenImportant: 'ÖNEMLİ:',
  saveTokenImportantNote:
    'Aşağıdaki kodu kaydedin ve güvenli bir şekilde saklayın. Kodu kimseyle paylaşmayın. Bu kod ile ilerlemesini takip etmek ve mesajları görmek için raporunuza erişebileceksiniz.',
  saveTokenUniqueCodeLabel: 'Özgün Kodunuz',
  saveTokenReceivedReport: 'Raporunuzu aldık ve en kısa sürede size geri döneceğiz.',
  saveTokenReportInfo:
    'Gönderdiğiniz raporunuzu aşağıdan indirebilir/dışa aktarabilir veya bitirmek için "Tamamla"yı tıklayabilirsiniz.',
  saveTokenExportBtn: 'Raporu Dışarıya Aktar',
  saveTokenCompleteBtn: 'Tamamlandı',

  // Delete Resource Modal
  areYouSureWantToDelete: 'Bu kullanıcıyı silmek istediğinizden emin misiniz?',
  areYouSureWantToDeleteThisCategory: 'Bu kategoriyi silmek istediğinizden emin misiniz?',
  areYouSureWantToDeleteThisBranch: 'Bu Branch silmek istediğinizden emin misiniz?',
  yesIamSure: 'Evet eminim',
  noCancel: 'Hayır, iptal et',

  // Resolution Option Modal
  areYouSureWantToCloseThisCase: 'Bu vakayı kapatmak istediğinizden emin misiniz?',
  youAreAboutToCloseThisCase: 'Bu vakayı kapatmak üzeresiniz',
  theCaseWillBe: 'Vaka olacak',
  howWouldYouLikeToResolveThisCase: 'Bu vakayı nasıl çözmek istersiniz?',
  yesCloseTheCase: 'Evet, vakayı kapat',
  noIamNotReady: 'Hayır, hazır değilim',
  archived: 'Arşivlendi',
  resolved: 'Çözümlendi',

  // Report Form
  reportAnOccurrenceText:
    'Kuruluşun politikalarına ve prosedürlerine aykırı olan çeşitli suistimal, dolandırıcılık veya uyum ihlalleriyle ilgili beklenmedik olayları bildirmek için bu formu kullanın. ReportAll formu, bir soruşturmanın yapılıp yapılmayacağını ve nasıl yürütüleceğini belirlemek için ilgili bilgileri talep eder. Gizlilik yüksek düzeyde korunur ve uyum görevlileri her türlü kişisel bilgiyi korumakla yükümlüdür.',
  makeReport: 'Rapor Hazırla',
  checkReport: 'Raporu Kontrol Et',
  review: 'Gözden geçir',
  startReport: 'Raporu Başlat',
  typeOfReport: 'Rapor Türü',
  category: 'Kategori',
  branch: 'Branch',
  fullName: 'Ad Soyad',
  telephone: 'Telefon',
  questions: 'Sorular',
  whenDidTheIncidentOccur: 'Olay ne zaman meydana geldi?',
  isTheIncidentStillOngoing: 'Olay hala devam ediyor mu?',
  whereDidTheIncidentOccur: 'Olay nerede meydana geldi?',
  whoIsInvolvedAndWhatIsTheirCapacity: 'Kimler dahil ve ünvanları nedir?',
  nameSurnameAndTheirCapacity: 'Ad Soyad ve ünvanları',
  fullDescriptionOfTheIncident: 'Olayın Tam Açıklaması',
  regular: 'Sürekli',
  anonymous: 'İsimsiz',
  attachments: 'Ekler',
  uploadFilesTitle: 'Raporunuzla ilgili tüm dosyaları yükleyebilirsiniz.',
  dragHere: 'Dosyaları Yüklemek için buraya sürükleyin',
  supportedFiles: 'Desteklenen Dosyalar',
  addFile: 'Dosya Ekle',
  reportAnOccurrence: 'Bir olayı bildir',
  anonymousReporting: 'İsimsiz raporlama',
  regularReporting: 'Sürekli raporlama',
  chooseIncidentcategory: 'Olay kategorisini seçin',
  choose: 'Seçin',
  anonymousText:
    'Bu Bildirim, şikayetçiden herhangi bir kişisel veri ve/veya iletişim bilgisi gerektirmediğinden isimsizdir. Lütfen bu durumda Raporunuzun takibine yalnızca Raporun sunulması üzerine size sağlanan erişim kodu aracılığıyla izin verildiğini unutmayın.',
  regularText:
    'Bu Raporlama, gönderilmeden önce kişisel verilerinizi ve/veya iletişim bilgilerinizi isteyebilir. Lütfen adınız dışında bu tür bilgilerin zorunlu olmadığını unutmayın. Her durumda, Raporunuz en üst düzeyde gizlilikle ele alınacaktır.',
  optional: 'isteğe bağlı',
  back: 'Geri',
  nextStep: 'Sonraki adım',
  submitReport: 'Rapor gönder',
  noAttachmentsFound: 'Ek bulunamadı',
  textHere: 'Buraya Yazın',
  freeTextSmallField: 'Serbest Küçük Metin Alan',
  freeTextLargeField: 'Serbest Büyük Metin Alanı',
  details: 'Detaylar',
  assignTo: 'Görevlendir',
  caseOverview: 'Vaka Genel Bakış',
  activityLog: 'Etkinlik Günlüğü',
  submit: 'İbraz et',
  search: 'Arama',
  yes: 'Evet',
  no: 'Hayır',
  writeYourFullName: 'Tam adınızı yazın',
  writeYourEmail: 'E-postanızı yazın',
  writeYourTelephone: 'Telefon numaranızı yazın',
  writeThePlaceOfTheIncident: 'Olayın yerini yazınız',
  addTheNamesOfThePeopleInvolved: 'İlgili kişilerin adlarını ekleyin',
  describeIncident:
    'Lütfen olayı açıklayın (Ne oldu? Nasıl oldu? vb.) Mümkün olduğunca kesin ve apaçık olun.',
  report: 'Rapor',
  export: 'Dışarı aktar',
  caseDetails: 'Vaka Ayrıntıları',
  yourMessages: 'Mesajlarınız',
  yourMessagesInfo:
    'Burada, raporunuzla ilgili daha fazla bilgi eklemek için durumunuzu üstlenen uyum görevlisiyle iletişime geçebilirsiniz.',
  UNASSIGNED: 'Atanmamış',
  IN_PROGRESS: 'İlerleme halinde',
  CLOSED: 'Kapanmış',
  TOTAL: 'Toplam',
  selectBranch: 'Select Branch',

  // Navigation
  dashboard: 'Gösterge Paneli',
  reports: 'Raporlar',
  users: 'Kullanıcılar',
  settings: 'Ayarlar',
  statistics: 'İstatistikler',
  categories: 'Kategoriler',
  branches: 'Branches',

  // Admin
  newUser: 'Yeni Kullanıcı',
  editUser: 'Kullanıcıyı Düzenle',
  saveNewUser: 'Yeni Kullanıcıyı Kaydet',
  userName: 'Kullanıcı Adı',
  firstName: 'Ad',
  lastName: 'Soyad',
  password: 'Şifre',
  position: 'Görev',
  language: 'Dil',
  newCategory: 'Yeni Kategori',
  newBranch: 'Yeni branch',
  editCategory: 'Kategoriyi Düzenle',
  editBranch: 'Branch Düzenle',
  insertCategory: 'Kategori Ekle',
  insertName: 'İsim Ekle',
  saveCategory: 'Kategoriyi Kaydet',
  noCategoriesFound: 'Kategori bulunamadı',
  noDataFound: 'Veri bulunamadı',
  changePassword: 'Şifre değiştir',
  updateCompanyName: 'Şirket Adını Güncelle',
  updatePrivacyPolicy: 'Gizlilik Politikasını Güncelle',
  enterLink: 'Gizlilik Politikası Bağlantısını Girin',
  updateLogo: 'Logoyu Güncelle',
  companyLogo: 'Şirket Logosu',

  // Compliance Officer
  complianceOfficer: 'Uyum Görevlisi',
  visibility: 'Görünürlük',
  created: 'Oluşturuldu',
  status: 'Durum',
  overviewStatistics: 'İstatistiklere Genel Bakış',
  complianceOfficerNotes: 'Uyum Görevlisi Notları',
  complianceOffcerNoteCreatedBy: 'Uyum Görevlisi Notunu oluşturan:',
  youHaventAddedNotes: 'Bu vakaya henüz herhangi bir not eklemediniz.',
  onlyAssignedCoOfficerCanViewMessages: 'Mesajları yalnızca atanan Uyum Görevlisi görebilir.',
  characterLimit: 'Karakter Sınırı',
  noteLimit: 'Mesaj en fazla 320 karakter olmalıdır',
  closeCase: 'Vakayı Kapat',
  resolutionOption: 'Çözünürlük Seçeneği',
  archivedClosed: 'Arşiv (Kapalı)',
  resolvedClosed: 'Çözümle (Kapalı)',
  caseClosed: 'Vaka Kapandı',
  caseHasBeenClosed: 'Vaka kapatıldı',
  failedToCloseCase: 'Vaka kapatılamadı',
  REPORT_CREATED: 'Rapor Oluşturuldu',
  COMPLIANCE_OFFICER_ASSIGNED: 'Uyum Görevlisi Atandı:',
  REPORT_STATUS_CHANGED: 'Rapor Durumu Değiştirildi:',
  REPORT_NOTE_CREATED: 'Rapor Notu Oluşturuldu',
  REPORT_EDITED: 'Rapor Düzenlendi',

  // Table
  showing: 'Gösteriliyor',
  to: 'ile',
  entries: 'girişler',
  of: 'hakkında',
  pages: 'sayfalar',
  ANONYMOUS: 'İsimsiz',
  noReportsFound: 'Rapor Bulunamadı',
  noUsersFound: 'Kullanıcı bulunamadı',

  // Charts
  mostReportedCategories: 'En çok rapor edilen kategoriler',
  conclusionType: 'Sonuç türü',
  numberNatureOfReports: 'Rapor Sayısı / Niteliği',
  total: 'Tüm Raporlar',
  other: 'Diğer',
  noDataFoundForTheSelectedPeriod: 'Seçilen dönem için veri bulunamadı',

  // Toast messages
  copiedToClipboard: 'Panoya kopyalandı',
  aUserWithThisEmailAlreadyExists: 'Bu e-postaya sahip bir kullanıcı zaten var',
  aUserWithThisUsernameAlreadyExists: 'Bu kullanıcı adına sahip bir kullanıcı zaten var',
  failedToCreateUser: 'Kullanıcı oluşturulamadı',
  userHasBeenCreated: 'Kullanıcı oluşturuldu',
  userHasBeenUpdated: 'Kullanıcı güncellendi',
  failedToUpdateUser: 'Kullanıcı güncellenemedi',
  userHasBeenDeleted: 'Kullanıcı silindi',
  failedToDeleteUser: 'Kullanıcı silinemedi',
  failedToSubmitNote: 'Not gönderilemedi',
  failedToLoadReports: 'Raporlar yüklenemedi',
  failedToCreateCategory: 'Kategori oluşturulamadı',
  categoryHasBeenCreated: 'Kategori oluşturuldu',
  categoryHasBeenDeleted: 'Kategori silindi',
  failedToDeleteCategory: 'Kategori silinemedi',
  categoryHasBeenUpdated: 'Kategori güncellendi',
  branchHasBeenUpdated: 'Branch güncellendi',
  failedToUpdateBranch: 'Branch güncellenemedi',
  branchHasBeenDeleted: 'Branch silindi',
  failedToDeleteBranch: 'Branch silinemedi',
  failedToUpdateCategory: 'Kategori güncellenemedi',
  branchHasBeenCreated: 'Branch oluşturuldu',
  failedToCreateBranch: 'Branch oluşturulamadı',
  submitFailed: 'Gönderilemedi',
  aResetLinkHasBeenSentToYourEmail: 'E-postanıza bir sıfırlama bağlantısı gönderildi.',
  InvalidUsernameOrPassword: 'Geçersiz kullanıcı adı veya şifre',
  yourPasswordHasBeenResetsussfully: 'Şifreniz başarıyla sıfırlandı.',
  failedToResetPassword: 'Failed to reset password',
  yourPasswordHasBeenChanged: 'Şifreniz başarıyla değiştirildi.',
  failedToChangePassword: 'Şifre değiştirilemedi',
  companyNameHasBeenUpdated: 'Şirket adı güncellendi',
  failedToUpdateCompanyName: 'Şirket adı güncellenemedi',
  sessionExpired: 'Oturumunuz sona erdi. Lütfen tekrar giriş yapın.',
  failedToSendMessage: 'Mesaj gönderilemedi',
  failedToLoadMessages: 'Mesajlar yüklenemedi',
  privacyPolicyHasBeenUpdated: 'Gizlilik Politikası güncellendi',
  failedToUpdatePrivacyPolicy: 'Gizlilik politikası güncellenemedi',
  logoHasBeenUpdated: 'Logo güncellendi',
  failedToUpdateLogo: 'Logo güncellenemedi',
  caseAssigned: 'Vaka başarıyla atandı',

  // Error messages
  somethingWentWrongFailedToLoadNotes: 'Bir şeyler ters gitti, notlar yüklenemedi',

  //Validation Messages
  requiredField: 'Gerekli Alan',
  invalidEmail: 'Geçersiz E-posta',
  invalidPhone: 'Geçersiz Telefon',

  logout: 'Oturumu Kapat',
  logIn: 'Oturum Aç',
  enterCredentials: 'Hesabınıza erişmek için Kullanıcı Bilgilerinizi girin.',
  resetPassword: 'Şifreyi Sıfırla',
  forgotPassword: 'Şifrenizi unuttunuz mu?',
  insertUsername: 'Kullanıcı Adı Ekle',
  insertPassword: 'Şifre Ekle',
  insertEmail: 'E-posta Ekle',
  enterYourEmailToResetYourPassword: 'Şifrenizi sıfırlamak için e-postanızı girin.',
  enterNewPassword: 'Yeni Şifreyi Girin',
  confirmPassword: 'Şifreyi Onayla',
  passwordMustBeAtLeast8CharactersAndContainAtLeastOneUppercaseLetterAndOneNumber:
    'Şifre en az 8 karakter olmalı ve en az bir büyük harf bir rakam ve bir özel karakter içermelidir.',
  passwordsMustMatch: 'Şifreler aynı olmalıdır',

  'Select Position': 'Ünvan Seçin',
  'Compliance Officer': 'Uyum Görevlisi',
  Administrator: 'Yönetici',
  HR: 'İK',
  COMPLIANCE_OFFICER: 'Uyum Görevlisi',
  ADMINISTRATOR: 'Yönetici',

  chooseResolutionOption: 'Çözünürlük seçeneğini seçin',
  'Case Dismissed': 'Vaka Reddedildi',
  Lawsuit: 'Dava',
  'Report to Other Authorities': 'Diğer Yetkililere Bildirin',
  'Resolved Internally': 'Dahili Olarak Çözüldü',

  IhaveReadAndAgreeWithThe: 'Okudum ve Katılıyorum',
  termsAndConditions: 'Şartlar ve Koşullar',
  IhaveBeenInformedAboutTheProcessingOfMyData: 'Kişisel verilerimin işlenmesi konusunda bilgilendirildim.',
  privacyStatement: 'Gizlilik Bildirimi',
  Jan: 'Oca',
  Feb: 'Şub',
  Mar: 'Mar',
  Apr: 'Nis',
  May: 'May',
  Jun: 'Haz',
  Jul: 'Tem',
  Aug: 'Ağu',
  Sep: 'Eyl',
  Oct: 'Eki',
  Nov: 'Kas',
  Dec: 'Ara',

  CASE_DISMISSED: 'Vaka Reddedildi',
  LAWSUIT: 'Dava',
  REPORT_TO_OTHER_AUTHORITIES: 'Diğer Yetkililere Bildirin',
  RESOLVED_INTERNALLY: 'Dahili Olarak Çözüldü',

  tooltipLogo: 'Bir ihbarcı şirketinize bir rapor gönderdiğinde şirket logosu gösterilecektir.',
}
