import { useEffect, useState } from 'react'
import { CategoryTypes } from '../../interfaces/category'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useSWRConfig } from 'swr'
import { useOutletContext, useNavigate, useParams } from 'react-router-dom'
import { useAuthContext } from '../../context/auth/auth'
import axios from '../../services/api'
import PageTitle from '../../components/UI/PageTitle'
import BranchesCategoryForm from '../../components/Forms/BranchesCategoryForm'

const EditCategory = () => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { token, authClient } = useAuthContext()
  const categories: CategoryTypes[] = useOutletContext()
  const navigate = useNavigate()
  const { mutate } = useSWRConfig()
  const { id } = useParams()

  const category = categories?.find(category => category.id.toString() === id)

  const onSubmit = async (values: { title: string }) => {
    try {
      setIsLoading(true)
      const response = await axios.patch(
        `/report/category/${id}/`,
        {
          title: values.title,
          organization: authClient?.organization,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      if (response.status === 200) {
        toast.success(t('categoryHasBeenUpdated'))
        mutate('categories')
        navigate(-1)
        setIsLoading(false)
      }
    } catch (error) {
      toast.error(t('failedToUpdateCategory'))
      setIsLoading(false)
    }
  }

  useEffect(() => {
    document.title = `${t('editCategory')} - ReportAll`
  }, [t])

  return (
    <>
      <PageTitle title={t('editCategory')} />
      <BranchesCategoryForm
        onSubmit={onSubmit}
        isLoading={isLoading}
        data={category}
        label='category'
        buttonText='editCategory'
      />
    </>
  )
}

export default EditCategory
