import Header from './Header'
import HamburgerNav from './Navigation/HamburgerNav'

const ReporterHeader = () => {
  return (
    <>
      <HamburgerNav />
      <Header type='reporter' />
    </>
  )
}

export default ReporterHeader
