import { useTranslation } from 'react-i18next'
import { Navigate, Outlet } from 'react-router-dom'
import { useAuthContext } from '../context/auth/auth'
import { AuthClientTypes } from '../context/auth/auth.interfaces'
import setupInterceptor from '../services/setupInterceptor'

interface Props {
  requiredUserType: AuthClientTypes['user_type'] | undefined
}

const ProtectedRoute = ({ requiredUserType }: Props) => {
  const { t } = useTranslation()
  const { token, authClient, logout } = useAuthContext()
  setupInterceptor({ logout, toastMsg: t('sessionExpired') })

  return token && authClient?.user_type === requiredUserType ? <Outlet /> : <Navigate to='/login' />
}

export default ProtectedRoute
