import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { IoMdAdd } from 'react-icons/io'
import PageTitle from '../../components/UI/PageTitle'
import Button from '../../components/Buttons/Button'
import UsersList from '../../components/UsersList/UsersList'
import ContentSection from '../../components/UI/ContentSection'
import ModalConfirmationDialog from '../../components/Modals/ModalConfirmationDialog'
import { useModalContext } from '../../context/modal/modal'
import { useUsersContext } from '../../context/users/users'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'

const Users = () => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { activeUsers } = useUsersContext()
  const { showConfirmationDialog } = useModalContext()
  const isExactPath = pathname === '/admin' || pathname === '/admin/users'

  useEffect(() => {
    document.title = `${t('users')} - ReportAll`
  }, [t])

  return (
    <ContentSection>
      {showConfirmationDialog && (
        <ModalConfirmationDialog
          text={t('areYouSureWantToDelete')}
          api='/auth/user'
          toastSuccess={t('userHasBeenDeleted')}
          toastError={t('failedToDeleteUser')}
          swrKey='users'
        />
      )}
      {isExactPath ? (
        <>
          <div className='flex flex-col xsm:flex-row xsm:items-center gap-6'>
            <div className='flex items-center gap-2'>
              <PageTitle title={t('users')} />
              {activeUsers && activeUsers.length !== 0 && (
                <p className='text-[28px] font-bold'>({activeUsers.length})</p>
              )}
            </div>
            <Button
              onClickHandler={() => navigate(pathname === '/admin' ? 'users/create-user' : 'create-user')}
              text={t('newUser')}
              type='button'
            >
              <IoMdAdd />
            </Button>
          </div>
          <UsersList users={activeUsers} />
        </>
      ) : (
        <Outlet context={activeUsers} />
      )}
    </ContentSection>
  )
}

export default Users
