import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'
import { useSWRConfig } from 'swr'
import { yearOptions } from '../../helpers/selectOptions'
import useAxios from '../../hooks/useAxios'
import useWidth from '../../hooks/useWidth'
import { CategoryTypes } from '../../interfaces/category'
import { MontlyByCategoryTypes } from '../../interfaces/charts'
import Input from '../Formik/Input'
import ChartTitle from '../UI/ChartTitle'

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

const NumberOfReports = () => {
  const currentYear = new Date().getFullYear().toString()
  const { t } = useTranslation()
  const { width } = useWidth()

  const [year, setYear] = useState<string>(currentYear)
  const [statsData, setStatsData] = useState<MontlyByCategoryTypes[]>([])
  const [selectedCategory, setSelectedCategory] = useState<string>('')
  const { pathname } = useLocation()
  const { mutate } = useSWRConfig()

  const isStatisticsRoute = pathname.includes('/statistics')

  const chartClasses = isStatisticsRoute
    ? 'flex-col sm:flex-row'
    : 'flex-col sm:flex-row xl:flex-col 3xl:flex-row'

  const { data: stats } = useAxios({
    url: `/report/stats/monthly_by_category/${year}/`,
    swrKey: 'monthlyByCategory',
  })

  const { data: categories } = useAxios({
    url: '/report/category/organization-code/',
    swrKey: 'categories',
    method: 'POST',
  })

  useEffect(() => {
    if (stats && categories) {
      // Data transformation for the chart
      const transformedStats = stats.map((stat: MontlyByCategoryTypes) => {
        // Get Category name as key and the number of reports as value
        const selectedCategories = categories.map((cat: CategoryTypes) => ({
          [cat.title]: stat.report_counts[cat.id.toString()],
        }))

        // Merge all categories for the specific month
        const transformedMonth: { [x: string]: string | number; month: string } = {
          month: t(months[stat.month - 1]),
          [t('total')]: stat.report_counts.total,
        }

        // Add category data to the month
        for (const cat of selectedCategories) {
          const key: string = Object.keys(cat)[0]
          const value: number = Object.values(cat)[0] as number

          transformedMonth[key] = value
        }

        // Return the transformed month
        return transformedMonth
      })
      setStatsData(transformedStats)
      mutate('monthlyByCategory')
    }
  }, [stats, categories, year, t, mutate])

  useEffect(() => {
    if (categories) {
      setSelectedCategory(categories[0]?.title)
    }
  }, [categories])
  const total = t('total')

  const categoriesOptions =
    categories && categories.map((cat: CategoryTypes) => ({ value: cat.title, label: cat.title }))

  return (
    <div className='w-full h-full bg-white shadow-form rounded-20px p-6'>
      <div className={`flex items-center justify-between mb-4 ${chartClasses}`}>
        <ChartTitle text={t('numberNatureOfReports')} />
        <div
          className={`flex gap-4 mt-4 sm:mt-0  3xl:mt-0 flex-col lg:flex-row ${
            isStatisticsRoute ? 'xl:mt-0' : 'xl:mt-4'
          }`}
        >
          <Input
            as='select'
            value={selectedCategory}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSelectedCategory(event.target.value)}
            selectOptions={categoriesOptions}
          />

          <Input
            as='select'
            value={year}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setYear(event.target.value)}
            selectOptions={yearOptions}
          />
        </div>
      </div>

      <ResponsiveContainer
        width='100%'
        height={
          width && width <= 1024
            ? '60%'
            : width && width >= 1280 && width < 1640
            ? isStatisticsRoute
              ? '85%'
              : '70%'
            : isStatisticsRoute
            ? '83%'
            : '80%'
        }
        className='bg-transparent rounded-20px'
      >
        <LineChart data={statsData} margin={{ top: 0, left: 0, right: 0, bottom: 0 }}>
          <Line strokeWidth='3' type='linear' dataKey={total} stroke='#025FDA' activeDot={{ r: 8 }} />
          <Line strokeWidth='3' type='linear' dataKey={selectedCategory ?? ''} stroke='#FCB875' />
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='month' stroke='#3d3d3d' fontSize={13} tickMargin={10} tickLine={false} />
          <YAxis width={25} stroke='#9CA3AF' fontSize={13} />
          <Tooltip />
          <Legend />
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}

export default NumberOfReports
