interface Props {
  as: 'back' | 'next'
  type: 'button' | 'submit'
  text: string | JSX.Element
  onClickHandler?: () => void
  hasNotError?: boolean
}

const StepButton = ({ as = 'next', type, text, onClickHandler, hasNotError }: Props) => {
  switch (as) {
    case 'next':
      return (
        <button
          disabled={hasNotError}
          type={type}
          onClick={onClickHandler}
          className='w-[142px] h-[44px] flex justify-center items-center gap-2 text-base ml-auto rounded-[5px] text-white bg-[#0c75ff] cursor-pointer hover:opacity-90 transition-opacity'
        >
          {text}
        </button>
      )
    case 'back':
      return (
        <button
          type='button'
          className='text-base font-medium cursor-pointer'
          onClick={onClickHandler}
        >
          {text}
        </button>
      )
  }
}

export default StepButton
