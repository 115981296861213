import { NavLink as Link, useLocation } from 'react-router-dom'
import { useModalContext } from '../../context/modal/modal'

interface Props {
  path?: string
  text: string
  type?: 'link' | 'button'
  onClickHandler?: () => void
}

const HamburgerNavLink = ({ path = '', text, type = 'link', onClickHandler }: Props) => {
  const { pathname } = useLocation()
  const activeLinkBg = ' text-primaryBlue font-bold'
  const { isModalOpenHandler } = useModalContext()

  switch (type) {
    case 'link':
      return (
        <li onClick={isModalOpenHandler}>
          <Link
            to={path}
            className={({ isActive }) =>
              'flex items-center justify-center gap-5 xl:px-9 xl:justify-start pb-1 text-2xl ' +
              (isActive
                ? activeLinkBg
                : pathname === '/compliance-officer' && path === 'dashboard'
                ? activeLinkBg
                : pathname === '/admin' && path === 'users'
                ? activeLinkBg
                : pathname === '/admin/create-user' && path === 'users'
                ? activeLinkBg
                : pathname === '/hr'
                ? activeLinkBg
                : 'font-medium')
            }
          >
            {text}
          </Link>
        </li>
      )
    case 'button':
      return (
        <button
          onClick={onClickHandler}
          className={`flex items-center justify-center gap-5 xl:px-9 xl:justify-start pb-1 text-2xl ${
            pathname.startsWith('/check-report') ? activeLinkBg : 'font-medium'
          }`}
        >
          {text}
        </button>
      )
  }
}

export default HamburgerNavLink
