import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactDOM from 'react-dom'
import { useFormik } from 'formik'
import { useModalContext } from '../../context/modal/modal'
import { useReportsContext } from '../../context/reports/reports'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import axios from '../../services/api'
import { toast } from 'react-toastify'
import useWidth from '../../hooks/useWidth'
import Button from '../Buttons/Button'
import Input from '../Formik/Input'
import InputWrapper from '../Formik/InputWrapper'
import Label from '../Formik/Label'
import Spinner from '../UI/Spinner'
import Backdrop from '../UI/Backdrop'

const AccessReportModal = () => {
  const { t, i18n } = useTranslation()
  const { closeUniqueCodeDialogHandler } = useModalContext()
  const { setReportersReport } = useReportsContext()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const { width } = useWidth()
  const isEl = i18n.language === 'el'

  const validationSchema = yup.object({
    uniqueCode: yup.string().trim().required(),
  })

  const formik = useFormik({
    initialValues: {
      uniqueCode: '',
    },
    validationSchema,
    onSubmit: async values => {
      try {
        setIsLoading(true)
        const response = await axios.get('/report/report/retrieve-report/', {
          headers: {
            Token: values.uniqueCode.trim(),
          },
        })

        if (response.status === 200) {
          setReportersReport(response.data.data)
          sessionStorage.setItem('reportToken', JSON.stringify(response.data.data.token))
          navigate(`/check-report/${response.data.data.token}`)
          closeUniqueCodeDialogHandler()
        }
      } catch (error) {
        toast.error('Invalid token')
        setIsLoading(false)
      }
    },
  })

  return ReactDOM.createPortal(
    <>
      <Backdrop onClickHandler={closeUniqueCodeDialogHandler} />
      <form
        onSubmit={formik.handleSubmit}
        className={`fixed z-50 bg-white min-w-[200px] max-w-[370px] w-full xsm:max-w-[500px] px-8 py-6 sm:px-11 sm:py-9 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-20px ${
          isEl ? 'sm:max-w-[610px] ' : 'sm:max-w-[593px]'
        }`}
      >
        <h2 className='font-bold text-2xl'>{t('accessReportModalTitle')}</h2>
        <p className='font-base text-lightGray mb-[18px] mt-[22px]'>{t('accessReportModalText')}</p>
        <InputWrapper>
          <Label text={t('accessReportModalLabel')} />
          <Input
            id='Unique Code'
            as='input'
            type='text'
            autoComplete='off'
            placeholder={t('accessReportModalPlaceholder')}
            hasError={formik.touched.uniqueCode && formik.errors.uniqueCode ? true : false}
            {...formik.getFieldProps('uniqueCode')}
          />
        </InputWrapper>
        <div className='mt-8 flex gap-[14px] justify-end flex-col xsm:flex-row'>
          <button
            type='button'
            onClick={closeUniqueCodeDialogHandler}
            className='font-medium font-base rounded-5px py-[13px] px-7 bg-[#F6F8F9] border-[1px] border-solid border-borderGray hover:opacity-90 transition-opacity cursor-pointer'
          >
            {t('accessReportModalCancelBtn')}
          </button>
          <Button
            type='submit'
            width={width && width <= 500 ? 'min-w-full' : 'w-full'}
            text={`${isLoading ? 'Loading' : t('accessReportModalChecklBtn')}`}
          >
            {isLoading && <Spinner spinner='form' />}
          </Button>
        </div>
      </form>
    </>,
    document.getElementById('overlay') as HTMLElement
  )
}

export default AccessReportModal
