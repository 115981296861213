import { createContext, useContext, useEffect, useState } from 'react'
import useAxios from '../../hooks/useAxios'
import { UserTypes } from '../../interfaces/user'
import { Props } from '../auth/auth.interfaces'

interface UserCtxTypes {
  activeUsers: UserTypes[]
  complianceOfficers: UserTypes[]
  isUserLoading: boolean
}

const usersCtx = createContext<UserCtxTypes>({
  activeUsers: [],
  complianceOfficers: [],
  isUserLoading: false,
})

export const useUsersContext = (): UserCtxTypes => useContext(usersCtx)

const UsersProvider = ({ children }: Props) => {
  const [complianceOfficers, setComplianceOfficers] = useState<UserTypes[]>([])
  const [activeUsers, setActiveUsers] = useState<UserTypes[]>([])
  const { data: allUsers, isLoading: isUserLoading } = useAxios({
    url: '/auth/user/',
    swrKey: 'users',
  })

  // Get Compliance Officers
  useEffect(() => {
    if (allUsers) {
      const filteredComplianceOfficers = allUsers.filter(
        (user: UserTypes) => user.user_type === 'COMPLIANCE_OFFICER'
      )

      setComplianceOfficers(filteredComplianceOfficers)
    }
  }, [allUsers])

  // Get Active Users (is_active: true)
  useEffect(() => {
    if (allUsers) {
      const filteredActiveUsers = allUsers.filter((user: UserTypes) => user?.is_active === true)

      setActiveUsers(filteredActiveUsers)
    }
  }, [allUsers])

  const ctx = {
    activeUsers,
    complianceOfficers,
    isUserLoading,
  }
  return <usersCtx.Provider value={ctx}>{children}</usersCtx.Provider>
}

export default UsersProvider
