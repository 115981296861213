import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useSWR, { useSWRConfig } from 'swr'
import DatePicker, { registerLocale } from 'react-datepicker'
import CustomDatePicker from '../UI/CustomDatePicker'
import ChartTitle from '../UI/ChartTitle'
import axios from '../../services/api'
import moment from 'moment'
import { el, tr } from 'date-fns/locale'
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts'
import { useAuthContext } from '../../context/auth/auth'
import 'react-datepicker/dist/react-datepicker.css'

registerLocale('el', el)
registerLocale('tr', tr)

const ConclusionType = () => {
  const firstDateOfTheYear = new Date(new Date().getFullYear(), 0, 1)
  const currentDate = new Date()
  const { t, i18n } = useTranslation()
  const { token } = useAuthContext()
  const { mutate } = useSWRConfig()
  const [statsData, setStatsData] = useState<{ name: string; conclusionNumber: number }[]>([])
  const [dateRange, setDateRange] = useState<Date[]>([firstDateOfTheYear, currentDate])
  const [startDate, endDate] = dateRange

  const fetchConclusionTypes = async () => {
    const response = await axios.post(
      '/report/stats/by_conclusion/',
      {
        from: moment(startDate).format('MM/DD/YYYY'),
        to: moment(endDate).add(1, 'days').format('MM/DD/YYYY'),
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return response.data
  }

  const { data: conclusionStats } = useSWR(
    token && startDate && endDate ? 'conclusionTypes' : null,
    fetchConclusionTypes
  )

  useEffect(() => {
    if (conclusionStats) {
      const transformedData = Object.keys(conclusionStats).map(key => ({
        name: t(key),
        conclusionNumber: conclusionStats[key],
      }))

      setStatsData(transformedData)
    }
    mutate('conclusionTypes')
  }, [conclusionStats, t, mutate])

  useEffect(() => {
    mutate('conclusionTypes')
  }, [endDate, mutate])

  const barColors = ['#F98484', '#FFDD87', '#00BFBF', '#025FDA']

  return (
    <div className='w-full h-full bg-white shadow-form rounded-20px p-6'>
      <div className='flex items-center justify-between mb-4 flex-col sm:flex-row'>
        <ChartTitle text={t('conclusionType')} />
        <DatePicker
          locale={i18n.language === 'el' ? 'el' : i18n.language === 'tr' ? 'tr' : 'en'}
          dateFormat='dd/MM/yyyy'
          calendarClassName='bg-white'
          maxDate={new Date()}
          selectsRange={true}
          startDate={startDate}
          placeholderText='Select Date Range'
          endDate={endDate}
          onChange={update => {
            setDateRange(update as Date[])
          }}
          customInput={<CustomDatePicker />}
        />
      </div>
      <ResponsiveContainer width='100%' height='75%' className='bg-transparent rounded-20px'>
        <BarChart
          layout='vertical'
          data={statsData}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis type='number' tickMargin={10} />
          <YAxis
            tickLine={false}
            padding={{ bottom: 0, top: 0 }}
            width={110}
            dataKey='name'
            type='category'
            fontSize={13}
            tickMargin={15}
          />
          <Bar dataKey='conclusionNumber' barSize={40} fill='transparent'>
            {statsData.map((_, index) => (
              <Cell key={`cell-${index}`} fill={barColors[index % barColors.length]} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

export default ConclusionType
