import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { ReportTypes } from '../../interfaces/report'
import { ActivityLogTypes } from '../../interfaces/activityLog'
import ReportLog from './ReportLog'
import PageTitle from '../UI/PageTitle'
import Spinner from '../UI/Spinner'
import ErrorMsg from '../UI/ErrorMsg'
import useAxios from '../../hooks/useAxios'

interface Props {
  report: ReportTypes
  printVisibility?: 'print:hidden' | 'print:block'
  visibility?: 'hidden' | 'block'
}

const ReportLogs = ({ report, printVisibility = 'print:hidden', visibility = 'block' }: Props) => {
  const { t } = useTranslation()
  const [activityLogs, setActivityLogs] = useState<ActivityLogTypes[] | undefined>(undefined)
  const {
    data: reportLogs,
    error,
    isLoading,
  } = useAxios({ url: '/report/action-log-item/', swrKey: `reportLogs` })

  useEffect(() => {
    if (reportLogs) {
      const filteredLogs = reportLogs
        .filter((log: ActivityLogTypes) => log.report === report?.id)
        .sort((a: ActivityLogTypes, b: ActivityLogTypes) =>
          a.date_created.localeCompare(b.date_created)
        )

      setActivityLogs(filteredLogs)
    }
  }, [reportLogs, report])

  const displayLogs =
    activityLogs && activityLogs.map(log => <ReportLog key={log.id} activityLog={log} />)

  return (
    <div
      className={`bg-white py-8 px-3 md:px-8 rounded-20px mt-8 shadow-form lg:w-[60%] rounded-tl-none min-h-full max-h-[750px] overflow-y-auto no-scrollbar print:overflow-y-visible print:max-h-full print:shadow-none print:break-inside-avoid  ${printVisibility} ${visibility}`}
    >
      <PageTitle title={t('activityLog')} />
      <div className='flex flex-col gap-2 border-t-[1px] border-solid border-borderGray pt-5 mt-8 '>
        {isLoading ? (
          <div className='min-h-[calc(750px-125px)] flex m-auto'>
            <Spinner spinner='table' width='w-11' height='h-11' color='#0C75FF' />
          </div>
        ) : error ? (
          <ErrorMsg text='Failed to load activity logs' />
        ) : (
          displayLogs
        )}
      </div>
    </div>
  )
}

export default ReportLogs
