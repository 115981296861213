import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ConclusionType from '../../components/Charts/ConclusionType'
import MostReportedCategories from '../../components/Charts/MostReportedCategories'
import NumberOfReports from '../../components/Charts/NumberOfReports'
import ContentSection from '../../components/UI/ContentSection'
import PageTitle from '../../components/UI/PageTitle'

const Statistics = () => {
  const { t } = useTranslation()
  useEffect(() => {
    document.title = `${t('statistics')} - ReportAll`
  }, [t])
  return (
    <ContentSection>
      <PageTitle title={t('overviewStatistics')} />

      <div className='h-[calc(100%-58px)] flex flex-col gap-4 md:gap-5 mt-4 '>
        <div className='h-full 2xl:h-1/2'>
          <NumberOfReports />
        </div>
        <div className='h-[calc(100%+340px)] 2xl:h-1/2 flex gap-4 md:gap-5 flex-col 2xl:flex-row'>
          <ConclusionType />
          <MostReportedCategories />
        </div>
      </div>
    </ContentSection>
  )
}

export default Statistics
