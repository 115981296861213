import { useTranslation } from 'react-i18next'
import { getStatusClassColors } from '../../helpers/getStatusClassColors'
import { ReportTypes } from '../../interfaces/report'

interface Props {
  numberOfReports: number
  status: ReportTypes['status'] | 'TOTAL'
}

const StatusNumber = ({ numberOfReports, status }: Props) => {
  const { t } = useTranslation()
  const colorClass = getStatusClassColors(status)
  const text = t(status)
  return (
    <div className='max-w-[100px] gap-4 flex flex-col items-center justify-self-center'>
      <p className='text-black font-bold text-5xl'>{numberOfReports}</p>
      <p className={`${colorClass} w-[125px] text-sm py-1 text-center rounded-lg capitalize font-medium`}>
        {text?.replace('_', ' ').toLowerCase()}
      </p>
    </div>
  )
}

export default StatusNumber
