import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DatePicker, { registerLocale } from 'react-datepicker'
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts'
import useSWR, { useSWRConfig } from 'swr'
import { useAuthContext } from '../../context/auth/auth'
import axios from '../../services/api'
import moment from 'moment'
import ChartTitle from '../UI/ChartTitle'
import { el, tr } from 'date-fns/locale'
import CustomDatePicker from '../UI/CustomDatePicker'
import 'react-datepicker/dist/react-datepicker.css'
import useAxios from '../../hooks/useAxios'
import { CategoryTypes } from '../../interfaces/category'

registerLocale('el', el)
registerLocale('tr', tr)

const RADIAN = Math.PI / 180
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, _ }: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 1.1
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  return (
    <text
      className='text-[0.8125rem]'
      x={x}
      y={y}
      fill='#3D3D3D'
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline='central'
    >
      {`${(percent * 100).toFixed(0)}%` === '0%' ? '' : `${(percent * 100).toFixed(0)}%`}
    </text>
  )
}

const MostReportedCategories = () => {
  const firstDateOfTheYear = new Date(new Date().getFullYear(), 0, 1)
  const currentDate = new Date()
  const { t, i18n } = useTranslation()
  const { token } = useAuthContext()
  const { mutate } = useSWRConfig()
  const [statsData, setStatsData] = useState<{ name: string; value: number }[]>([])
  const [dateRange, setDateRange] = useState<Date[]>([firstDateOfTheYear, currentDate])
  const [startDate, endDate] = dateRange

  const fetchPieChartDateRange = async () => {
    const response = await axios.post(
      '/report/stats/by_date_range/',
      {
        from: moment(startDate).format('MM/DD/YYYY'),
        to: moment(endDate).add(1, 'days').format('MM/DD/YYYY'),
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return response.data
  }

  const { data: pieStats } = useSWR(
    token && startDate && endDate ? 'pieChartDateRange' : null,
    fetchPieChartDateRange
  )

  const { data: categories } = useAxios({
    url: '/report/category/organization-code/',
    swrKey: 'categories',
    method: 'POST',
  })

  useEffect(() => {
    if (pieStats && categories && categories?.length !== 0) {
      // Data transformation for the pie chart
      const transformedStats = Object.keys(pieStats).map((key: string) => {
        const pieValue: { name: string; value: number } = {
          name: '',
          value: -1,
        }

        categories.forEach((cat: CategoryTypes) => {
          if (key === cat.id.toString()) {
            pieValue['name'] = cat.title
            pieValue['value'] = pieStats[key]
          }
        })

        return pieValue
      })

      // Sort the data by value in descending order
      transformedStats.sort((a: { value: number }, b: { value: number }) => {
        return b.value - a.value
      })

      // Get the TOP 5 most reported categories
      const top5ReportedCategories = transformedStats.slice(0, 5)

      // Get the sum of all the values in categories which are NOT in Top 5
      const allOtherCategoriesReportsNumber: number = transformedStats
        .slice(5)
        .reduce((acc: number, curr: { value: number }) => acc + curr.value, 0)

      top5ReportedCategories.push({ name: t('other'), value: allOtherCategoriesReportsNumber })

      // Set the data to the state and
      setStatsData(top5ReportedCategories)
    }
  }, [pieStats, categories, t, mutate])

  useEffect(() => {
    mutate('pieChartDateRange')
  }, [endDate, mutate])

  const pieColors = ['#F98484', '#00BFBF', '#FCB875', '#009DE9', '#FFDD87', '#025FDA']

  const hasNoValues = statsData && statsData.every(stat => stat.value === 0)

  return (
    <div className='w-full h-full bg-white shadow-form rounded-20px p-6'>
      <div className='flex items-center justify-between mb-4 flex-col sm:flex-row '>
        <ChartTitle text={t('mostReportedCategories')} />
        <DatePicker
          locale={i18n.language === 'el' ? 'el' : i18n.language === 'tr' ? 'tr' : 'en'}
          dateFormat='dd/MM/yyyy'
          calendarClassName='bg-white'
          maxDate={new Date()}
          selectsRange={true}
          startDate={startDate}
          placeholderText='Select Date Range'
          endDate={endDate}
          onChange={update => {
            setDateRange(update as Date[])
          }}
          customInput={<CustomDatePicker />}
        />
      </div>
      <div className='sm:h-[calc(100%-28px)] flex flex-col sm:items-center sm:flex-row gap-6'>
        {hasNoValues ? (
          <div className='w-full  flex justify-center text-center'>
            <p className='text-lightGray text-lg'>{t('noDataFoundForTheSelectedPeriod')}</p>
          </div>
        ) : (
          <ResponsiveContainer
            width='100%'
            height='75%'
            minHeight={250}
            className='bg-transparent rounded-20px p-0'
          >
            <PieChart margin={{ top: 0, left: 0, right: 0, bottom: 0 }}>
              <Pie
                blendStroke={true}
                data={statsData}
                cx='50%'
                cy='47%'
                labelLine={false}
                label={renderCustomizedLabel}
                fill='#8884d8'
                dataKey='value'
              >
                {statsData.map((_, index) => (
                  <Cell key={`cell-${index}`} fill={pieColors[index % pieColors.length]} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        )}

        <div className='w-[90%] grid grid-cols-2 gap-y-11 mx-auto sm:mx-0'>
          {statsData &&
            statsData.length !== 0 &&
            statsData.map((stat: { name: string }, idx: number) => (
              <div key={stat.name} className='flex items-center gap-2 w-1/2 ml-[20%] sm:w-auto sm:ml-0'>
                <div style={{ backgroundColor: pieColors[idx] }} className={`min-w-[1rem] min-h-[1rem]`} />
                <p title={stat.name} className='truncate text-[0.8125rem]'>
                  {stat.name}
                </p>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default MostReportedCategories
