import { ReportTypes } from '../interfaces/report'

export const getStatusClassColors = (status: ReportTypes['status'] | 'TOTAL') => {
  let colorClass
  switch (status) {
    case 'UNASSIGNED':
      colorClass = 'bg-unAssignedBg text-unAssignedText'
      break
    case 'IN_PROGRESS':
      colorClass = 'bg-inProgressBg text-inProgressText'
      break
    case 'CLOSED':
      colorClass = 'bg-closedBg text-closedText'
      break
    case 'TOTAL':
      colorClass = 'bg-totalBg text-totalText'
      break
  }

  return colorClass
}
