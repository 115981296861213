import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import axios from '../../services/api'
import Input from '../Formik/Input'
import InputWrapper from '../Formik/InputWrapper'
import Label from '../Formik/Label'
import Button from '../Buttons/Button'
import Spinner from '../UI/Spinner'
import { useTranslation } from 'react-i18next'
import FormikForm from '../Formik/FormikForm'

const ForgotPasswordForm = () => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const navigate = useNavigate()

  const initialValues = {
    email: '',
  }

  const validationSchema = yup.object({
    email: yup.string().email().required(),
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async values => {
      try {
        setIsLoading(true)
        const response = await axios.post('/auth/password-reset-request/', {
          email: values.email,
        })
        if (response.status === 200) {
          setIsLoading(false)
          toast.success(t('aResetLinkHasBeenSentToYourEmail'))
          navigate('/login')
        }
      } catch (error) {
        setIsLoading(false)
        toast.error(t('submitFailed'))
      }
    },
  })

  return (
    <FormikForm onSubmitHandler={formik.handleSubmit} formType='lg'>
      <div className='mb-3'>
        <h2 className='font-bold text-center text-2xl sm:text-3xl mb-4'>{t('forgotPassword')}</h2>
        <p className='text-lightGray text-center'>{t('enterYourEmailToResetYourPassword')}</p>
      </div>

      <InputWrapper>
        <Label text='Email' />
        <Input
          id='Email'
          as='input'
          type='text'
          placeholder={t('insertEmail')}
          hasError={formik.touched.email && formik.errors.email ? true : false}
          {...formik.getFieldProps('email')}
        />
      </InputWrapper>
      <Button width='min-w-full' type='submit' text={isLoading ? 'Loading...' : t('submit')}>
        {isLoading && <Spinner spinner='form' />}
      </Button>
    </FormikForm>
  )
}

export default ForgotPasswordForm
