import { useTranslation } from 'react-i18next'

interface Props {
  title: string
  text: string
  anonymityLevel: boolean
  onClickHandler: () => void
}

const AnonymityLevel = ({ title, text, anonymityLevel, onClickHandler }: Props) => {
  const { t } = useTranslation()
  const borderRadiusClass =
    title === t('anonymousReporting')
      ? 'rounded-t-10px md:rounded-bl-10px md:rounded-tl-10px md:rounded-tr-none'
      : title === t('regularReporting')
      ? 'rounded-b-10px md:rounded-br-10px md:rounded-tr-10px md:rounded-bl-none'
      : ''

  return (
    <div
      className={`${borderRadiusClass} border-solid border-[1px] border-borderGray p-5 md:h-[14.5rem] xl:h-[13rem] cursor-pointer ${
        anonymityLevel ? 'bg-[#0c75ff] text-white' : 'bg-white'
      }`}
      onClick={onClickHandler}
    >
      <div className='flex flex-col h-full justify-between gap-3'>
        <p className='text-md  font-semibold'>{title}</p>
        <p className='text-sm'>{text}</p>
        <p />
      </div>
    </div>
  )
}

export default AnonymityLevel
