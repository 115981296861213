interface Props {
  text: string;
  stepNumber: string;
}

const Step = ({ stepNumber, text }: Props) => {
  return (
    <div className='mx-auto w-[8.125rem] md:w-max md:m-0 flex items-center h-10 md:px-2 md:py-2 -mb-px text-center sm:px-4 -px-1  whitespace-nowrap focus:outline-none'>
      <span className='px-4 py-2 mr-1 md:m-2 rounded-full font-semibold bg-gray-200 text-gray-500'>
        {stepNumber}
      </span>
      <span className='mx-1 text-sm sm:text-base font-semibold'>{text}</span>
    </div>
  );
};

export default Step;
