import { BsFileEarmarkArrowDown } from 'react-icons/bs'

interface Props {
  text: string
  status: 'reporter' | 'complianceOfficer'
}

const ExportButton = ({ text, status }: Props) => {
  let btnClasses = ''
  switch (status) {
    case 'complianceOfficer':
      btnClasses = 'rounded-10px bg-white border-0 shadow-header'
      break
    case 'reporter':
      btnClasses = 'rounded-5px bg-transparent border-solid border-[1px] border-lightGray'
      break
  }

  return (
    <button
      className={`${btnClasses} flex items-center justify-center gap-[10px] text-lightGray font-medium py-3 px-4 cursor-pointer print:hidden`}
      onClick={() => window.print()}
    >
      <BsFileEarmarkArrowDown className=' text-lightGray text-xl font-medium' />
      {text}
    </button>
  )
}

export default ExportButton
