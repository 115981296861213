import { useState } from 'react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { useSWRConfig } from 'swr'
import axios from '../../services/api'
import * as yup from 'yup'
import { useReportsContext } from '../../context/reports/reports'
import InputWrapper from '../Formik/InputWrapper'
import { BsCheckCircleFill, BsXCircleFill } from 'react-icons/bs'
import { useAuthContext } from '../../context/auth/auth'

const UpdateOrgName = () => {
  const { t } = useTranslation()
  const { mutate } = useSWRConfig()
  const { selectedOrg } = useReportsContext()
  const { token } = useAuthContext()
  const [isFocused, setIsFocused] = useState<boolean>(false)

  const validationSchema = yup.object({
    orgName: yup.string().trim().required('Company name is required'),
  })

  const formik = useFormik({
    initialValues: {
      orgName: selectedOrg ? selectedOrg?.title.trim() : '',
    },
    validationSchema,
    onSubmit: async values => {
      try {
        const response = await axios.patch(
          `/organizations/organization/${selectedOrg?.id}/update-organization-name/`,
          {
            title: values.orgName.trim(),
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )

        if (response.status === 200) {
          toast.success(t('companyNameHasBeenUpdated'))
          mutate('org')
          setIsFocused(false)
        }
      } catch (error) {
        toast(t('failedToUpdateCompanyName'))
      }
    },
  })

  const hasError = formik.touched.orgName && formik.errors.orgName ? 'border-red-500' : 'border-borderGray'

  return (
    <form className='flex flex-col gap-3' onSubmit={formik.handleSubmit}>
      <InputWrapper>
        <div
          onFocus={() => setIsFocused(true)}
          className={`${hasError} cursor-default bg-transparent rounded-lg px-3 py-2 w-full md:w-[17.5rem] border-[1px] border-solid hover:border-primaryBlue transition-all`}
        >
          <input
            autoComplete='off'
            className={`bg-transparent border-0 p-0 w-full scale-95 focus:outline-none font-bold ${
              isFocused ? 'cursor-text' : 'cursor-default'
            }`}
            type='text'
            {...formik.getFieldProps('orgName')}
          />
        </div>
      </InputWrapper>
      <div className='flex items-center gap-3 self-end'>
        {isFocused &&
          formik.values.orgName.trim() !== selectedOrg?.title.trim() &&
          formik.values.orgName.trim().length !== 0 && (
            <>
              <button type='submit'>
                <BsCheckCircleFill className='text-2xl text-green-500 cursor-pointer' />
              </button>

              <BsXCircleFill
                className='text-2xl text-red-500 cursor-pointer'
                onClick={() => {
                  formik.setFieldValue('orgName', selectedOrg?.title.trim())
                  setIsFocused(false)
                }}
              />
            </>
          )}
      </div>
    </form>
  )
}

export default UpdateOrgName
