interface Props {
  textLeft: string
  textRight: string | JSX.Element | undefined
  as: 'title' | 'text'
}

const ReviewInfo = ({ as, textLeft, textRight }: Props) => {
  return (
    <div className='flex items-center justify-between'>
      <p className={`${as === 'title' ? 'font-bold text-2xl' : 'font-base font-bold text-lg'}`}>
        {textLeft}
      </p>
      <p className={`${as === 'title' ? 'font-thin' : 'font-base capitalize text-textBlack'}`}>
        {textRight}
      </p>
    </div>
  )
}

export default ReviewInfo
