import React from 'react'

interface Props {
  children: React.ReactNode
  formType?: 'sm' | 'lg'
  paddingX?: 'sm:px-28' | 'sm:px-24'
  onSubmitHandler: () => void
}

const FormikForm = ({
  children,
  formType = 'sm',
  paddingX = 'sm:px-24',
  onSubmitHandler,
}: Props) => {
  let formClasses: string = ''

  switch (formType) {
    case 'sm':
      formClasses = 'p-11 md:p-14 mt-10 max-w-[52.9375rem]'
      break

    case 'lg':
      formClasses = `max-w-[36.25rem] w-full flex flex-col py-12 sm:py-16 px-7 xsm:px-20 gap-7 ${paddingX}`
  }

  return (
    <form onSubmit={onSubmitHandler} className={`bg-white rounded-20px shadow-form ${formClasses}`}>
      {children}
    </form>
  )
}

export default FormikForm
