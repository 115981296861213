import { forwardRef } from 'react'
import { FiCalendar } from 'react-icons/fi'

const CustomDatePicker = forwardRef(({ value, onClick }: any, ref: any) => (
  <button
    className='flex items-center gap-5 border-[1px] border-solid border-lightGray rounded-lg text-sm text-textBlack px-3 py-3'
    onClick={onClick}
    ref={ref}
  >
    {value}
    <FiCalendar className='text-base' />
  </button>
))

export default CustomDatePicker
