import SideLinks from '../components/ReporterForm/SideLinks';

interface Props {
  activePage?: 'make-report' | 'check-report';
  children: React.ReactNode;
}

const FormContainer = ({ children, activePage = 'make-report' }: Props) => {
  let activeClasses: string = '';

  switch (activePage) {
    case 'make-report':
      activeClasses =
        'px-4 pt-4 pb-10 sm:py-10 sm:px-11 lg:py-12 lg:px-20 xsm:shadow-form lg:max-w-[60rem]';
      break;
    case 'check-report':
      activeClasses = 'h-full shadow-none p-0 max-w-[78.75rem]';
      break;
  }

  return (
    <div className='bg-mainBg h-[calc(100%-106px)]'>
      <div className='p-0 xsm:pt-16 lg:pb-16 xxl:py-8 bg-mainBg print:xsm:pt-2'>
        <div className={`relative bg-white lg:mx-auto lg:rounded-20px ${activeClasses}`}>
          <SideLinks />
          {children}
        </div>
      </div>
    </div>
  );
};

export default FormContainer;
