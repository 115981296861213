import { useTranslation } from 'react-i18next'
import { NavLink as Link, useLocation } from 'react-router-dom'

interface Props {
  children: React.ReactNode
  path: string
  text: string
}

const NavLink = ({ children, path, text }: Props) => {
  const { i18n } = useTranslation()
  const { pathname } = useLocation()
  const activeLinkBg = ' bg-[rgba(255,255,255,0.19)]'
  const paddingX = i18n.language === 'tr' ? 'xl:px-[25px]' : 'xl:px-9'

  return (
    <li>
      <Link
        to={path}
        className={({ isActive }) =>
          `${paddingX} flex items-center justify-center gap-5 xl:justify-start py-5 rounded-10px` +
          (isActive
            ? activeLinkBg
            : pathname === '/compliance-officer' && path === 'dashboard'
            ? activeLinkBg
            : pathname === '/admin' && path === 'users'
            ? activeLinkBg
            : pathname === '/admin/create-user' && path === 'users'
            ? activeLinkBg
            : pathname === '/hr' && path === 'statistics'
            ? activeLinkBg
            : '')
        }
      >
        {children}
        <span className='hidden xl:block text-2xl font-normal text-white'>{text}</span>
      </Link>
    </li>
  )
}

export default NavLink
