import { useReportsContext } from '../../context/reports/reports'

const OrganizationCode = () => {
  const { adminCompanyCode } = useReportsContext()
  return (
    <p className='bg-transparent font-bold rounded-lg px-3 py-2 w-full md:w-[17.5rem] border-[1px] border-solid'>
      {adminCompanyCode && adminCompanyCode}
    </p>
  )
}

export default OrganizationCode
