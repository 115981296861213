import { useNavigate } from 'react-router-dom'
import { useModalContext } from '../../context/modal/modal'
import { RiDeleteBin6Line } from 'react-icons/ri'
import { BiEditAlt } from 'react-icons/bi'
import { CategoryTypes } from '../../interfaces/category'
import { useTranslation } from 'react-i18next'
import { BranchTypes } from '../../interfaces/organization'
import ModalConfirmationDialog from '../Modals/ModalConfirmationDialog'

interface Props {
  data: CategoryTypes | BranchTypes
  type: 'category' | 'branch'
}

const CompanyBranchCategory = ({ data, type }: Props) => {
  const { t } = useTranslation()
  const { showConfirmationDialog, showConfirmationDialogHandler } = useModalContext()
  const navigate = useNavigate()

  const path = type === 'category' ? 'categories/edit-category' : 'branches/edit-branch'

  return (
    <>
      {showConfirmationDialog && (
        <ModalConfirmationDialog
          text={
            type === 'category'
              ? t('areYouSureWantToDeleteThisCategory')
              : t('areYouSureWantToDeleteThisBranch')
          }
          api={type === 'category' ? '/report/category' : 'organizations/branch'}
          toastSuccess={t(type === 'category' ? 'categoryHasBeenDeleted' : 'branchHasBeenDeleted')}
          toastError={t(type === 'category' ? 'failedToDeleteCategory' : 'failedToDeleteBranch')}
          swrKey={type === 'category' ? 'categories' : 'org'}
        />
      )}
      <div className='flex items-center justify-between px-5 xsm:px-8 border-[1px] border-solid rounded-lg border-borderGray text-lightGray text-base font-normal h-[5rem]'>
        <p>{data.title}</p>
        <div className='flex gap-5'>
          <BiEditAlt
            className='text-primaryBlue text-lg cursor-pointer hover:opacity-80 transition-opacity'
            onClick={() => navigate(`/admin/${path}/${data.id}`)}
          />
          <RiDeleteBin6Line
            className='text-deleteRed text-lg cursor-pointer hover:opacity-80 transition-opacity'
            onClick={() => showConfirmationDialogHandler(data.id)}
          />
        </div>
      </div>
    </>
  )
}

export default CompanyBranchCategory
