import { useEffect, useState } from 'react'
import { ReportNoteTypes } from '../../interfaces/reportNote'
import ComplianceOfficerNote from './ComplianceOfficerNote'
import CaseInfoMessage from '../UI/CaseInfoMessage'
import { useTranslation } from 'react-i18next'
import useAxios from '../../hooks/useAxios'
import ErrorMsg from '../UI/ErrorMsg'
import Spinner from '../UI/Spinner'

interface Props {
  reportId: number | undefined
}

const ComplianceOfficerNotes = ({ reportId }: Props) => {
  const { t } = useTranslation()
  const [selectedReportNotes, setSelectedReportNotes] = useState<ReportNoteTypes[]>([])
  const {
    data: reportNotes,
    error,
    isLoading,
  } = useAxios({
    url: '/report/report-note/',
    swrKey: `reportNotes`,
  })

  useEffect(() => {
    // GET THE NOTES FOR THE SELECTED REPORT ID
    if (reportNotes) {
      const filteredNotes = reportNotes.filter((note: ReportNoteTypes) => note.report === reportId)
      setSelectedReportNotes(filteredNotes)
    }
  }, [reportId, reportNotes])

  const displayNotes =
    selectedReportNotes &&
    selectedReportNotes.map((note: ReportNoteTypes) => (
      <ComplianceOfficerNote key={note.id} reportNote={note} />
    ))

  return (
    <div className='flex flex-col-reverse gap-6 w-full bg-white max-h-[500px] overflow-y-auto no-scrollbar'>
      {isLoading ? (
        <Spinner spinner='table' color='#0C75FF' height='h-5' width='w-5' />
      ) : error ? (
        <ErrorMsg text={t('somethingWentWrongFailedToLoadNotes')} />
      ) : displayNotes.length === 0 ? (
        <CaseInfoMessage text={t('youHaventAddedNotes')} />
      ) : (
        displayNotes?.reverse()
      )}
    </div>
  )
}

export default ComplianceOfficerNotes
