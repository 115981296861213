import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { translationEn } from '../langs/en'
import { translationEl } from '../langs/el'
import { translationTr } from '../langs/tr'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // debug: true,
    fallbackLng: 'en',
    resources: {
      en: {
        translation: translationEn,
      },
      el: {
        translation: translationEl,
      },
      tr: {
        translation: translationTr,
      },
    },
  })

export default i18n
