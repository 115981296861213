import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../context/auth/auth'
import { FiLogOut } from 'react-icons/fi'
import { useModalContext } from '../../context/modal/modal'
import { useTranslation } from 'react-i18next'

interface Props {
  type: 'mobile' | 'desktop'
}

const LogoutBtn = ({ type }: Props) => {
  const { t } = useTranslation('')
  const { logout } = useAuthContext()
  const navigate = useNavigate()
  const { isModalOpenHandler } = useModalContext()
  switch (type) {
    case 'desktop':
      return (
        <button
          onClick={() => {
            navigate('/login')
            logout()
          }}
          type='button'
          className='hidden sm:flex text-sm font-bold bg-transparent items-center gap-3'
        >
          <FiLogOut className='text-2xl' />
          <span>{t('logout')}</span>
        </button>
      )

    case 'mobile':
      return (
        <button
          onClick={() => {
            navigate('/login')
            logout()
            isModalOpenHandler()
          }}
          type='button'
          className=' md:hidden text-2xk font-medium bg-transparent items-center text-2xl'
        >
          {t('logout')}
        </button>
      )
  }
}

export default LogoutBtn
