interface Props {
  onClickHandler?: () => void;
}

const Backdrop = ({ onClickHandler }: Props) => {
  return (
    <div
      className="fixed inset-0 z-40 bg-[rgba(0,0,0,0.60)] print:hidden"
      onClick={onClickHandler}
    />
  );
};

export default Backdrop;
