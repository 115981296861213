interface Props {
  text: string;
}

const CompletedStep = ({ text }: Props) => {
  return (
    <div className='mx-auto flex w-[8.125rem] md:w-max md:m-0 items-center h-10 md:px-2 md:py-2 -mb-px text-center sm:px-4 -px-1  whitespace-nowrap focus:outline-none'>
      <span className='px-2 py-2 mr-1 md:m-2 rounded-full bg-[#E1ECFC] text-gray-500'>
        <svg
          className='h-7 w-7 text-white'
          xmlns='http://www.w3.org/2000/svg'
          fill='none'
          viewBox='0 0 24 24'
          stroke='currentColor'
          strokeWidth='2'
        >
          <path strokeLinecap='round' strokeLinejoin='round' d='M5 13l4 4L19 7' />
        </svg>
      </span>
      <span className='mx-1 text-sm sm:text-base font-semibold text-[#D5D5D5] '>{text}</span>
    </div>
  );
};

export default CompletedStep;
