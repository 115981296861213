import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSWRConfig } from 'swr'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import PageTitle from '../../components/UI/PageTitle'
import UserForm from '../../components/Forms/UserForm'
import { useAuthContext } from '../../context/auth/auth'
import { UserTypes } from '../../interfaces/user'
import axios from '../../services/api'

const CreateUser = () => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { token } = useAuthContext()
  const { mutate } = useSWRConfig()
  const navigate = useNavigate()

  const onSubmit = async (values: UserTypes) => {
    const userData = {
      username: values.username,
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      user_type: values.user_type,
    }
    try {
      setIsLoading(true)
      const response = await axios.post('/auth/user/', userData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      if (response.status === 201) {
        mutate('users')
        navigate('/admin/users')
        toast.success(t('userHasBeenCreated'))
      }
    } catch (error: any) {
      if (error.response.data.data[0].includes('email')) {
        toast.error(t('aUserWithThisEmailAlreadyExists'))
      } else if (error.response.data.data[0].includes('username')) {
        toast.error(t('aUserWithThisUsernameAlreadyExists'))
      } else {
        toast.error(t('failedToCreateUser'))
      }
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => () => setIsLoading(false), [])

  useEffect(() => {
    document.title = `${t('newUser')} - ReportAll`
  }, [t])

  return (
    <>
      <PageTitle title={t('newUser')} />
      <UserForm onSubmit={onSubmit} isLoading={isLoading} />
    </>
  )
}

export default CreateUser
