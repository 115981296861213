import { useTranslation } from 'react-i18next'

const PrintUniqueCode = () => {
  const { t } = useTranslation()
  return (
    <div className='hidden print:flex print:flex-col print:gap-2 print:mb-8 '>
      <h3 className='font-semibold text-lg'>{t('saveTokenUniqueCodeLabel')}</h3>
      <p className='text-lightGray border-lightGray text-base border-[1px] border-solid w-max rounded-lg px-4 py-3'>
        {JSON.parse(sessionStorage.getItem('reportToken')!)}
      </p>
    </div>
  )
}

export default PrintUniqueCode
