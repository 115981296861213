import { useState } from 'react'
import Lightbox from 'react-image-lightbox'
import { ReportMessageTypes } from '../../interfaces/reportMessage'
import docImg from '../../assets/doc-img.png'
import docxImg from '../../assets/docx-img.png'
import pdfImg from '../../assets/pdf-img.png'
import moment from 'moment'

interface Props {
  message: ReportMessageTypes
  isTheReporter: boolean
  isComplianceOfficer: boolean
}

const CaseMessage = ({ message, isTheReporter, isComplianceOfficer }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [photoIndex, setPhotoIndex] = useState<number>(0)

  const isMessageFromComplianceOfficer = isComplianceOfficer && message.user !== null

  const isMessageFromReporter = isTheReporter && message.user === null

  const alignItemClassWithMargin =
    isMessageFromReporter || isMessageFromComplianceOfficer ? 'self-end mr-2' : 'self-start ml-2'
  const alignItemClass = isMessageFromReporter || isMessageFromComplianceOfficer ? 'self-end' : 'self-start'

  const righText =
    'after:bg-[#E1ECFC] after:w-2 after:h-5 after:absolute after:z-10 after:top-1/2 after:transform after:-translate-y-1/2 after:-right-[8px] after:clip-right'

  const leftText =
    'after:bg-white after:w-2 after:h-5 after:absolute after:z-10 after:top-1/2 after:transform after:-translate-y-1/2 after:-left-[7px] after:clip-left before:bg-borderGray before:w-2 before:h-5 before:absolute before:z-10 before:top-1/2 before:transform before:-translate-y-1/2 before:-left-[8px] before:clip-left'

  // Get the images
  const onlyImages = message.media.filter(
    media => media.file.endsWith('.jpg') || media.file.endsWith('.jpeg') || media.file.endsWith('.png')
  )

  // Get the documents
  const onlyDocuments = message.media.filter(
    media => media.file.endsWith('.pdf') || media.file.endsWith('.docx') || media.file.endsWith('.doc')
  )

  // Get the videos
  const onlyVideos = message.media.filter(
    media => media.file.endsWith('.mp4') || media.file.endsWith('.mov') || media.file.endsWith('.mpeg')
  )

  // Get the audio
  const onlyAudio = message.media.filter(media => media.file.endsWith('.wav'))

  return (
    <div className={`flex flex-col gap-[2px] max-w-[80%] w-max ${alignItemClassWithMargin}`}>
      <p className={`text-[#3D3D3D] text-[10px] ${alignItemClass}`}>
        {moment(message.date_created).format('DD/MM/yyyy HH:mm')}
      </p>
      <div className='flex flex-col gap-1'>
        <p
          className={`py-2 px-3 rounded-5px relative whitespace-pre-line ${alignItemClass} ${
            isMessageFromReporter || isMessageFromComplianceOfficer
              ? `bg-[#E1ECFC] ${righText}`
              : `bg-white border-[1px] border-solid border-borderGray  ${leftText}`
          }`}
        >
          {message.content}
        </p>
        <div
          className={`flex flex-wrap items-center justify-end ${alignItemClass}  gap-2 text-lightGray mt-1`}
        >
          {onlyDocuments.length !== 0 &&
            onlyDocuments.map(file => (
              <a key={file.id} href={file.file} target='_blank' rel='noreferrer'>
                <img
                  width={30}
                  src={
                    file.file.endsWith('.pdf')
                      ? pdfImg
                      : file.file.endsWith('.docx')
                      ? docxImg
                      : file.file.endsWith('.doc')
                      ? docImg
                      : ''
                  }
                  alt=''
                />
              </a>
            ))}
          {onlyImages.length !== 0 && (
            <div
              className='cursor-pointer rounded-5px relative before:absolute before:inset-0 before:bg-[rgba(0,0,0,0.20)] before:rounded-5px'
              onClick={() => setIsOpen(true)}
            >
              <img width={50} src={onlyImages[0].file} alt='' className='rounded-5px' />
              {onlyImages.length > 1 && (
                <p className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white text-lg font-medium'>
                  +{onlyImages.length}
                </p>
              )}
            </div>
          )}
        </div>

        {onlyAudio.length !== 0 && (
          <div className={`flex flex-col items-end justify-end  gap-2 text-lightGray mt-1 ${alignItemClass}`}>
            {onlyAudio.map(file => (
              <audio
                key={file.id}
                src={file.file?.replace('http', 'https')}
                autoPlay={false}
                controls
                className='w-[250px] h-7'
              />
            ))}
          </div>
        )}

        {onlyVideos.length !== 0 && (
          <div className='flex flex-col gap-1 items-center justify-end  text-lightGray mt-1'>
            {onlyVideos.map(file => (
              <video
                key={file.id}
                src={file.file?.replace('http', 'https')}
                autoPlay={false}
                controls
                className='rounded-lg'
              />
            ))}
          </div>
        )}
      </div>
      {onlyImages.length !== 0 && isOpen && (
        <Lightbox
          mainSrc={onlyImages[photoIndex].file}
          onCloseRequest={() => setIsOpen(false)}
          nextSrc={onlyImages[(photoIndex + 1) % onlyImages.length].file}
          prevSrc={onlyImages[(photoIndex + onlyImages.length - 1) % onlyImages.length].file}
          onMovePrevRequest={() =>
            setPhotoIndex(prevState => (prevState + onlyImages.length - 1) % onlyImages.length)
          }
          onMoveNextRequest={() => setPhotoIndex(prevState => (prevState + 1) % onlyImages.length)}
        />
      )}
    </div>
  )
}

export default CaseMessage
