import { useEffect, useState } from 'react'
import { useSWRConfig } from 'swr'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import axios from '../../services/api'
import BranchesCategoryForm from '../../components/Forms/BranchesCategoryForm'
import PageTitle from '../../components/UI/PageTitle'
import { useAuthContext } from '../../context/auth/auth'
import { toast } from 'react-toastify'

const CreateBranch = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { t } = useTranslation()
  const { mutate } = useSWRConfig()
  const { authClient, token } = useAuthContext()
  const navigate = useNavigate()

  const onSubmit = async (values: { title: string }) => {
    try {
      setIsLoading(true)
      const response = await axios.post(
        '/organizations/branch/',
        {
          title: values.title,
          organization: authClient?.organization,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      if (response.status === 201) {
        toast.success(t('branchHasBeenCreated'))
        mutate('org')
        navigate(-1)
        setIsLoading(false)
      }
    } catch (error) {
      toast.error(t('failedToCreateBranch'))
      setIsLoading(false)
    }
  }

  useEffect(() => {
    document.title = `${t('newBranch')} - ReportAll`
  }, [t])

  return (
    <>
      <PageTitle title={t('newBranch')} />
      <BranchesCategoryForm onSubmit={onSubmit} isLoading={isLoading} label='branch' buttonText='newBranch' />
    </>
  )
}

export default CreateBranch
