import { useStepContext } from '../../context/steps/StepsProvider'
import { IoIosCloseCircle } from 'react-icons/io'
import docImg from '../../assets/doc-img.png'
import docxImg from '../../assets/docx-img.png'
import pdfImg from '../../assets/pdf-img.png'
import audioImg from '../../assets/audio-img.png'

interface Props {
  fileName: string
  preview: string
  onClickHandler?: () => void
}

const DropzoneFilePreview = ({ preview, onClickHandler, fileName }: Props) => {
  const { step } = useStepContext()
  const imgClasses = 'w-full h-full rounded-5px'

  let filePreview
  if (fileName.endsWith('.jpeg') || fileName.endsWith('.jpg') || fileName.endsWith('.png')) {
    filePreview = (
      <img src={preview} alt='' draggable={false} className={`${imgClasses} object-cover`} />
    )
  }
  if (fileName.endsWith('.docx')) {
    filePreview = (
      <img src={docxImg} alt='' draggable={false} className={`${imgClasses} object-contain`} />
    )
  }
  if (fileName.endsWith('.doc')) {
    filePreview = (
      <img src={docImg} alt='' draggable={false} className={`${imgClasses} object-contain`} />
    )
  }
  if (fileName.endsWith('.pdf')) {
    filePreview = (
      <img src={pdfImg} alt='' draggable={false} className={`${imgClasses} object-contain`} />
    )
  }
  if (fileName.endsWith('.wav')) {
    filePreview = (
      <img src={audioImg} alt='' draggable={false} className={`${imgClasses} object-contain`} />
    )
  }
  if (fileName.endsWith('.mp4') || fileName.endsWith('.mov')) {
    filePreview = (
      <video src={preview} controls draggable={false} className={`${imgClasses} object-cover`} />
    )
  }

  return (
    <div className='h-[170px] xsm:h-[130px] rounded-5px relative'>
      {filePreview}
      {step === 3 && (
        <IoIosCloseCircle
          className='absolute -top-[9%] -right-[6%] cursor-pointer text-2xl text-[#CD4444]'
          onClick={onClickHandler}
        />
      )}
    </div>
  )
}

export default DropzoneFilePreview
