import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ResetPasswordForm from '../../components/Forms/ResetPasswordForm'
import PublicLayout from '../../containers/PublicLayout'

const ResetPassword = () => {
  const { t } = useTranslation()
  useEffect(() => {
    document.title = `${t('resetPassword')} - ReportAll`
  }, [t])

  return (
    <PublicLayout>
      <ResetPasswordForm />
    </PublicLayout>
  )
}

export default ResetPassword
