import { useOutletContext, useParams, useLocation } from 'react-router-dom'
import { useAuthContext } from '../../../context/auth/auth'
import { useReportsContext } from '../../../context/reports/reports'
import { ReportTypes } from '../../../interfaces/report'
import { useTranslation } from 'react-i18next'
import BackBtn from '../../../components/Buttons/BackBtn'
import PageTitle from '../../../components/UI/PageTitle'
import CaseOverview from '../../../components/CaseOverview/CaseOverview'
import CaseMessages from '../../../components/CaseMessages/CaseMessages'
import { useState } from 'react'
import ReportLogs from '../../../components/ReportLogs/ReportLogs'
import Tab from '../../../components/UI/Tab'
import ResolutionOption from '../../../components/ResolutionOption/ResolutionOption'

const Report = () => {
  const { t } = useTranslation()
  const [isCaseOverview, setIsCaseOverview] = useState<boolean>(true)
  const [isReportLogs, setIsReportLogs] = useState<boolean>(false)
  const { token, authClient } = useAuthContext()
  const { reportersReport } = useReportsContext()
  const reports: ReportTypes[] = useOutletContext()
  // const navigate = useNavigate()
  const { pathname } = useLocation()
  const { id } = useParams()
  const report = reports?.find(report => report.id.toString() === id) || reportersReport!

  const isTheAssignedCo =
    authClient?.id === report?.assigned_co?.id && authClient?.user_type === 'COMPLIANCE_OFFICER'

  const isComplianceOfficer = authClient?.user_type === 'COMPLIANCE_OFFICER'

  const isTheReporter =
    JSON.parse(sessionStorage.getItem('reportToken')!) && !token && !isComplianceOfficer

  // TODO MAKE A NOT FOUND PAGE

  return (
    <>
      {isComplianceOfficer && (
        <div className='flex items-center justify-between flex-wrap gap-3'>
          <div className='flex flex-col gap-1'>
            <BackBtn />
            <div className='flex items-center gap-2'>
              <PageTitle title={t('report')} />
              {report && <p className='text-[1.75rem] font-bold'>#{report?.id}</p>}
            </div>
          </div>
          <ResolutionOption report={report} />
        </div>
      )}
      <div
        className={`flex flex-col lg:flex-row ${
          isComplianceOfficer
            ? 'gap-[0.9375rem] sm:gap-[1.25rem] md:gap-7 lg:gap-9 shadow-none rounded-none relative mt-14'
            : 'gap-0 shadow-form min-h-[63.5rem] max-h-[81rem] rounded-20px'
        } print:min-h-max print:shadow-none print:mt-0`}
      >
        {isComplianceOfficer && pathname.startsWith('/compliance-officer/reports') && (
          <div className='absolute flex bg-[#E1ECFC] -top-5 rounded-t-10px h-max print:hidden'>
            <Tab
              text={t('caseOverview')}
              onClickHandler={() => {
                setIsReportLogs(false)
                setIsCaseOverview(true)
              }}
              isActive={isCaseOverview}
            />
            <Tab
              text={t('activityLog')}
              onClickHandler={() => {
                setIsCaseOverview(false)
                setIsReportLogs(true)
              }}
              isActive={isReportLogs}
            />
          </div>
        )}
        {isCaseOverview && (
          <CaseOverview
            report={report}
            isComplianceOfficer={isComplianceOfficer}
            isTheReporter={isTheReporter}
          />
        )}

        {isReportLogs && <ReportLogs report={report} />}
        {isTheReporter && !isComplianceOfficer && (
          <div className='hidden lg:block min-h-full w-[1px] bg-borderGray my-8' />
        )}
        <CaseMessages
          report={report}
          isTheAssignedCo={isTheAssignedCo}
          isComplianceOfficer={isComplianceOfficer}
          isTheReporter={isTheReporter}
        />
      </div>
    </>
  )
}

export default Report
