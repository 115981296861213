import React from 'react';

interface Props {
  children: React.ReactNode;
}

const ContentSection = ({ children }: Props) => {
  return (
    <section className='p-5 md:px-7 lg:px-9 w-full sm:overflow-y-auto relative print:lg:overflow-y-visible'>
      {children}
    </section>
  );
};

export default ContentSection;
