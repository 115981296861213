import audioImg from '../../assets/audio-img.png'

interface Props {
  audio: { id: number; file: string }
}

const CaseAudioPreview = ({ audio }: Props) => {
  return (
    <a href={audio.file} target='_blank' rel='noreferrer' className='h-24 w-[90px]'>
      <img src={audioImg} alt='' className='object-contain w-full h-full' />
    </a>
  )
}

export default CaseAudioPreview
