import { useTranslation } from 'react-i18next'
import { ReportTypes } from '../../interfaces/report'
import CaseInfoMessage from '../UI/CaseInfoMessage'
import CaseAudioPreview from './CaseAudioPreview'
import CaseFilePreview from './CaseFilePreview'
import CaseImagesPreview from './CaseImagesPreview'
import CaseVideoPreview from './CaseVideoPreview'

interface Props {
  media: ReportTypes['media'] | undefined
}

const CaseMedia = ({ media = [] }: Props) => {
  const { t } = useTranslation()
  const getImages = media.filter(
    file => file.file.endsWith('.jpg') || file.file.endsWith('.jpeg') || file.file.endsWith('.png')
  )

  const getFiles = media.filter(
    file => file.file.endsWith('.pdf') || file.file.endsWith('.doc') || file.file.endsWith('.docx')
  )

  const getVideos = media.filter(
    file => file.file.endsWith('.mp4') || file.file.endsWith('.mov') || file.file.endsWith('.mpeg')
  )

  const getAudio = media.filter(file => file.file.endsWith('.wav'))

  const displayFiles = getFiles.map(file => <CaseFilePreview key={file.id} media={file} />)
  const displayVideos = getVideos.map(file => <CaseVideoPreview key={file.id} video={file} />)
  const displayAudio = getAudio.map(file => <CaseAudioPreview key={file.id} audio={file} />)

  return (
    <div className='flex flex-wrap gap-2 gap-y-4 overflow-y-auto max-h-[7rem]'>
      {media.length === 0 && <CaseInfoMessage text={t('noAttachmentsFound')} />}
      {getImages?.length !== 0 && <CaseImagesPreview images={getImages} />}
      {getFiles?.length !== 0 && displayFiles}
      {getVideos?.length !== 0 && displayVideos}
      {getAudio?.length !== 0 && displayAudio}
    </div>
  )
}

export default CaseMedia
