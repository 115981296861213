import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import LangSelector from '../../components/LangSelector/LangSelector'
import ContentSection from '../../components/UI/ContentSection'
import InputWrapper from '../../components/Formik/InputWrapper'
import PageTitle from '../../components/UI/PageTitle'
import UpdateOrgName from '../../components/Forms/UpdateOrgName'
import SettingsLabelText from '../../components/UI/SettingsLabelText'
import OrganizationCode from '../../components/UI/OrganizationCode'
import UpdatePolicy from '../../components/Forms/UpdatePolicy'
import UploadLogo from '../../components/Forms/UploadLogo'

const Settings = () => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const isSubPath = pathname.includes('change-password')
  const isAdmin = pathname.startsWith('/admin')

  useEffect(() => {
    document.title = `${t('settings')} - ReportAll`
  }, [t])

  const changePasswordLink = (
    <Link to='change-password' className='font-bold text-base mb-2 w-max'>
      {t('changePassword')}
    </Link>
  )

  const changeLangOption = (
    <>
      <SettingsLabelText text={t('language')} />
      <LangSelector type='admin' />
    </>
  )

  return (
    <ContentSection>
      {isSubPath ? (
        <Outlet />
      ) : (
        <>
          <PageTitle title={t('settings')} />
          <div className='mt-11 flex flex-col gap-11'>
            {isAdmin ? (
              <div className='bg-white flex flex-col gap-11 shadow-form max-w-[43.75rem] p-5 md:px-7 lg:p-12 rounded-20px'>
                <div className='flex flex-col gap-11 md:gap-[40px] md:flex-row'>
                  <InputWrapper>
                    <SettingsLabelText text={t('companyCodeLabel')} />
                    <OrganizationCode />
                  </InputWrapper>
                  <InputWrapper>{changeLangOption}</InputWrapper>
                </div>
                <div className='flex flex-col gap-11 md:gap-[40px] md:flex-row'>
                  <InputWrapper>
                    <SettingsLabelText text={t('updatePrivacyPolicy')} />
                    <UpdatePolicy />
                  </InputWrapper>
                  <InputWrapper>
                    <SettingsLabelText text={t('updateCompanyName')} />
                    <UpdateOrgName />
                  </InputWrapper>
                </div>

                <InputWrapper>
                  <SettingsLabelText text={t('companyLogo')} />
                  <UploadLogo />
                </InputWrapper>

                <InputWrapper>{changePasswordLink}</InputWrapper>
              </div>
            ) : (
              <>
                <InputWrapper>{changeLangOption}</InputWrapper>
                <InputWrapper>{changePasswordLink}</InputWrapper>
              </>
            )}
          </div>
        </>
      )}
    </ContentSection>
  )
}

export default Settings
