import ReactDOM from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { AuthClientTypes } from '../../context/auth/auth.interfaces'
import { useModalContext } from '../../context/modal/modal'
import LogoutBtn from '../Buttons/LogoutBtn'
import HamburgerNavLink from './HamburgerNavLink'

interface Props {
  role?: AuthClientTypes['user_type']
}

const HamburgerNav = ({ role }: Props) => {
  const { t } = useTranslation()
  const { isOpen, showUniqueCodeDialogHandler, isModalOpenHandler } = useModalContext()
  const { pathname } = useLocation()
  const reporterNav = pathname.startsWith('/reporter') || pathname.startsWith('/check-report')

  return ReactDOM.createPortal(
    <nav
      className={`fixed flex flex-col items-center py-16 px-4 bg-white inset-0 top-[72px] z-50 touch-none transition-transform ${
        isOpen ? 'translate-x-0' : 'translate-x-full'
      }`}
    >
      <ul className='flex flex-col justify-center h-[calc(100%-72px)] gap-14'>
        {role === 'COMPLIANCE_OFFICER' ? (
          <>
            <HamburgerNavLink path='dashboard' text={t('dashboard')} />
            <HamburgerNavLink path='reports' text={t('reports')} />
            <HamburgerNavLink path='statistics' text={t('statistics')} />
            <HamburgerNavLink path='settings' text={t('settings')} />
            <LogoutBtn type='mobile' />
          </>
        ) : role === 'ADMINISTRATOR' ? (
          <>
            <HamburgerNavLink path='users' text={t('users')} />
            <HamburgerNavLink path='categories' text={t('categories')} />
            <HamburgerNavLink path='branches' text={t('branches')} />
            <HamburgerNavLink path='settings' text={t('settings')} />
            <LogoutBtn type='mobile' />
          </>
        ) : role === 'HR' ? (
          <>
            <HamburgerNavLink path='statistics' text={t('statistics')} />
            <HamburgerNavLink path='settings' text={t('settings')} />
            <LogoutBtn type='mobile' />
          </>
        ) : reporterNav ? (
          <>
            <HamburgerNavLink path='/reporter' text={t('makeReport')} />
            <HamburgerNavLink
              type='button'
              onClickHandler={() => {
                isModalOpenHandler()
                showUniqueCodeDialogHandler()
              }}
              text={t('checkReport')}
            />
          </>
        ) : null}
      </ul>
    </nav>,
    document.getElementById('overlay') as HTMLElement
  )
}

export default HamburgerNav
