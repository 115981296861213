import useSWR from 'swr'
import { useTranslation } from 'react-i18next'
import { useAuthContext } from '../context/auth/auth'
import { useReportsContext } from '../context/reports/reports'
import { ReportTypes } from '../interfaces/report'
import { UserTypes } from '../interfaces/user'
import axios from '../services/api'
import moment from 'moment'

interface Props {
  url: string
  swrKey: string
  method?: 'GET' | 'POST'
}

const useAxios = ({ url, swrKey, method = 'GET' }: Props) => {
  const { t } = useTranslation()
  const { token } = useAuthContext()
  const { adminCompanyCode } = useReportsContext()

  const fetcher = async () => {
    if (method === 'POST') {
      // FETCH CATEGORIES
      if (url === '/report/category/organization-code/') {
        if (!adminCompanyCode) throw Error
        const response = await axios.post(
          url,
          {
            company_code: adminCompanyCode,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        return response.data.data
      }
    }

    if (method === 'GET') {
      // FETCH USERS
      if (url === '/auth/user/') {
        const response = await axios.get('/auth/user/', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const transformedUsers = response.data.data.map((user: UserTypes) => ({
          ...user,
          fullName: `${user.first_name} ${user.last_name}`,
          tableRole: t(user.user_type),
        }))
        return transformedUsers
      }

      // FETCH REPORTS
      if (url === '/report/report/') {
        const response = await axios.get('/report/report/', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const transformedReports = response.data.data
          .map((report: ReportTypes) => ({
            // Keep the report object as is
            ...report,

            // Add new properties to make them searchable for the react table.
            tableStatus: t(report.status),
            tableCreated: moment(report.date_filled).format('DD/MM/yyyy'),
            tableVisibility:
              report.user_visibility === 'CONFIDENTIAL' ? t('regular') : t(report.user_visibility),
            tableCategory: report.category.title,
          }))
          .sort((a: ReportTypes, b: ReportTypes) => b.date_filled.localeCompare(a.date_filled))
        return transformedReports
      }

      // FETCH EVERYTHING ELSE
      else {
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })

        return response.data.data ?? response.data
      }
    }
  }

  const { data, error } = useSWR(token ? swrKey : null, fetcher, {
    errorRetryInterval: swrKey === 'categories' ? 1000 : 5000,
  })
  const isLoading = !data && !error

  return {
    data,
    error,
    isLoading,
  }
}

export default useAxios
