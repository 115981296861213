import { useTranslation } from 'react-i18next'
import NavLink from './NavLink'
import { AuthClientTypes } from '../../context/auth/auth.interfaces'
import HamburgerNav from './HamburgerNav'
import { AiOutlineSetting } from 'react-icons/ai'
import { FiHome, FiBarChart, FiUser } from 'react-icons/fi'
import { CgFileDocument } from 'react-icons/cg'
import { BiCategory } from 'react-icons/bi'
import { IoIosGitBranch } from 'react-icons/io'

interface Props {
  role: AuthClientTypes['user_type']
}

const Navigation = ({ role }: Props) => {
  const { t } = useTranslation()

  const roleRoutes =
    role === 'COMPLIANCE_OFFICER' ? (
      <>
        <NavLink path='dashboard' text={t('dashboard')}>
          <FiHome className='text-2xl text-white' />
        </NavLink>
        <NavLink path='reports' text={t('reports')}>
          <CgFileDocument className='text-2xl text-white' />
        </NavLink>
        <NavLink path='statistics' text={t('statistics')}>
          <FiBarChart className='text-2xl text-white' />
        </NavLink>
        <NavLink path='settings' text={t('settings')}>
          <AiOutlineSetting className='text-2xl text-white' />
        </NavLink>
      </>
    ) : role === 'ADMINISTRATOR' ? (
      <>
        <NavLink path='users' text={t('users')}>
          <FiUser className='text-2xl text-white' />
        </NavLink>
        <NavLink path='categories' text={t('categories')}>
          <BiCategory className='text-2xl text-white' />
        </NavLink>
        <NavLink path='branches' text={t('branches')}>
          <IoIosGitBranch className='text-2xl text-white' />
        </NavLink>
        <NavLink path='settings' text={t('settings')}>
          <AiOutlineSetting className='text-2xl text-white' />
        </NavLink>
      </>
    ) : role === 'HR' ? (
      <>
        <NavLink path='statistics' text={t('statistics')}>
          <FiBarChart className='text-2xl text-white' />
        </NavLink>
        <NavLink path='settings' text={t('settings')}>
          <AiOutlineSetting className='text-2xl text-white' />
        </NavLink>
      </>
    ) : null

  return (
    <>
      <HamburgerNav role={role} />
      <nav className='hidden sm:block w-full min-h-full bg-navBlue items-center xl:px-7 py-32 max-w-[64px] min-w-[64px] xl:max-w-[330px] xl:min-w-[330px] xl:items-start'>
        <ul className='flex flex-col gap-7 w-full'>{roleRoutes}</ul>
      </nav>
    </>
  )
}

export default Navigation
