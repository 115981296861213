interface Props {
  text: string
}

const Label = ({ text }: Props) => {
  return (
    <label htmlFor={text} className='text-base font-bold mb-[10px] '>
      {text}
    </label>
  )
}

export default Label
