import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation } from 'react-router-dom'
import Table from '../../../components/Table/Table'
import ContentSection from '../../../components/UI/ContentSection'
import PageTitle from '../../../components/UI/PageTitle'
import { useReportsContext } from '../../../context/reports/reports'

const Reports = () => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const { reports } = useReportsContext()
  const isExactPath = pathname === '/compliance-officer/reports'

  useEffect(() => {
    document.title = `${t('reports')} - ReportAll`
  }, [t])

  return (
    <ContentSection>
      {isExactPath ? (
        <>
          <PageTitle title={t('reports')} />
          <Table reports={reports} />
        </>
      ) : (
        <Outlet context={reports} />
      )}
    </ContentSection>
  )
}

export default Reports
