import moment from 'moment'

interface Props {
  date?: string
  dateStyle: 'normal' | 'italicBlack' | 'italic'
  momentProp?: string
}

const CaseDate = ({ date, dateStyle, momentProp }: Props) => {
  const dateClasses =
    dateStyle === 'italic'
      ? 'text-[#4B90EB] text-sm italic mr-1'
      : dateStyle === 'italicBlack'
      ? 'text-textBlack text-sm italic mr-1'
      : 'text-base font-normal capitalize text-[#3D3D3D]'

  return (
    <span className={dateClasses}>
      {date ? moment(date).format('DD/MM/yyyy') : momentProp ? momentProp : ''}
    </span>
  )
}

export default CaseDate
