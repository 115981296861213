interface Props {
  as: 'h1' | 'h2'
  text: string
}

const FormStepTitle = ({ as, text }: Props) => {
  switch (as) {
    case 'h1':
      return <h1 className='text-2xl md:text-[1.75rem] font-bold my-3'>{text}</h1>
    case 'h2':
      return <h2 className='text-lg font-semibold mb-2'>{text}</h2>
  }
}

export default FormStepTitle
