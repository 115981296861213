import { useTranslation } from 'react-i18next'
import { ActivityLogTypes } from '../../interfaces/activityLog'
import { TiDocumentAdd } from 'react-icons/ti'
import { BiUserPlus } from 'react-icons/bi'
import { FiUser, FiPlay } from 'react-icons/fi'
import { RiFileEditLine } from 'react-icons/ri'
import moment from 'moment'

interface Props {
  activityLog: ActivityLogTypes
}

const ReportLog = ({ activityLog }: Props) => {
  const { t } = useTranslation()
  let icon
  const iconClasses = 'text-xl text-textBlack'
  switch (activityLog.action) {
    case 'REPORT_CREATED':
      icon = <TiDocumentAdd className={iconClasses} />
      break
    case 'COMPLIANCE_OFFICER_ASSIGNED':
      icon = <BiUserPlus className={iconClasses} />
      break
    case 'REPORT_STATUS_CHANGED':
      icon = <FiPlay className={iconClasses} />
      break
    case 'REPORT_NOTE_CREATED':
      icon = <FiUser className={iconClasses} />
      break
    case 'REPORT_EDITED':
      icon = <RiFileEditLine className={iconClasses} />
      break
  }

  return (
    <div className='border-solid border-l-[1px] border-borderGray relative pb-4 pt-1 last:border-0 ml-3 before:bg-white before:absolute before:-left-[5px] before:top-[33.5px] before:w-2 before:h-2'>
      <div className='absolute flex items-center justify-center rounded-[50%] border-[1px] border-solid border-[#707070] w-[33px] h-[33px] -left-[17px] top-0 bg-white'>
        {icon}
      </div>
      <div className='ml-10 flex flex-col gap-1'>
        <p className='capitalize text-textBlack text-base'>
          {t(activityLog.action)}
          {activityLog.content !== '' &&
          (activityLog.content === 'IN_PROGRESS' || activityLog.content === 'CLOSED')
            ? ` ${t(activityLog.content) === 'Κλειστές' ? 'Κλειστή' : t(activityLog.content)}`
            : ` ${activityLog.content?.replaceAll('_', ' ').toLowerCase()}`}
        </p>
        <p className='text-black text-xs'>
          {moment(activityLog.date_created).format('DD/MM/yyyy HH:mm')}
        </p>
      </div>
    </div>
  )
}

export default ReportLog
