interface Props {
  printVisibility?: 'print:block' | 'print:hidden'
  children: React.ReactNode
}

const CaseSection = ({ children, printVisibility = 'print:block' }: Props) => {
  return (
    <section
      className={`py-4 border-b-[1px] border-solid border-borderGray flex flex-col gap-7 last:border-0 ${printVisibility}`}
    >
      {children}
    </section>
  )
}

export default CaseSection
