import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import HCaptcha from '@hcaptcha/react-hcaptcha'
import axios from '../../services/api'
import { useStepContext } from '../../context/steps/StepsProvider'
import { useDataProviderContext } from '../../context/dataProvider/DataProvider'
import { useModalContext } from '../../context/modal/modal'
import { useTranslation } from 'react-i18next'
import { useAuthContext } from '../../context/auth/auth'
import StepButton from './StepButton'
import Spinner from '../UI/Spinner'
import Divider from '../UI/Divider'

interface Props {
  hasNotError?: boolean
}

const StepsButtons = ({ hasNotError }: Props) => {
  const { t } = useTranslation()
  const { setUniqueCode } = useAuthContext()
  const reportData = useDataProviderContext()
  const { step, setStep } = useStepContext()
  const { setShowSubmittedReportModal } = useModalContext()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const isLastStep = step === 4
  const isFirstStep = step === 1

  const nextStep = () => {
    if (step === 3 || hasNotError) {
      setStep(step + 1)
    }
  }

  const prevStep = () => {
    setStep(step - 1)
  }

  const postData = async () => {
    if (
      reportData.companyCode === null ||
      (process.env.NODE_ENV === 'production' && reportData.storeCaptchaToken === null)
    )
      return

    try {
      setIsLoading(true)
      const reportFormData = new FormData()
      reportFormData.append('company_code', reportData.companyCode)
      reportFormData.append('user_visibility', reportData.anonymity)
      reportFormData.append('category', reportData.category.toString())
      reportFormData.append('incident_date', reportData.incidentDate)
      reportFormData.append(
        'is_active',
        reportData.ongoing === 'yes' ? JSON.stringify(true) : JSON.stringify(false)
      )
      reportFormData.append('incident_location', reportData.incidentOccur)
      reportFormData.append('involved_name_capacity', reportData.involved)
      reportFormData.append('full_description', reportData.description)
      reportFormData.append('status', 'UNASSIGNED')

      if (reportData.anonymity === 'CONFIDENTIAL') {
        reportFormData.append('name', reportData.reporterName)
        reportData.reporterEmail && reportFormData.append('email', reportData?.reporterEmail)
        reportData.reporterPhone && reportFormData.append('phone_number', reportData.reporterPhone)
      }

      if (reportData.branch) {
        reportFormData.append('branch', reportData.branch.id)
      }

      if (process.env.NODE_ENV === 'production') {
        reportFormData.append('captcha_challenge', `${reportData.storeCaptchaToken}`)
      }

      if (reportData.media?.length !== 0)
        for (const file of reportData.media) reportFormData.append('media', file.file)

      const response = await axios.post('/report/report/', reportFormData)

      if (response.status === 201) {
        setShowSubmittedReportModal(true)
        setUniqueCode(response.data.data.token)
      }
    } catch (error) {
      toast.error('Failed to submit report.')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => () => setIsLoading(false), [])

  return (
    <>
      <Divider />
      <div className='flex justify-between gap-6 flex-wrap'>
        {!isFirstStep && (
          <StepButton as='back' type='button' text={`← ${t('back')}`} onClickHandler={prevStep} />
        )}

        {process.env.NODE_ENV === 'production' && !reportData.storeCaptchaToken && isLastStep ? (
          <form>
            <HCaptcha
              sitekey='98b8f186-e1b4-44f8-9447-294917da5d46'
              onVerify={token => reportData.setters.setStoreCaptchaToken(token)}
            />
          </form>
        ) : (
          <StepButton
            as='next'
            type='submit'
            text={
              isLastStep && isLoading ? (
                <Spinner spinner='form' />
              ) : isLastStep && !isLoading ? (
                t('submitReport')
              ) : (
                t('nextStep')
              )
            }
            onClickHandler={isLastStep ? postData : nextStep}
            hasNotError={step === 3 ? false : hasNotError}
          />
        )}
      </div>
    </>
  )
}

export default StepsButtons
