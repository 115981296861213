import { useNavigate } from 'react-router-dom'
import { BiEditAlt } from 'react-icons/bi'
import { RiDeleteBin6Line } from 'react-icons/ri'
import { UserTypes } from '../../interfaces/user'
import TableItems from './TableItems'
import { ReportTypes } from '../../interfaces/report'
import { useModalContext } from '../../context/modal/modal'
import { useTranslation } from 'react-i18next'
import { BsChevronRight } from 'react-icons/bs'
import { useReportsContext } from '../../context/reports/reports'

interface Props {
  users?: UserTypes[]
  reports?: ReportTypes[]
}

interface OriginalTypes {
  cell: { row: { original: { id: number; branch: { title: string } } } }
}

interface ValueType {
  value: string
}

const Table = ({ users = [], reports = [] }: Props) => {
  const { t } = useTranslation()
  const { showConfirmationDialogHandler } = useModalContext()
  const { selectedOrg } = useReportsContext()
  const navigate = useNavigate()
  const isUsers = users.length !== 0
  const isReports = reports.length !== 0

  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
    },
    {
      Header: isUsers ? t('fullName') : t('status'),
      accessor: isUsers ? 'fullName' : 'tableStatus',
      Cell: ({ value }: ValueType) =>
        isUsers ? (
          <span className='capitalize text-black'>{value}</span>
        ) : (
          <span
            className={`inline-block text-center capitalize text-sm font-medium rounded-lg py-1 w-[125px] ${
              value === t('IN_PROGRESS') && 'bg-inProgressBg text-inProgressText'
            } ${value === t('UNASSIGNED') && 'bg-unAssignedBg text-unAssignedText '} ${
              value === t('CLOSED') && 'bg-closedBg text-closedText '
            }`}
          >
            {value.replace('_', ' ').toLowerCase()}
          </span>
        ),
    },
    {
      Header: isUsers ? t('userName') : t('category'),
      accessor: isUsers ? 'username' : 'tableCategory',
      Cell: ({ value }: ValueType) =>
        isReports ? (
          <p title={value} className='truncate max-w-[200px]'>
            {value}
          </p>
        ) : (
          <p>{value}</p>
        ),
    },
    {
      Header: isUsers ? 'E-mail' : t('created'),
      accessor: isUsers ? 'email' : 'tableCreated',
    },
    {
      Header: isUsers ? t('position') : t('visibility'),
      accessor: isUsers ? 'tableRole' : 'tableVisibility',
      Cell: ({ value }: ValueType) => <span className='capitalize'>{t(value)}</span>,
    },
    {
      Header: isUsers ? 'Actions' : 'Branch',
      Cell: ({
        cell: {
          row: { original },
        },
      }: OriginalTypes) =>
        isUsers ? (
          <div className='flex justify-end gap-5'>
            <BiEditAlt
              className='text-primaryBlue text-lg cursor-pointer hover:opacity-80 transition-opacity'
              onClick={() => navigate(`/admin/users/edit-user/${original?.id}`)}
            />
            <RiDeleteBin6Line
              className='text-deleteRed text-lg cursor-pointer hover:opacity-80 transition-opacity'
              onClick={() => showConfirmationDialogHandler(original?.id!)}
            />
          </div>
        ) : selectedOrg?.branch && isReports ? (
          <div className='flex items-center h-full relative'>
            <p title={original?.branch?.title} className='truncate max-w-[14.375rem]'>
              {original?.branch?.title}
            </p>
            <BsChevronRight className='absolute right-5 text-lg group-hover:opacity-100 opacity-0 transition-all' />
          </div>
        ) : null,
    },
  ]

  return (
    <div className='w-full h-[710px] px-5 py-5 md:px-10 md:py-6 lg:px-20 lg:py-11 bg-white rounded-20px shadow-form mt-7'>
      <TableItems columns={columns} data={isUsers ? users : reports} />
    </div>
  )
}

export default Table
