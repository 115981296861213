interface Props {
  text: string;
  stepNumber: string;
}

const ActiveStep = ({ stepNumber, text }: Props) => {
  return (
    <div className='w-[8.125rem] mx-auto md:w-max md:m-0 flex items-center   h-10 md:px-2 md:py-2 -mb-px text-center sm:px-4 -px-1  whitespace-nowrap focus:outline-none'>
      <span className='px-4 py-2 mr-1 md:m-2 rounded-full font-semibold bg-[#0c75ff] text-white'>
        {stepNumber}
      </span>
      <span className='mx-1 text-sm sm:text-base font-semibold'>{text}</span>
    </div>
  );
};

export default ActiveStep;
