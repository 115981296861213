import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAsyncDebounce } from 'react-table'
import { FiSearch } from 'react-icons/fi'
import Spinner from '../UI/Spinner'

interface Props {
  filter: string
  setFilter: (value: string) => void
}

const Search = ({ filter, setFilter }: Props) => {
  const { t } = useTranslation()
  const [value, setValue] = useState<string>(filter)
  const [isSearching, setIsSearching] = useState<boolean>(false)

  const handleChange = useAsyncDebounce(value => {
    setIsSearching(false)
    return setFilter(value || undefined)
  }, 500)

  return (
    <div className='absolute top-4 right-4 md:right-9 flex items-center border-[1px] gap-4 rounded-lg bg-white border-borderGray border-solid px-4 w-full h-[3rem] max-w-[170px] md:max-w-[15.9375rem]'>
      <FiSearch className='transform scale-[1.5] md:scale-125 text-[#3E3E3E]' />
      <input
        className='w-full border-0 px-0 md:px-2 bg-transparent focus:outline-none'
        type='text'
        placeholder={t('search')}
        value={value || ''}
        onChange={event => {
          setIsSearching(true)
          setValue(event.target.value)
          handleChange(event.target.value)
        }}
      />
      {isSearching ? <Spinner spinner='form' width='w-4' height='h-4' color='#0C75FF' /> : <span />}
    </div>
  )
}

export default Search
