import { useFormik } from 'formik'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import { useStepContext } from '../../context/steps/StepsProvider'
import { useDataProviderContext } from '../../context/dataProvider/DataProvider'
import StepsButtons from './StepsButtons'
import FormikErrorText from '../Formik/FormikErrorText'
import AnonymityLevel from './AnonymityLevel'
import FormStepTitle from '../UI/FormStepTitle'

const Step1 = () => {
  const { t } = useTranslation()
  const { step, setStep } = useStepContext()
  const reportData = useDataProviderContext()

  const isAnonymous = reportData.anonymity === 'ANONYMOUS'
  const isConfidential = reportData.anonymity === 'CONFIDENTIAL'

  const validationSchema = yup.object({
    category: yup.number().required(t('requiredField')).positive(t('requiredField')),
    reporterName: isConfidential
      ? yup.string().trim().required(t('requiredField'))
      : yup.string().trim(),
    reporterEmail: isConfidential
      ? yup.string().trim().email(t('invalidEmail'))
      : yup.string().trim(),
    reporterPhone: isConfidential
      ? yup
          .string()
          .trim()
          .matches(
            /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
            t('invalidPhone')
          )
      : yup.string().trim(),
  })
  const handleSubmit = (values: {
    category: number
    reporterName: string
    reporterEmail: string
    reporterPhone: string
  }) => {
    reportData.setters.setCategory(+values.category)
    if (isConfidential) {
      reportData.setters.setReporterName(values.reporterName.trim())
      reportData.setters.setReporterEmail(values.reporterEmail.trim())
      reportData.setters.setReporterPhone(values.reporterPhone.trim())
    } else {
      reportData.setters.setReporterName('')
      reportData.setters.setReporterEmail('')
      reportData.setters.setReporterPhone('')
    }
    setStep(step + 1)
  }

  const formik = useFormik({
    initialValues: {
      category: reportData.category,
      reporterName: reportData.reporterName,
      reporterEmail: reportData.reporterEmail,
      reporterPhone: reportData.reporterPhone,
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className='lg:container'>
        <div className='items-center mx-auto  mb-11'>
          <FormStepTitle as='h1' text={t('reportAnOccurrence')} />
          <p className='text-base text-[#6F6F6F] my-3 leading-[1.5625rem]'>
            {t('reportAnOccurrenceText')}
          </p>
        </div>

        <div className='lg:mx-auto'>
          <FormStepTitle as='h2' text={t('typeOfReport')} />
          <div className='grid grid-cols-1 md:grid-cols-2 xl:max-w-6xl max-w-4xl mb-11'>
            <AnonymityLevel
              title={t('anonymousReporting')}
              text={t('anonymousText')}
              anonymityLevel={isAnonymous}
              onClickHandler={() => reportData.setters.setAnonymity('ANONYMOUS')}
            />
            <AnonymityLevel
              title={t('regularReporting')}
              text={t('regularText')}
              anonymityLevel={isConfidential}
              onClickHandler={() => reportData.setters.setAnonymity('CONFIDENTIAL')}
            />
          </div>

          <div className='my-9'>
            <div>
              <FormStepTitle as='h2' text={t('chooseIncidentcategory')} />
              <select
                className={
                  'block w-full sm:max-w-sm  h-12 px-4 p-0 mt-2 text-gray-700 focus:outline-none bg-white border-[1px] border-borderGray rounded-lg hover:border-primaryBlue transition-all ' +
                  (formik.errors.category ? 'border-[1px] border-red-500' : '')
                }
                id='category'
                {...formik.getFieldProps('category')}
              >
                <option value={-1}>{t('choose')}</option>
                {reportData.categories &&
                  reportData.categories.map(category => (
                    <option key={category.id} value={category.id}>
                      {category.title}
                    </option>
                  ))}
              </select>
              {formik.errors.category && formik.touched.category && (
                <FormikErrorText errorText={formik.errors.category} />
              )}
            </div>
          </div>

          {isConfidential && (
            <>
              <div>
                <FormStepTitle as='h2' text={t('fullName')} />
                <input
                  className={
                    'placeholder:text-lightGray block w-full sm:max-w-sm h-12 px-4 p-0 mt-2 text-gray-700 bg-white border-[1px] border-borderGray rounded-lg hover:border-primaryBlue transition-all ' +
                    (formik.errors.reporterName && formik.touched.reporterName
                      ? 'border-[1px] border-red-500'
                      : '')
                  }
                  placeholder={t('writeYourFullName')}
                  {...formik.getFieldProps('reporterName')}
                />
                {formik.errors.reporterName && formik.touched.reporterName && (
                  <FormikErrorText errorText={formik.errors.reporterName} />
                )}
              </div>
              <div className='mt-9  flex-col md:flex-row flex sm:justify-between '>
                <div className='mb-9'>
                  <FormStepTitle as='h2' text={`Email (${t('optional')})`} />
                  <input
                    className={
                      'placeholder:text-lightGray block w-full sm:w-[384px] h-12 px-4 p-0 mt-2 text-gray-700 bg-white border-[1px] border-borderGray rounded-lg hover:border-primaryBlue transition-all ' +
                      (formik.errors.reporterEmail && formik.touched.reporterEmail
                        ? 'border-[1px] border-red-500'
                        : '')
                    }
                    placeholder={t('writeYourEmail')}
                    type='email'
                    {...formik.getFieldProps('reporterEmail')}
                  />
                  {formik.errors.reporterEmail && formik.touched.reporterEmail && (
                    <FormikErrorText errorText={formik.errors.reporterEmail} />
                  )}
                </div>

                <div>
                  <FormStepTitle as='h2' text={`${t('telephone')} (${t('optional')})`} />
                  <input
                    className={
                      'placeholder:text-lightGray block w-full sm:w-[384px] h-12 px-4 p-0 mt-2 text-gray-700 bg-white border-[1px] border-borderGray rounded-lg hover:border-primaryBlue transition-all ' +
                      (formik.errors.reporterPhone && formik.touched.reporterPhone
                        ? 'border-[1px] border-red-500'
                        : '')
                    }
                    type='tel'
                    placeholder={t('writeYourTelephone')}
                    {...formik.getFieldProps('reporterPhone')}
                  />
                  {formik.errors.reporterPhone && formik.touched.reporterPhone && (
                    <FormikErrorText errorText={formik.errors.reporterPhone} />
                  )}
                </div>
              </div>
            </>
          )}
        </div>

        <StepsButtons hasNotError={formik.errors.category ? true : false} />
      </div>
    </form>
  )
}

export default Step1
