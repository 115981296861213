import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useSWR from 'swr'
import { toast } from 'react-toastify'
import axios from '../../services/api'
import { ReportMessageTypes } from '../../interfaces/reportMessage'
import ReportTitle from '../UI/ReportTitle'
import CaseMessage from './CaseMessage'
import AddMessage from './AddMessage'
import { ReportTypes } from '../../interfaces/report'
import CaseInfoMessage from '../UI/CaseInfoMessage'
import { AxiosRequestHeaders } from 'axios'
import { useAuthContext } from '../../context/auth/auth'

interface Props {
  report: ReportTypes
  isTheAssignedCo: boolean
  isComplianceOfficer: boolean
  isTheReporter: boolean
}

const CaseMessages = ({ report, isTheAssignedCo, isComplianceOfficer, isTheReporter }: Props) => {
  const { t, i18n } = useTranslation()
  const { token } = useAuthContext()
  const [selectedReportComments, setSelectedReportComments] = useState<ReportMessageTypes[]>([])
  const isEl = i18n.language === 'el'

  const fetchReportMessages = async () => {
    const api = isTheReporter
      ? '/report/report-comment/retrieve-report-comments/'
      : '/report/report-comment/'

    const headers: AxiosRequestHeaders = isTheReporter
      ? {
          Token: JSON.parse(sessionStorage.getItem('reportToken')!),
        }
      : {
          Authorization: `Bearer ${token}`,
        }

    try {
      const response = await axios.get(api, {
        headers: headers,
      })

      return response.data.data
    } catch (error) {
      toast.error(t('failedToLoadMessages'))
    }
  }

  const { data: reportMessages } = useSWR('reportMessages', fetchReportMessages, {
    refreshInterval: 1000,
  })

  useEffect(() => {
    // GET THE MESSAGES FOR THE SELECTED REPORT ID
    if (reportMessages) {
      const filteredComments = reportMessages
        .filter((comment: ReportMessageTypes) => comment.report === report?.id)
        .sort((a: ReportMessageTypes, b: ReportMessageTypes) =>
          a.date_created.localeCompare(b.date_created)
        )
      setSelectedReportComments(filteredComments)
    }
  }, [report?.id, reportMessages])

  const displayComments =
    selectedReportComments &&
    selectedReportComments.map((message: ReportMessageTypes) => (
      <CaseMessage
        key={message.id}
        message={message}
        isTheReporter={isTheReporter}
        isComplianceOfficer={isComplianceOfficer}
      />
    ))

  return (
    <div
      className={`rounded-20px bg-white  px-3 md:px-8 ${
        isComplianceOfficer
          ? 'mt-8 h-[750px] shadow-form lg:w-[40%] lg:sticky lg:top-0 py-8'
          : `mt-0 lg:min-h-full shadow-none lg:w-[45%] min-h-[750px] py-8 ${
              isEl ? 'pb-11' : 'pb-8 '
            }`
      } print:hidden`}
    >
      <ReportTitle title={t('yourMessages')} />
      {isTheReporter ? (
        <p className='text-textBlack text-sm mt-4'>{t('yourMessagesInfo')}</p>
      ) : null}
      {!isTheReporter && !isTheAssignedCo ? (
        <CaseInfoMessage
          align='center'
          isComplianceOfficer={isComplianceOfficer}
          text={t('onlyAssignedCoOfficerCanViewMessages')}
        />
      ) : (
        <>
          <div
            className={`overflow-y-auto no-scrollbar mt-8 flex flex-col-reverse gap-10 ${
              isComplianceOfficer
                ? 'h-[calc(100%-142px)]'
                : 'h-[calc(750px-142px)] lg:h-[calc(100%-198px)]'
            }`}
          >
            {displayComments?.reverse()}
          </div>
          {report?.status !== 'CLOSED' && (
            <AddMessage reportId={report?.id} isTheReporter={isTheReporter} />
          )}
        </>
      )}
    </div>
  )
}

export default CaseMessages
