import { AuthClientTypes } from '../context/auth/auth.interfaces'

export const navigateTo = ({ user_type }: AuthClientTypes) => {
  switch (user_type) {
    case 'ADMINISTRATOR':
      return '/admin'
    case 'COMPLIANCE_OFFICER':
      return '/compliance-officer'
    case 'HR':
      return '/hr'
  }
}
