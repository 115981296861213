export const translationEl = {
  // Company Code Form
  welcome: 'Καλωσορίσατε',
  companyCodeInfo:
    'Εισάγετε τον κωδικό πρόσβασης της εταιρείας σας για να δημιουργήσετε μια νέα αναφορά ή ελέγξτε την κατάσταση μιας αναφοράς που έχει ήδη υποβληθεί.',
  companyCodeLabel: 'Κωδικός πρόσβασης',
  companyCodeInputPlaceholder: 'Εισάγετε τον κωδικό πρόσβασης για μια νέα αναφορά',
  statusOfExistingReport: 'Κατάσταση υπάρχουσας αναφοράς',
  createNewReport: 'Δημιουργία νέας αναφοράς',
  companyCode: 'Κωδικός εταιρείας',

  // Access Report Modal
  accessReportModalTitle: 'Για την πρόσβαση απαιτείται μοναδικός κωδικός',
  accessReportModalText:
    'Τη στιγμή που υποβάλατε την αναφορά σας δόθηκε ένας μοναδικός κωδικός. Επικολλήστε τον κωδικό στο πεδίο εισαγωγής παρακάτω.',
  accessReportModalLabel: 'Μοναδικός Κωδικός',
  accessReportModalPlaceholder: 'Εισάγετε τον κωδικό πρόσβασης',
  accessReportModalCancelBtn: 'Άκυρο',
  accessReportModalChecklBtn: 'Έλεγχος αναφοράς',

  // Save Token Modal
  saveTokenTitle: 'Η αναφορά υποβλήθηκε. Αποθηκεύστε τον παρακάτω κωδικό:',
  saveTokenImportant: 'ΣΗΜΑΝΤΙΚΟ:',
  saveTokenImportantNote:
    'Αποθηκεύστε τον παρακάτω κωδικό με ασφάλεια. Μην κοινοποιείτε τον κωδικό σε κανέναν. Με αυτόν τον κωδικό θα έχετε πρόσβαση στην αναφορά σας για να παρακολουθείτε την πρόοδό της και να βλέπετε τα μηνύματα.',
  saveTokenUniqueCodeLabel: 'Ο Μοναδικός σας Κωδικός',
  saveTokenReceivedReport: 'Λάβαμε την αναφορά σας και θα επικοινωνήσουμε μαζί σας το συντομότερο δυνατό.',
  saveTokenReportInfo:
    'Μπορείτε να κάνετε λήψη/εξαγωγή της αναφοράς που υποβάλατε ή να κάνετε κλικ στο "Ολοκλήρωση" για να προχωρήσετε.',
  saveTokenExportBtn: 'Εξαγωγή Αναφοράς',
  saveTokenCompleteBtn: 'Ολοκλήρωση',

  // Delete Resource Modal
  areYouSureWantToDelete: 'Είστε σίγουροι ότι θέλετε να διαγράψετε αυτόν τον χρήστη;',
  areYouSureWantToDeleteThisCategory: 'Είστε σίγουροι ότι θέλετε να διαγράψετε αυτήν την κατηγορία;',
  areYouSureWantToDeleteThisBranch: 'Είστε σίγουροι ότι θέλετε να διαγράψετε αυτο το Branch;',
  yesIamSure: 'Ναι, είμαι σίγουρος',
  noCancel: 'Όχι, ακύρωση',

  // Resolution Option Modal
  areYouSureWantToCloseThisCase: 'Είστε σίγουροι ότι θέλετε να κλείσετε αυτήν την υπόθεση;',
  youAreAboutToCloseThisCase: 'Πρόκειται να κλείσετε την υπόθεση',
  theCaseWillBe: 'Η υπόθεση θα',
  howWouldYouLikeToResolveThisCase: 'Πώς θα θέλατε να κλείσετε αυτήν την υπόθεση;',
  yesCloseTheCase: 'Ναι, κλείστε την υπόθεση',
  noIamNotReady: 'Όχι, δεν είμαι έτοιμος',
  archived: 'αρχειοθετηθεί',
  resolved: 'επιλυθεί',

  // Report Form
  reportAnOccurrenceText:
    'Χρησιμοποιήστε αυτήν τη φόρμα για να αναφέρετε περιστατικά παραπτωμάτων, απάτης ή παραβιάσεις συμμόρφωσης που αντιβαίνουν στις πολιτικές και τις διαδικασίες του οργανισμού. Η φόρμα ReportAll ζητά σχετικές πληροφορίες για να καθοριστεί εάν και πώς θα διεξαχθεί μια έρευνα. Η εμπιστευτικότητα προστατεύεται και οι υπεύθυνοι συμμόρφωσης είναι υποχρεωμένοι να προστατεύουν κάθε είδους προσωπικές πληροφορίες.',
  makeReport: 'Δημιουργία Αναφοράς',
  checkReport: 'Έλεγχος Αναφοράς',
  review: 'Ανασκόπηση',
  startReport: 'Έναρξη Αναφοράς',
  typeOfReport: 'Τύπος Αναφοράς',
  category: 'Κατηγορία',
  branch: 'Branch',
  fullName: 'Ονοματεπώνυμο',
  telephone: 'Τηλέφωνο',
  questions: 'Ερωτήσεις',
  whenDidTheIncidentOccur: 'Πότε συνέβη το περιστατικό;',
  isTheIncidentStillOngoing: 'Το περιστατικό συνεχίζεται ακόμα;',
  whereDidTheIncidentOccur: 'Που συνέβη το περιστατικό;',
  whoIsInvolvedAndWhatIsTheirCapacity: 'Ποιοι εμπλέκονται και ποια είναι η ιδιότητά τους;',
  nameSurnameAndTheirCapacity: 'Όνομα, Επώνυμο και η ιδιότητά τους',
  fullDescriptionOfTheIncident: 'Πλήρης περιγραφή του συμβάντος',
  regular: 'Απλή',
  anonymous: 'Ανώνυμη',
  attachments: 'Συνημμένα',
  uploadFilesTitle: 'Μπορείτε να ανεβάσετε οποιοδήποτε αρχείο σχετίζεται με την αναφορά σας',
  dragHere: 'Σύρετε εδώ για να μεταφορτώσετε αρχεία',
  supportedFiles: 'Αρχεία που υποστηρίζονται:',
  addFile: 'Προσθήκη Αρχείου',
  reportAnOccurrence: 'Αναφέρετε ένα περιστατικό',
  anonymousReporting: 'Ανώνυμη αναφορά',
  regularReporting: 'Απλή αναφορά',
  chooseIncidentcategory: 'Επιλέξτε κατηγορία περιστατικού',
  choose: 'Επιλέξτε',
  anonymousText:
    'Αυτή η Αναφορά είναι ανώνυμη, καθώς δεν απαιτεί προσωπικά δεδομένα ή/και στοιχεία επικοινωνίας από τον καταγγέλλοντα. Λάβετε υπόψη ότι σε αυτήν την περίπτωση, η παρακολούθηση της Αναφοράς σας επιτρέπεται μόνο μέσω του κωδικού πρόσβασης που σας παρέχεται κατά την υποβολή της Αναφοράς.',
  regularText:
    'Αυτή η Αναφορά ενδέχεται να ζητήσει τα προσωπικά σας δεδομένα ή/και τα στοιχεία επικοινωνίας πριν από την υποβολή. Σημειώστε ότι, εκτός από το όνομά σας, τέτοιες πληροφορίες δεν είναι υποχρεωτικές. Σε κάθε περίπτωση η αναφορά σας θα αντιμετωπίζεται με απόλυτη εμπιστευτικότητα.',
  optional: 'προαιρετικό',
  back: 'Πίσω',
  nextStep: 'Επόμενο βήμα',
  submitReport: 'Υποβολή',
  noAttachmentsFound: 'Δεν βρέθηκαν συνημμένα',
  yes: 'Ναι',
  no: 'Όχι',
  writeYourFullName: 'Γράψτε το όνομα σας',
  writeYourEmail: 'Γράψτε το email σας',
  writeYourTelephone: 'Γράψτε το τηλέφωνο σας',
  writeThePlaceOfTheIncident: 'Γράψτε τον τόπο του συμβάντος',
  addTheNamesOfThePeopleInvolved: 'Προσθέστε τα ονόματα των ατόμων που εμπλέκονται',
  describeIncident:
    'Περιγράψτε το περιστατικό (Τι συνέβη; Πώς συνέβη; κ.λπ.) Να είστε όσο το δυνατόν πιο συγκεκριμένοι.',
  report: 'Αναφορά',
  export: 'Εξαγωγή',
  caseDetails: 'Λεπτομέρειες Υπόθεσης',
  yourMessages: 'Τα μηνύματά σας',
  yourMessagesInfo:
    'Εδώ μπορείτε να επικοινωνήσετε με τον υπεύθυνο συμμόρφωσης που αναλαμβάνει την υπόθεσή σας, για να προσθέσετε περισσότερες πληροφορίες σχετικά με την αναφορά σας.',
  textHere: 'Γράψτε εδώ',
  freeTextSmallField: 'Μικρό Κέιμενο',
  freeTextLargeField: 'Μεγάλο Κέιμενο',
  details: 'Λεπτομέρειες',
  assignTo: 'Ανάθεση σε',
  caseOverview: 'Επισκόπηση Υπόθεσης',
  activityLog: 'Καταγραφή Δραστηριότητας',
  submit: 'Υποβολή',
  search: 'Αναζήτηση',
  selectBranch: 'Επιλέξτε Branch',

  // Status Cases
  UNASSIGNED: 'Χωρίς ανάθεση',
  IN_PROGRESS: 'Σε εξέλιξη',
  CLOSED: 'Κλειστές',
  TOTAL: 'Σύνολο',

  // Navigation
  dashboard: 'Αρχική',
  reports: 'Αναφορές',
  users: 'Χρήστες',
  settings: 'Ρυθμίσεις',
  statistics: 'Στατιστικά',
  categories: 'Κατηγορίες',
  branches: 'Branches',

  // Admin
  newUser: 'Νέος χρήστης',
  editUser: 'Επεξεργασία χρήστη',
  saveNewUser: 'Αποθήκευση νέου χρήστη',
  userName: 'Όνομα χρήστη',
  firstName: 'Όνομα',
  lastName: 'Επώνυμο',
  password: 'Κωδικός',
  position: 'Θέση',
  language: 'Γλώσσα',
  newCategory: 'Νέα κατηγορία',
  newBranch: 'Νέο branch',
  editCategory: 'Επεξεργασία κατηγορίας',
  editBranch: 'Επεξεργασία branch',
  insertCategory: 'Εισάγετε κατηγορία',
  insertName: 'Εισάγετε όνομα',
  saveCategory: 'Αποθήκευση κατηγορίας',
  noCategoriesFound: 'Δεν βρέθηκαν κατηγορίες',
  noDataFound: 'Δεν βρέθηκαν δεδομένα',
  changePassword: 'Αλλαγή κωδικού',
  updateCompanyName: 'Ενημέρωση ονόματος',
  updatePrivacyPolicy: 'Ενημέρωση πολιτικής',
  enterLink: 'Εισάγετε σύνδεσμο',
  updateLogo: 'Ενημέρωση λογότυπου',
  companyLogo: 'Λογότυπο εταιρείας',

  // Compliance Officer
  complianceOfficer: 'Υπεύθυνος Συμμόρφωσης',
  visibility: 'Ορατότητα',
  created: 'Δημιουργήθηκε',
  status: 'Κατάσταση',
  overviewStatistics: 'Επισκόπηση Στατιστικών',
  complianceOfficerNotes: 'Σημειώσεις Υπευθύνου Συμμόρφωσης',
  complianceOffcerNoteCreatedBy: 'Σημείωση Υπεύθυνου Συμμόρφωσης δημιουργήθηκε από:',
  youHaventAddedNotes: 'Δεν έχετε προσθέσει ακόμη σημειώσεις σε αυτήν την υπόθεση.',
  onlyAssignedCoOfficerCanViewMessages: 'Μόνο ο αρμόδιος Υπεύθυνος Συμμόρφωσης μπορεί να δει τα μηνύματα.',
  characterLimit: 'Όριο χαρακτήρων',
  noteLimit: 'Το μήνυμα πρέπει να έχει το πολύ 320 χαρακτήρες',
  closeCase: 'Κλείσιμο υπόθεσης',
  resolutionOption: 'Επιλογή Απόφασης',
  archivedClosed: 'Αρχείο (Κλειστή)',
  resolvedClosed: 'Απόφαση (Κλειστή)',
  caseClosed: 'Υπόθεση Κλειστή',
  REPORT_CREATED: 'Αναφορά Δημιουργήθηκε',
  COMPLIANCE_OFFICER_ASSIGNED: 'Υπεύθυνος Συμμόρφωσης Ανατέθηκε:',
  REPORT_STATUS_CHANGED: 'Κατάσταση Αναφοράς Αλλάχθηκε:',
  REPORT_NOTE_CREATED: 'Σημείωση Αναφοράς δημιουργήθηκε',
  REPORT_EDITED: 'Αναφορά Επεξεργάστηκε',

  // Table
  showing: 'Εμφάνιση',
  to: 'έως',
  entries: 'καταχωρήσεις',
  of: 'από',
  pages: 'σελίδες',
  ANONYMOUS: 'Ανώνυμη',
  noReportsFound: 'Δεν βρέθηκαν αναφορές',
  noUsersFound: 'Δεν βρέθηκαν χρήστες',

  // Charts
  mostReportedCategories: 'Οι πιο αναφερόμενες κατηγορίες',
  conclusionType: 'Τύπος συμπερασμάτων',
  numberNatureOfReports: 'Αριθμός / Φύση Αναφορών',
  total: 'Όλες οι αναφορές',
  other: 'Υπόλοιπες',
  noDataFoundForTheSelectedPeriod: 'Δεν βρέθηκαν δεδομένα για την επιλεγμένη περίοδο',

  // Toast messages
  copiedToClipboard: 'Αντιγράφηκε στο πρόχειρο',
  aUserWithThisEmailAlreadyExists: 'Υπάρχει ήδη ένας χρήστης με αυτό το email',
  aUserWithThisUsernameAlreadyExists: 'Υπάρχει ήδη ένας χρήστης με αυτό το όνομα χρήστη',
  failedToCreateUser: 'Αποτυχία δημιουργίας χρήστη',
  userHasBeenCreated: 'Ο χρήστης δημιουργήθηκε',
  userHasBeenUpdated: 'Ο χρήστης ενημερώθηκε',
  failedToUpdateUser: 'Αποτυχία ενημέρωσης χρήστη',
  userHasBeenDeleted: 'Ο χρήστης διαγράφηκε',
  failedToDeleteUser: 'Αποτυχία διαγραφής χρήστη',
  failedToSubmitNote: 'Αποτυχία υποβολής σημείωσης',
  failedToLoadReports: 'Αποτυχία φόρτωσης αναφορών',
  failedToCreateCategory: 'Αποτυχία δημιουργίας κατηγορίας',
  categoryHasBeenCreated: 'Η κατηγορία δημιουργήθηκε',
  categoryHasBeenDeleted: 'Η κατηγορία διαγράφηκε',
  failedToDeleteCategory: 'Αποτυχία διαγραφής κατηγορίας',
  categoryHasBeenUpdated: 'Η κατηγορία ενημερώθηκε',
  branchHasBeenUpdated: 'Tο branch ενημερώθηκε',
  failedToUpdateBranch: 'Αποτυχία ενημέρωσης branch',
  branchHasBeenDeleted: 'Το branch διαγράφηκε',
  failedToDeleteBranch: 'Αποτυχία διαγραφής branch',
  failedToUpdateCategory: 'Αποτυχία ενημέρωσης κατηγορίας',
  branchHasBeenCreated: 'Tο branch δημιουργήθηκε',
  failedToCreateBranch: 'Αποτυχία δημιουργίας branch',
  caseHasBeenClosed: 'Η υπόθεση έκλεισε',
  failedToCloseCase: 'Αποτυχία κλεισίματος υπόθεσης',
  yourPasswordHasBeenResetsussfully: 'Ο κωδικός σας επαναφέρθηκε επιτυχώς',
  submitFailed: 'Αποτυχία υποβολής',
  aResetLinkHasBeenSentToYourEmail: 'Ένας σύνδεσμος επαναφοράς έχει σταλεί στο email σας.',
  InvalidUsernameOrPassword: 'Λανθασμένο όνομα χρήστη ή κωδικός',
  failedToResetPassword: 'Αποτυχία επαναφοράς κωδικού',
  yourPasswordHasBeenChanged: 'Ο κωδικός σας άλλαξε επιτυχώς',
  failedToChangePassword: 'Αποτυχία αλλαγής κωδικού',
  companyNameHasBeenUpdated: 'Το όνομα εταιρείας άλλαξε επιτυχώς',
  failedToUpdateCompanyName: 'Αποτυχία αλλαγής όνομα εταιρείας',
  sessionExpired: 'Η συνεδρία σας έληξε. Παρακαλώ συνδεθείτε ξανά.',
  failedToSendMessage: 'Αποτυχία αποστολής μηνύματος',
  failedToLoadMessages: 'Αποτυχία φόρτωσης μηνυμάτων',
  privacyPolicyHasBeenUpdated: 'Η πολιτική απορρήτου άλλαξε επιτυχώς',
  failedToUpdatePrivacyPolicy: 'Αποτυχία αλλαγής πολιτικής απορρήτου',
  logoHasBeenUpdated: 'Το λογότυπο άλλαξε επιτυχώς',
  failedToUpdateLogo: 'Αποτυχία αλλαγής λογότυπου',
  caseAssigned: 'Η υπόθεση ανατέθηκε με επιτυχία',

  // Error messages
  somethingWentWrongFailedToLoadNotes: 'Κάτι πήγε στραβά κατά την φόρτωση των σημειώσεων',

  //Validation messages
  requiredField: 'Υποχρεωτικό πεδίο',
  invalidEmail: 'Μη έγκυρο email',
  invalidPhone: 'Μη έγκυρο τηλέφωνο',

  logout: 'Αποσύνδεση',
  logIn: 'Σύνδεση',
  enterCredentials: 'Εισαγάγετε τα διαπιστευτήριά σας για πρόσβαση στον λογαριασμό σας.',
  resetPassword: 'Επαναφορά κωδικού',
  forgotPassword: 'Ξεχάσατε τον κωδικό σας;',
  insertUsername: 'Εισάγετε όνομα χρήστη',
  insertPassword: 'Εισάγετε κωδικό',
  insertEmail: 'Εισάγετε email',
  enterYourEmailToResetYourPassword: 'Εισάγετε το email σας για να επαναφέρετε τον κωδικό σας.',
  enterNewPassword: 'Εισάγετε νέο κωδικό πρόσβασης',
  confirmPassword: 'Επιβεβαίωση κωδικού',
  passwordMustBeAtLeast8CharactersAndContainAtLeastOneUppercaseLetterAndOneNumber:
    'Ο κωδικός πρέπει να είναι τουλάχιστον 8 χαρακτήρες και να περιέχει τουλάχιστον ένα κεφαλαίο γράμμα έναν αριθμό και έναν ειδικό χαρακτήρα.',
  passwordsMustMatch: 'Οι κωδικοί πρέπει να ταιριάζουν',

  'Select Position': 'Επιλέξτε θέση',
  'Compliance Officer': 'Υπεύθυνος Συμμόρφωσης',
  Administrator: 'Διαχειριστής',
  HR: 'HR',
  COMPLIANCE_OFFICER: 'Υπεύθυνος Συμμόρφωσης',
  ADMINISTRATOR: 'Διαχειριστής',

  chooseResolutionOption: 'Επιλέξτε Απόφαση',
  'Case Dismissed': 'Η υπόθεση απορρίφθηκε',
  Lawsuit: 'Αγωγή',
  'Report to Other Authorities': 'Αναφορά σε άλλες Αρχές',
  'Resolved Internally': 'Επιλύθηκε εσωτερικά',

  IhaveReadAndAgreeWithThe: 'Έχω διαβάσει και συμφωνώ με τους',
  termsAndConditions: 'Όρους και Προϋποθέσεις',
  IhaveBeenInformedAboutTheProcessingOfMyData:
    'Έχω ενημερωθεί για την επεξεργασία των προσωπικών μου δεδομένων, σύμφωνα με την',
  privacyStatement: 'Δήλωση Προστασίας Προσωπικών Δεδομένων',

  Jan: 'Ιαν',
  Feb: 'Φεβ',
  Mar: 'Μαρ',
  Apr: 'Απρ',
  May: 'Μαι',
  Jun: 'Ιουν',
  Jul: 'Ιουλ',
  Aug: 'Αυγ',
  Sep: 'Σεπ',
  Oct: 'Οκτ',
  Nov: 'Νοε',
  Dec: 'Δεκ',

  CASE_DISMISSED: 'Yπόθεση απορρίφθηκε',
  LAWSUIT: 'Αγωγή',
  REPORT_TO_OTHER_AUTHORITIES: 'Αναφορά σε άλλες Αρχές',
  RESOLVED_INTERNALLY: 'Επιλύθηκε εσωτερικά',

  tooltipLogo:
    'Το λογότυπο της εταιρείας θα εμφανίζεται όταν ένας πληροφοριοδότης υποβάλλει μια αναφορά στην εταιρεία σας.',
}
