import { useDataProviderContext } from '../../context/dataProvider/DataProvider'

const CompanyLogo = () => {
  const { companyLogo } = useDataProviderContext()

  return (
    <div className='max-w-[11.25rem] py-2 h-[6.25rem] flex items-center'>
      <img src={companyLogo ?? ''} alt='' className='max-w-full max-h-full' />
    </div>
  )
}

export default CompanyLogo
