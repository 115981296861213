export const positionOptions = [
  { value: '', label: 'Select Position' },
  { value: 'COMPLIANCE_OFFICER', label: 'Compliance Officer' },
  { value: 'ADMINISTRATOR', label: 'Administrator' },
  { value: 'HR', label: 'HR' },
]

export const resolutionOptions = [
  { value: 'CASE_DISMISSED', label: 'Case Dismissed' },
  { value: 'LAWSUIT', label: 'Lawsuit' },
  { value: 'REPORT_TO_OTHER_AUTHORITIES', label: 'Report to Other Authorities' },
  { value: 'RESOLVED_INTERNALLY', label: 'Resolved Internally' },
]

export const incidentOnGoingOptions = [
  { value: '', label: 'choose' },
  { value: 'yes', label: 'yes' },
  { value: 'no', label: 'no' },
]

export const yearOptions = [
  { value: '2022', label: '2022' },
  { value: '2023', label: '2023' },
  { value: '2024', label: '2024' },
  { value: '2025', label: '2025' },
]
