import { FaRegPlayCircle } from 'react-icons/fa'

interface Props {
  video: { id: number; file: string }
}

const CaseVideoPreview = ({ video }: Props) => {
  return (
    <div className='relative group before:absolute before:inset-0 w-max  before:bg-[rgba(0,0,0,0.20)] before:rounded-10px'>
      <a href={video.file} target='_blank' rel='noreferrer' className='rounded-10px'>
        <video
          src={video.file?.replace('http', 'https')}
          autoPlay={false}
          className='h-24  w-full rounded-10px'
        />
        <FaRegPlayCircle className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-10 text-lightGray text-5xl group-hover:text-white transition-all cursor-pointer' />
      </a>
    </div>
  )
}

export default CaseVideoPreview
