import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useSWRConfig } from 'swr'
import { useOutletContext, useNavigate, useParams } from 'react-router-dom'
import { useAuthContext } from '../../context/auth/auth'
import axios from '../../services/api'
import PageTitle from '../../components/UI/PageTitle'
import BranchesCategoryForm from '../../components/Forms/BranchesCategoryForm'
import { BranchTypes } from '../../interfaces/organization'

const EditBranch = () => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { token } = useAuthContext()
  const branches: BranchTypes[] = useOutletContext()
  const navigate = useNavigate()
  const { mutate } = useSWRConfig()
  const { id } = useParams()

  const branch = branches?.find(branch => branch.id.toString() === id)

  const onSubmit = async (values: { title: string }) => {
    try {
      setIsLoading(true)
      const response = await axios.patch(
        `/organizations/branch/${id}/`,
        {
          title: values.title,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      if (response.status === 200) {
        toast.success(t('branchHasBeenUpdated'))
        mutate('org')
        navigate(-1)
        setIsLoading(false)
      }
    } catch (error) {
      toast.error(t('failedToUpdateBranch'))
      setIsLoading(false)
    }
  }

  useEffect(() => {
    document.title = `${t('editBranch')} - ReportAll`
  }, [t])

  return (
    <>
      <PageTitle title={t('editBranch')} />
      <BranchesCategoryForm
        onSubmit={onSubmit}
        isLoading={isLoading}
        data={branch}
        label='branch'
        buttonText='editBranch'
      />
    </>
  )
}

export default EditBranch
