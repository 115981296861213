interface Props {
  question: string
  answer: string
}

const ReviewQuestion = ({ question, answer }: Props) => {
  return (
    <div className='my-5'>
      <p className='text-lg font-bold py-1'>{question}</p>
      <p className='text-lg py-1 whitespace-pre-line'>{answer}</p>
    </div>
  )
}

export default ReviewQuestion
