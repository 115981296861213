import { useLocation } from 'react-router-dom'
import { useSWRConfig } from 'swr'
import { useLangContext } from '../../context/langProvider/langProvider'

interface Props {
  type: 'admin' | 'reporter'
}

const LangSelector = ({ type }: Props) => {
  const { locale, setLocale } = useLangContext()
  const { mutate } = useSWRConfig()
  const { pathname } = useLocation()

  const isComplianceOfficerPath = pathname.startsWith('/compliance-officer')
  const isAdminPath = pathname.startsWith('/admin')

  return (
    <select
      className={`bg-mainBg font-bold  ${
        type === 'reporter'
          ? ' w-[2.8125rem] border-0 p-0 text-sm '
          : 'border-[1px] border-solid border-borderGray text-base rounded-lg w-full md:w-[17.5rem]'
      } print:hidden`}
      defaultValue={locale}
      onChange={event => {
        if (isAdminPath) mutate('users')
        if (isComplianceOfficerPath) mutate('reports')
        setLocale(event.target.value)
      }}
    >
      <option value='en'>{type === 'reporter' ? 'EN' : 'English'}</option>
      <option value='el'>{type === 'reporter' ? 'EΛ' : 'Ελληνικά'}</option>
      <option value='tr'>{type === 'reporter' ? 'TR' : 'Türkçe'}</option>
    </select>
  )
}

export default LangSelector
