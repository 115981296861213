import { Navigate, Outlet } from 'react-router-dom'
import { useAuthContext } from '../context/auth/auth'
import { navigateTo } from '../helpers/navigateTo'

const PublicRoute = () => {
  const { token, authClient } = useAuthContext()

  return !token && !authClient?.user_type ? (
    <Outlet />
  ) : token && authClient?.user_type ? (
    <Navigate to={navigateTo(authClient)} />
  ) : null
}

export default PublicRoute
