import React, { createContext, useContext, useEffect, useState } from 'react'
import i18n from '../../i18n/i18n'

interface Props {
  children: React.ReactNode
}

interface LangTypes {
  locale: string
  setLocale: (locale: string) => void
}

const langContext = createContext<LangTypes>({
  locale: '',
  setLocale: () => {},
})

export const useLangContext = (): LangTypes => useContext(langContext)

const LangProvider = ({ children }: Props) => {
  const [locale, setLocale] = useState<string>(i18n.language)

  useEffect(() => {
    i18n.on('languageChanged', () => setLocale(i18n.language))
  }, [])

  useEffect(() => {
    i18n.changeLanguage(locale)
  }, [locale])

  const ctx = {
    locale,
    setLocale,
  }
  return <langContext.Provider value={ctx}>{children}</langContext.Provider>
}

export default LangProvider
