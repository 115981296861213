import { useState } from 'react'
import { useFormik } from 'formik'
import { useSWRConfig } from 'swr'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import axios from '../../services/api'
import { RiSendPlaneLine } from 'react-icons/ri'
import { ImAttachment } from 'react-icons/im'
import { useAuthContext } from '../../context/auth/auth'
import { accteptedFiles } from '../../helpers/acceptedFiles'
import Spinner from '../UI/Spinner'
interface Props {
  reportId: number | undefined
  isTheReporter: boolean
}

const AddMessage = ({ reportId, isTheReporter }: Props) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { token, authClient } = useAuthContext()
  const { mutate } = useSWRConfig()

  const validationSchema = yup.object({
    message: yup
      .string()
      ?.trim()
      .max(320, 'Message must be at most 320 characters')
      .required('Message is required'),
  })

  const formik = useFormik({
    initialValues: {
      message: '',
      files: [],
    },
    validationSchema,
    onSubmit: async values => {
      try {
        setIsLoading(true)
        const messageFormData = new FormData()
        messageFormData.append('content', values.message)
        messageFormData.append('report', `${reportId}`)
        !isTheReporter && messageFormData.append('user', `${authClient?.id}`)

        if (values.files?.length !== 0)
          for (const file of values.files) messageFormData.append('media', file)

        const response = isTheReporter
          ? await axios.post('/report/report-comment/', messageFormData, {
              headers: {
                Token: JSON.parse(sessionStorage.getItem('reportToken')!),
              },
            })
          : await axios.post('/report/report-comment/', messageFormData, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })

        if (response.status === 201) {
          mutate('reportMessages')
          formik.resetForm()
        }
      } catch (error) {
        toast.error(t('failedToSendMessage'))
      } finally {
        setIsLoading(false)
      }
    },
  })

  const hasError = formik.errors.message && formik.touched.message

  return (
    <form className='py-6' onSubmit={formik.handleSubmit}>
      <div className='flex items-center'>
        <div
          className={`w-full flex items-center border-[1px] border-solid border-borderGray rounded-l-lg pr-4 ${
            !hasError ? ' border-borderGray' : 'border-[#CD4444]'
          }`}
        >
          <textarea
            placeholder={t('textHere')}
            {...formik.getFieldProps('message')}
            className='resize-none bg-transparent border-0 focus:outline-none h-[49px] w-full py-3 pl-4 pr-3'
          />
          {formik.values.files?.length !== 0 && (
            <p className='text-xl mr-1 text-textBlack cursor-default'>
              {formik.values.files.length}
            </p>
          )}
          <label htmlFor='file'>
            <ImAttachment className='text-xl cursor-pointer hover:text-primaryBlue transition-all' />
          </label>
          <input
            id='file'
            type='file'
            name='files'
            accept={accteptedFiles}
            className='hidden'
            multiple
            onChange={event => {
              formik.setFieldValue('files', event.currentTarget.files)
            }}
          />
        </div>
        <button
          className='bg-primaryBlue py-[15px] min-h-full rounded-r-lg text-base text-white cursor-pointer flex justify-center items-center gap-1 w-[64px] hover:opacity-90 transition-opacity'
          type='submit'
        >
          {isLoading ? <Spinner spinner='form' /> : <RiSendPlaneLine className='text-xl' />}
        </button>
      </div>
    </form>
  )
}

export default AddMessage
