import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import LoginForm from '../../components/Forms/LoginForm'
import PublicLayout from '../../containers/PublicLayout'

const Login = () => {
  const { t } = useTranslation()
  useEffect(() => {
    document.title = `${t('logIn')} - ReportAll`
  }, [t])

  return (
    <PublicLayout>
      <LoginForm />
    </PublicLayout>
  )
}

export default Login
