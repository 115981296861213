import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Step1 from '../../components/ReporterForm/Step1'
import Step2 from '../../components/ReporterForm/Step2'
import Step3 from '../../components/ReporterForm/Step3'
import Step4 from '../../components/ReporterForm/Step4'
import StepsBar from '../../components/FormStepBar/StepsBar'
import FormContainer from '../../containers/FormContainer'
import Nav from '../../components/ReporterHeader'
import PoweredBy from '../../components/UI/PoweredBy'

import { useStepContext } from '../../context/steps/StepsProvider'
import { useDataProviderContext } from '../../context/dataProvider/DataProvider'

const MakeReport = () => {
  const { t } = useTranslation()
  const { step } = useStepContext()
  const { companyLogo } = useDataProviderContext()
  const StepMap = [<Step1 />, <Step2 />, <Step3 />, <Step4 />]

  const ShowStep = () => {
    return StepMap[step - 1]
  }

  useEffect(() => {
    document.title = `${t('makeReport')} - ReportAll`
  }, [t])

  return (
    <div className='flex flex-col'>
      <Nav />
      <FormContainer activePage='make-report'>
        <StepsBar />
        {ShowStep()}
      </FormContainer>
      {companyLogo && <PoweredBy />}
    </div>
  )
}

export default MakeReport
