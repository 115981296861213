import LangSelector from '../components/LangSelector/LangSelector'
import Logo from '../components/UI/Logo'

interface Props {
  children: React.ReactNode
}

const PublicLayout = ({ children }: Props) => {
  return (
    <main className='bg-mainBg h-full'>
      <header className='flex items-center justify-between pb-4 pt-6 max-w-[1200px] w-full mx-auto'>
        <p className='w-[2.8125rem]' />
        <Logo />
        <LangSelector type='reporter' />
      </header>
      <section className='h-[calc(100%-140px)] w-full flex justify-center items-center px-4'>
        {children}
      </section>
    </main>
  )
}

export default PublicLayout
