import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import axios from '../../services/api'
import Input from '../Formik/Input'
import InputWrapper from '../Formik/InputWrapper'
import Label from '../Formik/Label'
import Button from '../Buttons/Button'
import Spinner from '../UI/Spinner'
import { useTranslation } from 'react-i18next'
import FormikForm from '../Formik/FormikForm'
import FormikErrorText from '../Formik/FormikErrorText'

const ResetPasswordForm = () => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const { token } = useParams()

  const initialValues = {
    password: '',
    passwordConfirmation: '',
  }

  const validationSchema = yup.object({
    password: yup
      .string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&.*])(?=.{8,})/,
        t('passwordMustBeAtLeast8CharactersAndContainAtLeastOneUppercaseLetterAndOneNumber')
      )
      .required(t('requiredField')),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref('password'), null], t('passwordsMustMatch'))
      .required(t('requiredField')),
  })

  const formik = useFormik({
    validateOnChange: false,
    initialValues,
    validationSchema,
    onSubmit: async values => {
      try {
        if (token?.length !== 32) throw Error
        setIsLoading(true)
        const response = await axios.post('/auth/password-reset/', {
          password: values.password,
          token,
        })

        if (response.status === 200) {
          setIsLoading(false)
          toast.success(t('yourPasswordHasBeenResetsussfully'))
          navigate('/login')
        }
      } catch (error) {
        setIsLoading(false)
        toast.error(t('failedToResetPassword'))
      }
    },
  })

  return (
    <FormikForm onSubmitHandler={formik.handleSubmit} formType='lg'>
      <div className='mb-3'>
        <h2 className='font-bold text-center text-2xl sm:text-3xl mb-4'>{t('resetPassword')}</h2>
        <p className='text-lightGray text-center'>{t('enterNewPassword')}</p>
      </div>

      <InputWrapper>
        <Label text={t('password')} />
        <Input
          id='Password'
          as='input'
          type='password'
          placeholder={t('insertPassword')}
          hasError={formik.touched.password && formik.errors.password ? true : false}
          {...formik.getFieldProps('password')}
        />
        {formik.errors.password && <FormikErrorText errorText={formik.errors.password} />}
      </InputWrapper>
      <InputWrapper>
        <Label text={t('confirmPassword')} />
        <Input
          id='Confirm Password'
          as='input'
          type='password'
          placeholder={t('insertPassword')}
          hasError={
            formik.touched.passwordConfirmation && formik.errors.passwordConfirmation ? true : false
          }
          {...formik.getFieldProps('passwordConfirmation')}
        />
        {formik.errors.passwordConfirmation && (
          <FormikErrorText errorText={formik.errors.passwordConfirmation} />
        )}
      </InputWrapper>
      <Button width='min-w-full' type='submit' text={isLoading ? 'Loading...' : t('resetPassword')}>
        {isLoading && <Spinner spinner='form' />}
      </Button>
    </FormikForm>
  )
}

export default ResetPasswordForm
