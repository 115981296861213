import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useModalContext } from '../../context/modal/modal'
import SideLink from './SideLink'

const SideLinks = () => {
  const { t, i18n } = useTranslation()
  const { showUniqueCodeDialogHandler } = useModalContext()
  const { pathname } = useLocation()
  const isEl = i18n.language === 'el'

  const checkReportsPageClasses = pathname.includes('/check-report/')
    ? `3xl:-left-[175px] 3xl:top-11 3xl:flex-col xsm:left-[75px] ${
        isEl ? '3xl:-left-[185px]' : '3xl:-left-[175px]'
      }`
    : `xxl:-left-[175px] xxl:top-11 xxl:flex-col ${
        isEl ? 'xxl:-left-[185px]' : 'xxl:-left-[175px]'
      }`

  return (
    <nav
      className={`hidden absolute xsm:flex xsm:-top-[55.5px] gap-3 ${checkReportsPageClasses} print:hidden`}
    >
      <SideLink text={t('makeReport')} path='/reporter' />
      {/* <SideLink
        text='Check Reports'
        path='/reporter/check-report'
        nestedActivePath='/reporter/check-report/'
      /> */}
      <SideLink
        text={t('checkReport')}
        onClickHandler={showUniqueCodeDialogHandler}
        nestedActivePath='/check-report/'
      />
    </nav>
  )
}

export default SideLinks
