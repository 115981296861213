import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { IoMdAdd } from 'react-icons/io'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import CompanyBranchesCategories from '../../components/CompanyBranchesCategories/CompanyBranchesCategories'
import Button from '../../components/Buttons/Button'
import ContentSection from '../../components/UI/ContentSection'
import PageTitle from '../../components/UI/PageTitle'
import { useReportsContext } from '../../context/reports/reports'

const Branches = () => {
  const { t } = useTranslation()
  const { selectedOrg } = useReportsContext()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const isExactPath = pathname === '/admin/branches'

  useEffect(() => {
    document.title = `${t('categories')} - ReportAll`
  }, [t])

  return (
    <ContentSection>
      {isExactPath ? (
        <>
          <div className='flex items-center gap-6 flex-wrap'>
            <PageTitle title={t('branches')} />
            <Button onClickHandler={() => navigate('create-branch')} text={t('newBranch')} type='button'>
              <IoMdAdd />
            </Button>
          </div>

          <CompanyBranchesCategories data={selectedOrg?.branch ?? []} isLoading={false} type='branch' />
        </>
      ) : (
        <Outlet context={selectedOrg?.branch ?? []} />
      )}
    </ContentSection>
  )
}

export default Branches
