import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useTable, usePagination, useGlobalFilter } from 'react-table'
import { IoChevronForwardOutline, IoChevronBackOutline } from 'react-icons/io5'
import { BsChevronRight } from 'react-icons/bs'
import TableArrowBtn from '../UI/TableArrowBtn'
import Spinner from '../UI/Spinner'
import Search from './Search'
import { useReportsContext } from '../../context/reports/reports'
import { useUsersContext } from '../../context/users/users'

interface Props {
  data: any
  columns: any
}

const TableItems = ({ columns, data = [] }: Props) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { isReportLoading, selectedOrg } = useReportsContext()
  const { isUserLoading } = useUsersContext()
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state,
    rows,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 5 },
    },
    useGlobalFilter,
    usePagination
  )

  const { pageSize, pageIndex, globalFilter } = state
  const isReportsRoute = pathname === '/compliance-officer/reports'
  const isLoading = isReportsRoute ? isReportLoading : isUserLoading

  const branchClasses =
    selectedOrg?.branch && isReportsRoute
      ? 'first:w-[8%] w-[10%] last:text-black text-left'
      : 'first:w-[8%] w-1/5 last:text-transparent text-center'
  return (
    <>
      <Search filter={globalFilter} setFilter={setGlobalFilter} />
      <div className='h-[585px] relative overflow-x-auto hide-scrollbar flex '>
        {isLoading ? (
          <Spinner spinner='table' width='w-11' height='h-11' color='#0C75FF' />
        ) : rows.length === 0 ? (
          <div className='flex justify-center items-center w-full'>
            <p className='text-2xl text-lightGray'>
              {isReportsRoute ? t('noReportsFound') : t('noUsersFound')}
            </p>
          </div>
        ) : (
          <table
            className='w-full border-spacing border-separate absolute min-w-[1100px]'
            {...getTableProps()}
          >
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th
                      className={`first:text-left first:pl-9 text-base font-bold ${branchClasses}`}
                      {...column.getHeaderProps()}
                    >
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map(row => {
                prepareRow(row)
                const { original }: any = row
                return (
                  <tr
                    {...row.getRowProps()}
                    className={`${
                      isReportsRoute ? 'hover:bg-[#FCFCFF] transition-all cursor-pointer group' : ''
                    }  `}
                    onClick={() => (isReportsRoute ? navigate(`report/${original.id}`) : null)}
                  >
                    {row.cells.map((cell, idx) => {
                      return (
                        <td
                          className={`first:text-left first:pl-9 pr-2 first:border-l first:rounded-l-lg  last:border-r last:rounded-r-lg border-y-[1px] border-solid border-borderGray text-lightGray text-base font-normal h-[5rem] ${
                            isReportsRoute && selectedOrg?.branch
                              ? 'last:pr-0 text-left'
                              : 'last:pr-8 text-center'
                          } `}
                          {...cell.getCellProps()}
                        >
                          {cell.render('Cell')}
                          {idx === 5 && isReportsRoute && !selectedOrg?.branch && (
                            <BsChevronRight className='ml-auto text-lg group-hover:opacity-100 opacity-0 transition-all' />
                          )}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>
        )}
      </div>
      <div className='border-t-[1px] border-solid border-borderGray flex justify-between items-center pt-5 flex-wrap gap-4'>
        <div>
          {rows.length !== 0 && (
            <p className='text-sm'>
              {t('showing')} {pageSize * pageIndex + 1} {t('to')} {pageSize * pageIndex + 1 + page.length - 1}{' '}
              {t('of')} {rows.length} {t('entries')}
            </p>
          )}
        </div>
        {rows.length !== 0 && (
          <div className='flex justify-between items-center gap-7'>
            <TableArrowBtn onClick={previousPage} disabled={!canPreviousPage}>
              <IoChevronBackOutline className='text-lg' />
            </TableArrowBtn>
            <p className='text-sm'>
              <span className='text-primaryBlue'>{pageIndex + 1}</span> {t('of')} {pageOptions.length}{' '}
              {t('pages')}
            </p>
            <TableArrowBtn onClick={nextPage} disabled={!canNextPage}>
              <IoChevronForwardOutline className='text-lg' />
            </TableArrowBtn>
          </div>
        )}
      </div>
    </>
  )
}

export default TableItems
