import { useTranslation } from 'react-i18next'
import CheckBox from './CheckBox'

// This component is used to show the policy in the print version.
const PrintPolicy = () => {
  const { t } = useTranslation()
  return (
    <div className='mt-8'>
      <div className='hidden print:items-center print:gap-[10px] print:flex print:mb-2 '>
        <CheckBox type='checkbox' checked={true} readOnly />
        <p className='text-textBlack text-[0.8125rem]'>
          {t('IhaveReadAndAgreeWithThe')}{' '}
          <span className='text-primaryBlue font-semibold'>{t('termsAndConditions')}</span>.
        </p>
      </div>
      <div className='hidden print:items-center print:gap-[10px]  print:flex '>
        <CheckBox type='checkbox' checked={true} readOnly />
        <p className='text-textBlack text-[0.8125rem]'>
          {t('IhaveBeenInformedAboutTheProcessingOfMyData')}{' '}
          <span className='text-primaryBlue font-semibold'>{t('privacyStatement')}</span>.
        </p>
      </div>
    </div>
  )
}

export default PrintPolicy
