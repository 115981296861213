import { useDataProviderContext } from '../../context/dataProvider/DataProvider'
import StepsButtons from './StepsButtons'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useModalContext } from '../../context/modal/modal'
import CaseDate from '../CaseOverview/CaseDate'
import Divider from '../UI/Divider'
import FormStepTitle from '../UI/FormStepTitle'
import ReviewQuestion from './ReviewQuestion'
import ReviewInfo from './ReviewInfo'
import DropzoneFilePreview from '../UI/DropzoneFilePreview'
import SaveTokenModal from '../Modals/SaveTokenModal'

interface Props {
  type?: 'block' | 'print'
}

const Step4 = ({ type = 'block' }: Props) => {
  const { t } = useTranslation()
  const reportData = useDataProviderContext()
  const { showSubmittedReportModal } = useModalContext()
  const displayCategory = reportData.categories?.find(cat => cat.id === reportData.category)

  return (
    <div className={`${type === 'block' ? 'print:hidden block' : 'print:block hidden'}`}>
      {showSubmittedReportModal && type === 'block' && <SaveTokenModal />}
      <div className='items-center my-6'>
        <FormStepTitle as='h1' text={t('review')} />
      </div>

      <div className='flex flex-col gap-7 w-full'>
        <ReviewInfo
          as='title'
          textLeft={t('startReport')}
          textRight={<CaseDate dateStyle='italic' momentProp={moment().format('DD/MM/yyyy HH:mm')} />}
        />

        {reportData.branch && (
          <ReviewInfo as='text' textLeft={t('branch')} textRight={reportData.branch.title} />
        )}
        <ReviewInfo
          as='text'
          textLeft={t('typeOfReport')}
          textRight={reportData.anonymity === 'ANONYMOUS' ? t('anonymous') : t('regular')}
        />
        <ReviewInfo as='text' textLeft={t('category')} textRight={displayCategory?.title} />

        {reportData.anonymity === 'CONFIDENTIAL' && (
          <>
            <ReviewInfo as='text' textLeft={t('fullName')} textRight={reportData.reporterName} />
            {reportData.reporterEmail && (
              <ReviewInfo as='text' textLeft='Email' textRight={reportData.reporterEmail} />
            )}
            {reportData.reporterPhone && (
              <ReviewInfo as='text' textLeft={t('telephone')} textRight={reportData.reporterPhone} />
            )}
          </>
        )}
      </div>

      <Divider />

      <FormStepTitle as='h1' text={t('questions')} />

      <div className='flex-col flex md:flex-row md:justify-between md:gap-6'>
        <ReviewQuestion
          question={t('whenDidTheIncidentOccur')}
          answer={moment(reportData.incidentDate).format('DD/MM/yyyy')}
        />
        <ReviewQuestion question={t('isTheIncidentStillOngoing')} answer={t(reportData.ongoing)} />
      </div>

      <ReviewQuestion question={t('whereDidTheIncidentOccur')} answer={reportData.incidentOccur} />
      <ReviewQuestion question={t('whoIsInvolvedAndWhatIsTheirCapacity')} answer={reportData.involved} />
      <ReviewQuestion question={t('fullDescriptionOfTheIncident')} answer={reportData.description} />

      {reportData.media?.length > 0 && type === 'block' && (
        <>
          <Divider />
          <FormStepTitle as='h1' text={t('attachments')} />
          <div className='mt-4'>
            <div className='grid grid-cols-2 xsm:grid-cols-4 gap-7'>
              {reportData.media?.map((file: { file: { name: string }; preview: string }) => (
                <DropzoneFilePreview key={file.preview} preview={file.preview} fileName={file.file.name} />
              ))}
            </div>
          </div>
        </>
      )}

      {type === 'block' && <StepsButtons />}
    </div>
  )
}

export default Step4
