import { useFormik } from 'formik'
import * as yup from 'yup'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useWidth from '../../hooks/useWidth'
import axios from '../../services/api'
import Button from '../Buttons/Button'
import InputWrapper from '../Formik/InputWrapper'
import Label from '../Formik/Label'
import { useModalContext } from '../../context/modal/modal'
import { DebounceInput } from 'react-debounce-input'
import CheckBox from '../UI/CheckBox'
import { useDataProviderContext } from '../../context/dataProvider/DataProvider'
import { useCallback, useEffect, useState } from 'react'
import { useAuthContext } from '../../context/auth/auth'
import { OrganizationTypes } from '../../interfaces/organization'

const CompanyCodeForm = () => {
  const { t, i18n } = useTranslation()
  const [selectedOrganization, setSelectedOrganization] = useState<OrganizationTypes | undefined>(undefined)
  const navigate = useNavigate()
  const { logout } = useAuthContext()
  const { showUniqueCodeDialogHandler } = useModalContext()
  const reportData = useDataProviderContext()
  const { width } = useWidth()
  const [searchParams] = useSearchParams()
  const accessCode = searchParams.get('ac')

  const isEn = i18n.language === 'en' || i18n.language === 'en-US' || i18n.language === 'en-GB'
  const isEl = i18n.language === 'el'

  const validationSchema = yup.object({
    companyCode: yup.string().trim().required(),
    termsAndConditions: yup.boolean().oneOf([true]).required(),
    privacyStatement: yup.boolean().oneOf([true]).required(),
  })

  const formik = useFormik({
    initialValues: {
      companyCode: accessCode ?? '',
      branch: '',
      termsAndConditions: false,
      privacyStatement: false,
    },
    validationSchema,
    onSubmit: values => {
      if (!selectedOrganization) return
      reportData.setters.setCompanyLogo(selectedOrganization.image)
      reportData.setters.setCompanyCode(values.companyCode.toUpperCase().trim())
      if (selectedOrganization.branch) {
        const branch = values.branch.split('&&')
        reportData.setters.setBranchId({ id: branch[0], title: branch[1] })
      }

      navigate('/reporter')
    },
  })

  const {
    setFieldValue,
    values: { companyCode },
  } = formik

  const selectedOrganizationHandler = useCallback(async () => {
    try {
      const response = await axios.get(`/organizations/organization/by-code/${companyCode}/`)
      setSelectedOrganization(response.data.data)
    } catch (error) {
      setSelectedOrganization(undefined)
      setFieldValue('termsAndConditions', false)
      setFieldValue('privacyStatement', false)
    }
  }, [companyCode, setFieldValue])

  useEffect(() => {
    if (companyCode) {
      selectedOrganizationHandler()
    }
  }, [companyCode, selectedOrganizationHandler])

  useEffect(() => {
    if (selectedOrganization?.privacy_policy === null) {
      setFieldValue('privacyStatement', true)
    }
    if (selectedOrganization?.branch) {
      setFieldValue(
        'branch',
        `${selectedOrganization.branch[0].id.toString()}&&${selectedOrganization.branch[0].title}`
      )
    }
  }, [selectedOrganization, setFieldValue])

  useEffect(() => {
    logout()
  }, [logout])

  const isValidAccessCode =
    accessCode && accessCode?.toUpperCase() === selectedOrganization?.code.toUpperCase()

  return (
    <form
      onSubmit={formik.handleSubmit}
      className={`bg-white rounded-20px w-full flex flex-col py-12 sm:py-16 px-6 xsm:px-20  gap-6 shadow-form ${
        isEl ? 'max-w-[48.125rem]' : 'max-w-[38.1875rem]'
      }`}
    >
      <div className='mb-3'>
        <h2 className='font-bold text-center text-3xl mb-4'>{t('welcome')}</h2>
        {!!!isValidAccessCode && <p className='text-textBlack text-center'>{t('companyCodeInfo')}</p>}
      </div>
      {!!!isValidAccessCode && (
        <InputWrapper>
          <Label text={t('companyCodeLabel')} />
          <DebounceInput
            minLength={7}
            debounceTimeout={1000}
            id='Company Code'
            type='text'
            autoComplete='off'
            placeholder={t('companyCodeInputPlaceholder')}
            name='companyCode'
            onChange={formik.handleChange}
            value={formik.values.companyCode}
            className={`${
              formik.errors.companyCode !== undefined ? 'border-red-500' : 'border-borderGray'
            } bg-white rounded-lg px-4 py-3 max-w-full border-[1px] border-solid  focus:border-primaryBlue hover:border-primaryBlue transition-all outline-none placeholder:text-lightGray`}
          />
        </InputWrapper>
      )}

      {selectedOrganization && (
        <>
          <p>{selectedOrganization?.title}</p>
          {selectedOrganization && selectedOrganization?.branch && (
            <select
              className={`placeholder:text-lightGray border-borderGray bg-white h-12 px-4 p-0 block w-full text-gray-700 border-[1px] rounded-lg hover:border-primaryBlue transition-all`}
              {...formik.getFieldProps('branch')}
            >
              {selectedOrganization?.branch?.map(branch => (
                <option key={branch.id} value={`${branch.id}&&${branch.title}`}>
                  {branch.title}
                </option>
              ))}
            </select>
          )}
          <div className='flex items-center gap-[10px]'>
            <CheckBox
              type='checkbox'
              {...formik.getFieldProps('termsAndConditions')}
              hasError={formik.touched.termsAndConditions && formik.errors.termsAndConditions ? true : false}
            />
            <p className='text-textBlack text-[0.8125rem]'>
              {t('IhaveReadAndAgreeWithThe')}{' '}
              <a
                href='https://reportall.eu/terms-and-conditions/'
                target='_blank'
                rel='noreferrer'
                className='text-primaryBlue font-semibold'
              >
                {t('termsAndConditions')}
              </a>
              .
            </p>
          </div>
          {selectedOrganization && selectedOrganization.privacy_policy !== null && (
            <div className='flex items-start gap-[10px]'>
              <CheckBox
                type='checkbox'
                {...formik.getFieldProps('privacyStatement')}
                hasError={formik.touched.privacyStatement && formik.errors.privacyStatement ? true : false}
              />
              <p className='text-textBlack text-[0.8125rem]'>
                {t('IhaveBeenInformedAboutTheProcessingOfMyData')}{' '}
                <a
                  href={selectedOrganization?.privacy_policy}
                  target='_blank'
                  rel='noreferrer'
                  className='text-primaryBlue font-semibold'
                >
                  {t('privacyStatement')}
                </a>
                .
              </p>
            </div>
          )}
        </>
      )}

      <div
        className={`flex justify-between mt-6 flex-col gap-4 ${
          isEn ? 'sm:flex-row sm:gap-0' : 'md:flex-row  md:gap-0'
        }`}
      >
        <Button
          type='button'
          bgColor='bg-[#E1ECFC]'
          textColor='text-black'
          text={t('statusOfExistingReport')}
          width={!isEn && width! <= 768 ? 'min-w-full' : isEn && width! <= 640 ? 'min-w-full' : 'w-full'}
          onClickHandler={() => showUniqueCodeDialogHandler()}
        />
        <Button
          type='submit'
          text={t('createNewReport')}
          width={!isEn && width! <= 768 ? 'min-w-full' : isEn && width! <= 640 ? 'min-w-full' : 'w-full'}
        />
      </div>
    </form>
  )
}

export default CompanyCodeForm
