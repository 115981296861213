import { Navigate, Outlet } from 'react-router-dom'
import { useDataProviderContext } from '../context/dataProvider/DataProvider'

const ReporterRoute = () => {
  const reportData = useDataProviderContext()

  return reportData.companyCode !== null ? <Outlet /> : <Navigate to='/' />
}

export default ReporterRoute
