import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useSWRConfig } from 'swr'
import { useAuthContext } from '../../context/auth/auth'
import { ReportTypes } from '../../interfaces/report'
import { UserTypes } from '../../interfaces/user'
import axios from '../../services/api'

interface Props {
  options: UserTypes[]
  assignedCo: number | undefined
  report: ReportTypes
  isAssigned: boolean
  userVisibility: ReportTypes['user_visibility']
}

const CaseSelectOfficer = ({ options = [], assignedCo, report }: Props) => {
  const { t } = useTranslation()
  const { token } = useAuthContext()
  const [assignTo, setAssignedTo] = useState<string>('')
  const { mutate } = useSWRConfig()

  const assignReportToCoOfficer = useCallback(async () => {
    try {
      const response = await axios.patch(
        `/report/report/${report.id}/assign-compliance-officer/`,
        {
          compliance_officer: assignTo,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      if (response.status === 200) {
        // CHANGE REPORT STATUS IN PROGRESS AFTER WE ASSIGNED IT TO A CO OFFICER
        const reportStatus = await axios.patch(
          `/report/report/${report.id}/`,
          {
            status: 'IN_PROGRESS',
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        if (reportStatus.status === 200) {
          mutate('reports')
          toast.success(t('caseAssigned'))
        }
      }
    } catch (error) {
      toast.error('Failed to assign case')
    }
  }, [assignTo, report.id, token, mutate, t])

  useEffect(() => {
    if (assignTo && report.id) {
      assignReportToCoOfficer()
    }
  }, [assignTo, report.id, assignReportToCoOfficer])

  return (
    <select
      onChange={e => setAssignedTo(e.target.value)}
      defaultValue={assignedCo || ''}
      className='bg-white min-w-[10.25rem] pl-4 py-2 max-w-[15.625rem] focus:outline-none border-[1px] border-solid border-lightGray rounded-lg'
    >
      <option value='' disabled>
        {t('choose')}
      </option>
      {options
        .filter(user => user?.is_active === true)
        .map(user => (
          <option key={user.id} value={user.id}>{`${user.first_name} ${user.last_name}`}</option>
        ))}
    </select>
  )
}

export default CaseSelectOfficer
