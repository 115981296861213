import { useState } from 'react'
import { useFormik } from 'formik'
import { useSWRConfig } from 'swr'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import axios from '../../services/api'
import { useAuthContext } from '../../context/auth/auth'
import { toast } from 'react-toastify'
import Spinner from '../UI/Spinner'

interface Props {
  reportId: number | undefined
}

const AddNote = ({ reportId }: Props) => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { token, authClient } = useAuthContext()
  const { mutate } = useSWRConfig()

  const validationSchema = yup.object({
    message: yup.string()?.trim().max(320, t('noteLimit')).required(t('requiredField')),
  })

  const formik = useFormik({
    initialValues: {
      message: '',
    },
    validationSchema,
    onSubmit: async values => {
      try {
        setIsLoading(true)
        const commentData = new FormData()
        commentData.append('content', values.message)
        commentData.append('report', `${reportId}`)
        commentData.append('compliance_officer', `${authClient?.id}`)

        const response = await axios.post('report/report-note/', commentData, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })

        if (response.status === 201) {
          mutate('reportNotes')
          formik.resetForm()
        }
      } catch (error) {
        toast.error(t('failedToSubmitNote'))
      } finally {
        setIsLoading(false)
      }
    },
  })

  const hasError = formik.errors.message && formik.touched.message
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className='flex '>
        <textarea
          className={`resize-none bg-white rounded-l-lg px-4 py-3 w-full h-[50px] border-[1px] border-solid border-borderGray focus:border-primaryBlue hover:border-primaryBlue transition-all outline-none ${
            !hasError ? ' border-borderGray' : 'border-[#CD4444]'
          }`}
          placeholder={t('textHere')}
          {...formik.getFieldProps('message')}
        />
        <button
          className='bg-primaryBlue rounded-r-lg text-base text-white cursor-pointer flex justify-center items-center gap-1 w-[106px] hover:opacity-90 transition-opacity'
          type='submit'
        >
          {isLoading ? <Spinner spinner='form' /> : `${t('submit')}`}
        </button>
      </div>
      <p className={` text-sm mt-1 ml-1 ${!hasError ? ' text-lightGray' : 'text-[#CD4444]'}`}>
        {!hasError
          ? `${formik.values.message?.trim().length}/320 ${t('characterLimit')}`
          : formik.errors.message}
      </p>
    </form>
  )
}

export default AddNote
