import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import * as yup from 'yup'
import Button from '../Buttons/Button'
import Input from '../Formik/Input'
import InputWrapper from '../Formik/InputWrapper'
import Label from '../Formik/Label'
import { positionOptions } from '../../helpers/selectOptions'
import { UserTypes } from '../../interfaces/user'
import Spinner from '../UI/Spinner'
import FormikForm from '../Formik/FormikForm'

interface Props {
  user?: UserTypes
  isLoading: boolean
  onSubmit: (values: UserTypes) => void
}

const UserForm = ({ onSubmit, user, isLoading }: Props) => {
  const { t } = useTranslation()
  const textButton = user ? t('editUser') : t('saveNewUser')
  const initialValues = {
    username: user ? user.username : '',
    first_name: user ? user.first_name : '',
    last_name: user ? user.last_name : '',
    email: user ? user.email : '',
    user_type: user ? user.user_type : '',
  }

  const validationSchema = yup.object({
    username: yup.string().required(),
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    email: yup.string().required(),
    user_type: yup.string().required(),
  })

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  })

  return (
    <FormikForm onSubmitHandler={formik.handleSubmit}>
      <div className='grid sm:grid-cols-2 gap-10 w-full'>
        <InputWrapper>
          <Label text={t('userName')} />
          <Input
            as='input'
            id='Username'
            type='text'
            placeholder={t('userName')}
            hasError={formik.touched.username && formik.errors.username ? true : false}
            {...formik.getFieldProps('username')}
          />
        </InputWrapper>
        <InputWrapper>
          <Label text={t('firstName')} />
          <Input
            as='input'
            id='First Name'
            type='text'
            placeholder={t('firstName')}
            hasError={formik.touched.first_name && formik.errors.first_name ? true : false}
            {...formik.getFieldProps('first_name')}
          />
        </InputWrapper>
        <InputWrapper>
          <Label text={t('lastName')} />
          <Input
            as='input'
            id='Last Name'
            type='text'
            placeholder={t('lastName')}
            hasError={formik.touched.last_name && formik.errors.last_name ? true : false}
            {...formik.getFieldProps('last_name')}
          />
        </InputWrapper>
        <InputWrapper>
          <Label text='Email' />
          <Input
            as='input'
            id='Email'
            type='email'
            placeholder='Email'
            hasError={formik.touched.email && formik.errors.email ? true : false}
            {...formik.getFieldProps('email')}
          />
        </InputWrapper>
        <InputWrapper>
          <Label text={t('position')} />
          <Input
            as='select'
            hasError={formik.touched.user_type && formik.errors.user_type ? true : false}
            {...formik.getFieldProps('user_type')}
            selectOptions={positionOptions}
          />
        </InputWrapper>
      </div>

      <div className='flex flex-col items-end mt-14 pt-7 border-t-[1px] border-solid'>
        <Button type='submit' text={isLoading ? 'Submitting' : textButton}>
          {isLoading && <Spinner spinner='form' />}
        </Button>
      </div>
    </FormikForm>
  )
}

export default UserForm
