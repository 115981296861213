import { createContext, useContext, useState } from 'react'
import useSWR, { useSWRConfig } from 'swr'
import jwt from 'jwt-decode'
import axios from '../../services/api'
import { AuthCtxTypes, Props, TokenTypes } from './auth.interfaces'
import { DecodedTokenTypes } from '../../interfaces/decodedToken'

const authProviderCtx = createContext<AuthCtxTypes>({
  token: null,
  uniqueCode: null,
  authClient: undefined,
  logout: () => null,
  storeToken: () => null,
  setUniqueCode: () => null,
})

export const useAuthContext = (): AuthCtxTypes => useContext(authProviderCtx)

const AuthProvider = ({ children }: Props) => {
  // GET TOKEN FROM LOCAL STORAGE
  const getToken = () => {
    const token: AuthCtxTypes['token'] = localStorage.getItem('token')
    if (token !== null) {
      const accessToken: TokenTypes = JSON.parse(token)
      return accessToken.access
    }
    return null
  }

  const [token, setToken] = useState<AuthCtxTypes['token']>(getToken())
  const [uniqueCode, setUniqueCode] = useState<string | null>(null)
  const { cache } = useSWRConfig()
  // SAVE TOKEN TO LOCAL STORAGE
  const storeToken = (token: TokenTypes) => {
    localStorage.setItem('token', JSON.stringify(token))
    sessionStorage.clear()
    setToken(token.access)
  }

  // LOGOUT USER
  const logout = () => {
    cache.delete('authClient')
    localStorage.removeItem('token')
    setToken(null)
  }

  // GET THE LOGGED IN USER
  const getAuthClient = async () => {
    const { user_id }: DecodedTokenTypes = jwt(token!)
    const response = await axios.get(`/auth/user/${user_id}/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return response.data.data
  }

  const { data: authClient } = useSWR(token ? 'authClient' : null, getAuthClient)

  const ctx: AuthCtxTypes = {
    token,
    uniqueCode,
    authClient,
    logout,
    storeToken,
    setUniqueCode,
  }

  return <authProviderCtx.Provider value={ctx}>{children}</authProviderCtx.Provider>
}

export default AuthProvider
