import { ReportTypes } from '../../interfaces/report'

interface Props {
  children: React.ReactNode
  userVisibility?: ReportTypes['user_visibility']
  flexDirection?: 'col' | 'row'
}

const CaseLabelWrapper = ({ children, userVisibility, flexDirection = 'row' }: Props) => {
  let displayReporterInfo: string = ''

  if (userVisibility === 'ANONYMOUS') {
    displayReporterInfo = 'hidden'
  }
  if (userVisibility === 'CONFIDENTIAL') {
    displayReporterInfo = ''
  }

  const flexClasses =
    flexDirection === 'row' ? 'flex-wrap items-center justify-between gap-6' : 'flex-col gap-2'

  return (
    <div
      className={`${displayReporterInfo} ${flexClasses} flex overflow-y-hidden max-h-[11rem] text-textBlack print:overflow-y-visible print:max-h-full print:mb-4`}
    >
      {children}
    </div>
  )
}

export default CaseLabelWrapper
