import { useState, createContext, useContext } from 'react'

interface Props {
  children: React.ReactNode
}
interface StepsProviderType {
  step: number
  setStep: (value: number) => void
}
export const StepsProviderCtx = createContext<StepsProviderType>({
  step: 1,
  setStep: () => null,
})

export function useStepContext(): StepsProviderType {
  return useContext(StepsProviderCtx)
}

const StepsProvider = ({ children }: Props) => {
  const [step, setStep] = useState<number>(1)
  const finalCtx: StepsProviderType = { step, setStep }

  return <StepsProviderCtx.Provider value={finalCtx}>{children}</StepsProviderCtx.Provider>
}

export default StepsProvider
