import { useTranslation } from 'react-i18next'
import { getStatusClassColors } from '../../helpers/getStatusClassColors'
import { ReportTypes } from '../../interfaces/report'

interface Props {
  status: ReportTypes['status']
}

const CaseStatusText = ({ status }: Props) => {
  const { t } = useTranslation()
  const colorClass = getStatusClassColors(status)

  const text = t(status)

  return (
    <p
      className={`${colorClass} w-[110px] text-sm py-1 text-center rounded-lg capitalize font-medium print:hidden`}
    >
      {text === 'Κλειστές' ? 'Κλειστή' : text?.replace('_', ' ').toLowerCase()}
    </p>
  )
}

export default CaseStatusText
