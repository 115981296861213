import { useUsersContext } from '../../context/users/users'
import { useTranslation } from 'react-i18next'
import { ReportTypes } from '../../interfaces/report'
import CaseDate from './CaseDate'
import CaseLabel from './CaseLabel'
import CaseLabelWrapper from './CaseLabelWrapper'
import CaseMedia from './CaseMedia'
import CaseSection from './CaseSection'
import CaseSectionTitle from './CaseSectionTitle'
import CaseSelectOfficer from './CaseSelectOfficer'
import CaseText from './CaseText'
import ComplianceOfficerNotes from '../ComplianceOfficerNotes/ComplianceOfficerNotes'
import AddNote from '../ComplianceOfficerNotes/AddNote'
import ReportTitle from '../UI/ReportTitle'
import CaseStatusText from './CaseStatusText'
import ExportButton from '../Buttons/ExportButton'
import PrintUniqueCode from '../UI/PrintUniqueCode'
import ReportLogs from '../ReportLogs/ReportLogs'
import 'react-image-lightbox/style.css'
import PrintPolicy from '../UI/PrintPolicy'

interface Props {
  report: ReportTypes
  isComplianceOfficer: boolean
  isTheReporter: boolean
}

const CaseOverview = ({ report, isComplianceOfficer, isTheReporter }: Props) => {
  const { t } = useTranslation()
  const { complianceOfficers } = useUsersContext()

  return (
    <div
      className={`bg-white py-8 px-3 md:px-8 rounded-20px ${
        isComplianceOfficer
          ? 'mt-8 shadow-form lg:w-[60%] rounded-tl-none relative print:px-0'
          : 'mt-0 shadow-none lg:w-[55%]'
      }  print:py-0 print:shadow-none`}
    >
      {isComplianceOfficer && (
        <div className='absolute hidden xsm:block -top-14 right-0'>
          <ExportButton text={t('export')} status='complianceOfficer' />
        </div>
      )}
      {!isComplianceOfficer && <PrintUniqueCode />}
      <header className='flex items-center flex-wrap gap-3 justify-between pb-8 border-b-[1px] border-solid border-borderGray'>
        <div className='flex items-center gap-2 sm:gap-5'>
          <ReportTitle
            title={`${isComplianceOfficer ? `${t('caseOverview')}` : `${t('report')} #${report?.id}`}`}
          />
          <CaseStatusText status={report?.status!} />
        </div>

        {isComplianceOfficer ? (
          report.status !== 'CLOSED' && (
            <div className='flex items-center gap-3 print:hidden'>
              <CaseLabel text={t('assignTo')} />

              <CaseSelectOfficer
                options={complianceOfficers}
                assignedCo={report?.assigned_co?.id}
                report={report}
                isAssigned={report?.assigned_co !== null}
                userVisibility={report?.user_visibility!}
              />
            </div>
          )
        ) : (
          <ExportButton text={t('export')} status='reporter' />
        )}
      </header>
      <CaseSection>
        <CaseLabelWrapper>
          <CaseSectionTitle title={` ${isComplianceOfficer ? `${t('details')}` : `${t('caseDetails')}`}`} />
          <CaseDate
            date={report?.date_filled}
            dateStyle={`${isComplianceOfficer ? 'italic' : 'italicBlack'}`}
          />
        </CaseLabelWrapper>
        {report.branch && (
          <CaseLabelWrapper>
            <CaseLabel text={t('branch')} />
            <CaseText text={report.branch.title} />
          </CaseLabelWrapper>
        )}
        <CaseLabelWrapper>
          <CaseLabel text={t('typeOfReport')} />
          <CaseText text={report?.user_visibility === 'ANONYMOUS' ? t('anonymous') : t('regular')} />
        </CaseLabelWrapper>
        <CaseLabelWrapper>
          <CaseLabel text={t('category')} />
          <CaseText text={report?.category?.title} />
        </CaseLabelWrapper>
        {report?.name && (
          <CaseLabelWrapper
            userVisibility={!isTheReporter && report?.user_visibility ? report?.user_visibility : undefined}
          >
            <CaseLabel text={t('fullName')} />
            <CaseText text={report?.name} />
          </CaseLabelWrapper>
        )}
        {report?.email && (
          <CaseLabelWrapper
            userVisibility={!isTheReporter && report?.user_visibility ? report?.user_visibility : undefined}
          >
            <CaseLabel text='Email' />
            <CaseText text={report?.email} />
          </CaseLabelWrapper>
        )}
        {report?.phone_number && (
          <CaseLabelWrapper
            userVisibility={!isTheReporter && report?.user_visibility ? report?.user_visibility : undefined}
          >
            <CaseLabel text={t('telephone')} />
            <CaseText text={report?.phone_number} />
          </CaseLabelWrapper>
        )}
      </CaseSection>
      <CaseSection>
        <CaseSectionTitle title={t('questions')} />
        <CaseLabelWrapper flexDirection='row'>
          <CaseLabelWrapper flexDirection='col'>
            <CaseLabel text={t('whenDidTheIncidentOccur')} />
            <CaseDate date={report?.incident_date} dateStyle='normal' />
          </CaseLabelWrapper>
          <CaseLabelWrapper flexDirection='col'>
            <CaseLabel text={t('isTheIncidentStillOngoing')} />
            <CaseText text={report?.is_active ? t('yes') : t('no')} />
          </CaseLabelWrapper>
        </CaseLabelWrapper>
        <CaseLabelWrapper flexDirection='col'>
          <CaseLabel text={t('whereDidTheIncidentOccur')} />
          <CaseText text={report?.incident_location} />
        </CaseLabelWrapper>
        <CaseLabelWrapper flexDirection='col'>
          <CaseLabel text={t('whoIsInvolvedAndWhatIsTheirCapacity')} />
          <CaseText text={report?.involved_name_capacity} />
        </CaseLabelWrapper>
        <CaseLabelWrapper flexDirection='col'>
          <CaseLabel text={t('fullDescriptionOfTheIncident')} />
          <CaseText text={report?.full_description} />
        </CaseLabelWrapper>
      </CaseSection>
      <CaseSection printVisibility='print:hidden'>
        <CaseSectionTitle title={t('attachments')} />
        <CaseMedia media={report?.media} />
      </CaseSection>
      {isComplianceOfficer && (
        <CaseSection printVisibility='print:hidden'>
          <CaseSectionTitle title={t('complianceOfficerNotes')} />
          <ComplianceOfficerNotes reportId={report?.id} />
          <AddNote reportId={report?.id} />
        </CaseSection>
      )}

      {/* ONLY FOR PRINTING */}
      {isComplianceOfficer && (
        <ReportLogs report={report} visibility='hidden' printVisibility='print:block' />
      )}

      {isTheReporter && <PrintPolicy />}
    </div>
  )
}

export default CaseOverview
