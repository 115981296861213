import { Outlet } from 'react-router-dom'
import FormContainer from '../../containers/FormContainer'
import Nav from '../../components/ReporterHeader'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const CheckReport = () => {
  const { t } = useTranslation()
  useEffect(() => {
    document.title = `${t('checkReport')} - ReportAll`
  }, [t])

  return (
    <>
      <Nav />
      <FormContainer activePage='check-report'>
        <Outlet />
      </FormContainer>
    </>
  )
}

export default CheckReport
