import { useNavigate } from 'react-router-dom'
import { FiChevronLeft } from 'react-icons/fi'
import { useTranslation } from 'react-i18next'

interface Props {
  marginBottom?: string
}

const BackBtn = ({ marginBottom = 'mb-0' }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <button
      type='button'
      onClick={() => navigate(-1)}
      className={`bg-transparent w-max flex items-center text-sm font-medium text-lightGray gap-[1px] cursor-pointer print:hidden ${marginBottom}`}
    >
      <FiChevronLeft className='text-lg text-lightGray' />
      {t('back')}
    </button>
  )
}

export default BackBtn
