import { Outlet } from 'react-router-dom';
import Header from '../components/Header';
import Navigation from '../components/Navigation/Navigation';
import { AuthClientTypes } from '../context/auth/auth.interfaces';

interface Props {
  role: AuthClientTypes['user_type'];
}

const Layout = ({ role }: Props) => {
  return (
    <main className='h-full sm:h-screen w-full flex flex-col'>
      <Header type='admin' />
      <section className='flex flex-1 bg-mainBg sm:h-[calc(100%-106px)]'>
        <Navigation role={role} />
        <Outlet />
      </section>
    </main>
  );
};

export default Layout;
