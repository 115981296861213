import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { IoMdAdd } from 'react-icons/io'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import CompanyBranchesCategories from '../../components/CompanyBranchesCategories/CompanyBranchesCategories'
import Button from '../../components/Buttons/Button'
import ContentSection from '../../components/UI/ContentSection'
import PageTitle from '../../components/UI/PageTitle'
import useAxios from '../../hooks/useAxios'

const Categories = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const isExactPath = pathname === '/admin/categories'
  const { data: categories, isLoading } = useAxios({
    url: '/report/category/organization-code/',
    swrKey: 'categories',
    method: 'POST',
  })

  useEffect(() => {
    document.title = `${t('categories')} - ReportAll`
  }, [t])

  return (
    <ContentSection>
      {isExactPath ? (
        <>
          <div className='flex items-center gap-6 flex-wrap'>
            <PageTitle title={t('categories')} />
            <Button onClickHandler={() => navigate('create-category')} text={t('newCategory')} type='button'>
              <IoMdAdd />
            </Button>
          </div>

          <CompanyBranchesCategories data={categories} isLoading={isLoading} type='category' />
        </>
      ) : (
        <Outlet context={categories} />
      )}
    </ContentSection>
  )
}

export default Categories
