import { useLocation } from 'react-router-dom'
import { useModalContext } from '../../context/modal/modal'

const HamburgerMenu = () => {
  const { isOpen, isModalOpenHandler } = useModalContext()
  const { pathname } = useLocation()
  const hamburgerLine = `h-1 w-6 my-1 rounded-full bg-primaryBlue transition ease transform duration-300`

  return (
    <button
      className={`flex flex-col h-12 justify-center items-center group ${
        pathname.startsWith('/reporter') || pathname.startsWith('/check-report')
          ? 'xsm:hidden'
          : 'sm:hidden'
      } print:hidden`}
      onClick={isModalOpenHandler}
    >
      <div className={`${hamburgerLine} ${isOpen && 'rotate-45 translate-y-3'}`} />
      <div className={`${hamburgerLine} ${isOpen && 'opacity-0'}`} />
      <div className={`${hamburgerLine} ${isOpen && '-rotate-45 -translate-y-3'}`} />
    </button>
  )
}

export default HamburgerMenu
