export const translationEn = {
  // Company Code Form
  welcome: 'Welcome',
  companyCodeInfo:
    'Enter your Company code to create a new report, or check the status of a previously submitted report.',
  companyCodeLabel: 'Company access code',
  companyCodeInputPlaceholder: 'Enter access code to create new Report',
  statusOfExistingReport: 'Status of existing Report',
  createNewReport: 'Create new Report',
  companyCode: 'Company Code',

  // Access Report Modal
  accessReportModalTitle: 'Unique Code required to access',
  accessReportModalText:
    'The moment you submitted the report you were given a unique code. Please paste the code into the input field below.',
  accessReportModalLabel: 'Unique Code',
  accessReportModalPlaceholder: 'Insert access code',
  accessReportModalCancelBtn: 'Cancel',
  accessReportModalChecklBtn: 'Check Report',

  // Save Token Modal
  saveTokenTitle: 'The report was submitted. Save the following code:',
  saveTokenImportant: 'IMPORTANT:',
  saveTokenImportantNote:
    'Save the code below and store it securely. Do not share the code with anyone. With this code you will be able to access your report to follow up on its progress and see messages.',
  saveTokenUniqueCodeLabel: 'Your Unique Code',
  saveTokenReceivedReport: 'We have received your report and will get back to you as soon as possible.',
  saveTokenReportInfo:
    'You can download/export your submitted report below, or click "Complete" to finish up.',
  saveTokenExportBtn: 'Export Report',
  saveTokenCompleteBtn: 'Complete',

  // Delete Resource Modal
  areYouSureWantToDelete: 'Are you sure you want to delete this user?',
  areYouSureWantToDeleteThisCategory: 'Are you sure you want to delete this category?',
  areYouSureWantToDeleteThisBranch: 'Are you sure you want to delete this branch?',
  yesIamSure: "Yes, I'm sure",
  noCancel: 'No, cancel',

  // Resolution Option Modal
  areYouSureWantToCloseThisCase: 'Are you sure you want to close this case?',
  youAreAboutToCloseThisCase: 'You are about to close this case',
  theCaseWillBe: 'The case will be',
  howWouldYouLikeToResolveThisCase: 'How would you like to resolve this case?',
  yesCloseTheCase: 'Yes, Close the case',
  noIamNotReady: "No, I'm not ready",
  archived: 'Archived',
  resolved: 'Resolved',

  // Report Form
  reportAnOccurrenceText:
    'Use this form to report any unexpected incidents of various misconduct, fraud or compliance violations that are against organization’s policies and procedures. ReportAll form requests information that is relevant to determine whether and how to conduct an investigation. Confidentiality is highly protected and compliance officers are obliged to protect any kind of personal information.',
  makeReport: 'Make Report',
  checkReport: 'Check Report',
  review: 'Review',
  startReport: 'Start Report',
  typeOfReport: 'Type of Report',
  category: 'Category',
  branch: 'Branch',
  fullName: 'Full Name',
  telephone: 'Telephone',
  questions: 'Questions',
  whenDidTheIncidentOccur: 'When did the incident occur?',
  isTheIncidentStillOngoing: 'Is the incident still ongoing?',
  whereDidTheIncidentOccur: 'Where did the incident occur?',
  whoIsInvolvedAndWhatIsTheirCapacity: 'Who is involved and what is their capacity?',
  nameSurnameAndTheirCapacity: 'Name Surname and their capacity',
  fullDescriptionOfTheIncident: 'Full Description of the Incident',
  regular: 'Regular',
  anonymous: 'Anonymous',
  attachments: 'Attachments',
  uploadFilesTitle: 'You can upload any files related to your Report',
  dragHere: 'Drag here to Upload Files',
  supportedFiles: 'Supported Files',
  addFile: 'Add File',
  reportAnOccurrence: 'Report an occurence',
  anonymousReporting: 'Anonymous reporting',
  regularReporting: 'Regular reporting',
  chooseIncidentcategory: 'Choose incident category',
  choose: 'Choose',
  anonymousText:
    'This Reporting is anonymous, since it does not require any personal data or/and contact details from the complainant. Please note that in this case, follow up on your Report is allowed only through the access code provided to you upon submission of the Report.',
  regularText:
    'This Reporting may ask your personal data or/and contact details prior to be submitted. Please note that, apart from your name, such information is not obligatory. In any case your Report will be treated with utmost confidentiality.',
  optional: 'optional',
  back: 'Back',
  nextStep: 'Next Step',
  submitReport: 'Submit Report',
  noAttachmentsFound: 'No attachments found',
  textHere: 'Text Here',
  freeTextSmallField: 'Free Text Small Field',
  freeTextLargeField: 'Free Large Text Field',
  details: 'Details',
  assignTo: 'Assign To',
  caseOverview: 'Case Overview',
  activityLog: 'Activity Log',
  submit: 'Submit',
  search: 'Search',
  yes: 'Yes',
  no: 'No',
  writeYourFullName: 'Write your full name',
  writeYourEmail: 'Write your email',
  writeYourTelephone: 'Write your telephone',
  writeThePlaceOfTheIncident: 'Write the place of the incident',
  addTheNamesOfThePeopleInvolved: 'Add the names of the people involved',
  describeIncident:
    'Please describe the incident (What happened? How it happened? etc.) Be as specific as possible.',
  report: 'Report',
  export: 'Export',
  caseDetails: 'Case Details',
  yourMessages: 'Your Messages',
  yourMessagesInfo:
    'Here you can contact the compliance officer undertaking your case, to add more information regarding your report.',
  UNASSIGNED: 'Unassigned',
  IN_PROGRESS: 'In Progress',
  CLOSED: 'Closed',
  TOTAL: 'Total',
  selectBranch: 'Select Branch',

  // Navigation
  dashboard: 'Dashboard',
  reports: 'Reports',
  users: 'Users',
  settings: 'Settings',
  statistics: 'Statistics',
  categories: 'Categories',
  branches: 'Branches',

  // Admin
  newUser: 'New User',
  editUser: 'Edit User',
  saveNewUser: 'Save New User',
  userName: 'Username',
  firstName: 'First Name',
  lastName: 'Last Name',
  password: 'Password',
  position: 'Position',
  language: 'Language',
  newCategory: 'New Category',
  newBranch: 'New branch',
  editCategory: 'Edit Category',
  editBranch: 'Edit Branch',
  insertCategory: 'Insert Category',
  insertName: 'Insert Name',
  saveCategory: 'Save Category',
  noCategoriesFound: 'No categories found',
  noDataFound: 'No data found',
  changePassword: 'Change Password',
  updateCompanyName: 'Update Company Name',
  updatePrivacyPolicy: 'Update Privacy Policy',
  enterLink: 'Enter Privacy Policy Link',
  updateLogo: 'Update Logo',
  companyLogo: 'Company Logo',

  // Compliance Officer
  complianceOfficer: 'Compliance Officer',
  visibility: 'Visibility',
  created: 'Created',
  status: 'Status',
  overviewStatistics: 'Overview Statistics',
  complianceOfficerNotes: 'Compliance Officer Notes',
  complianceOffcerNoteCreatedBy: 'Compliance Officer Note created by:',
  youHaventAddedNotes: "You haven't added any notes to this case yet.",
  onlyAssignedCoOfficerCanViewMessages: 'Only the assigned Compliance Officer can view the messages.',
  characterLimit: 'Character Limit',
  noteLimit: 'Message must be at most 320 characters',
  closeCase: 'Close Case',
  resolutionOption: 'Resolution Option',
  archivedClosed: 'Archive (Closed)',
  resolvedClosed: 'Resolve (Closed)',
  caseClosed: 'Case Closed',
  caseHasBeenClosed: 'Case has been closed',
  failedToCloseCase: 'Failed to close case',
  REPORT_CREATED: 'Report Created',
  COMPLIANCE_OFFICER_ASSIGNED: 'Compliance Officer Assigned:',
  REPORT_STATUS_CHANGED: 'Report Status Changed:',
  REPORT_NOTE_CREATED: 'Report Note Created',
  REPORT_EDITED: 'Report Edited',

  // Table
  showing: 'Showing',
  to: 'to',
  entries: 'entries',
  of: 'of',
  pages: 'pages',
  ANONYMOUS: 'Anonymous',
  noReportsFound: 'No Reports Found',
  noUsersFound: 'No Users Found',

  // Charts
  mostReportedCategories: 'Most reported categories',
  conclusionType: 'Conclusion type',
  numberNatureOfReports: 'Number / Nature of Reports',
  total: 'All Reports',
  other: 'Other',
  noDataFoundForTheSelectedPeriod: 'No data found for the selected period',

  // Toast messages
  copiedToClipboard: 'Copied to clipboard',
  aUserWithThisEmailAlreadyExists: 'A user with this email already exists',
  aUserWithThisUsernameAlreadyExists: 'A user with this username already exists',
  failedToCreateUser: 'Failed to create user',
  userHasBeenCreated: 'User has been created',
  userHasBeenUpdated: 'User has been updated',
  failedToUpdateUser: 'Failed to update user',
  userHasBeenDeleted: 'User has been deleted',
  failedToDeleteUser: 'Failed to delete user',
  failedToSubmitNote: 'Failed to submit note',
  failedToLoadReports: 'Failed to load reports',
  failedToCreateCategory: 'Failed to create category',
  categoryHasBeenCreated: 'Category has been created',
  categoryHasBeenDeleted: 'Category has been deleted',
  failedToDeleteCategory: 'Failed to delete category',
  categoryHasBeenUpdated: 'Category has been updated',
  branchHasBeenUpdated: 'Branch has been updated',
  failedToUpdateBranch: 'Failed to update branch',
  branchHasBeenDeleted: 'Branch has been deleted',
  failedToDeleteBranch: 'Failed to delete branch',
  failedToUpdateCategory: 'Failed to update category',
  branchHasBeenCreated: 'Branch has been created',
  failedToCreateBranch: 'Failed to create branch',
  submitFailed: 'Submit Failed',
  aResetLinkHasBeenSentToYourEmail: 'A reset link has been sent to your email.',
  InvalidUsernameOrPassword: 'Invalid Username or Password',
  yourPasswordHasBeenResetsussfully: 'Your password has been reset successfully.',
  failedToResetPassword: 'Failed to reset password',
  yourPasswordHasBeenChanged: 'Your password has been changed successfully.',
  failedToChangePassword: 'Failed to change password',
  companyNameHasBeenUpdated: 'Company name has been updated',
  failedToUpdateCompanyName: 'Failed to update company name',
  sessionExpired: 'Your session has expired. Please login again.',
  failedToSendMessage: 'Failed to send message',
  failedToLoadMessages: 'Failed to load messages',
  privacyPolicyHasBeenUpdated: 'Privacy Policy has been updated',
  failedToUpdatePrivacyPolicy: 'Failed to update privacy policy',
  logoHasBeenUpdated: 'Logo has been updated',
  failedToUpdateLogo: 'Failed to update logo',
  caseAssigned: 'Case has been assigned successfully',

  // Error messages
  somethingWentWrongFailedToLoadNotes: 'Something went wrong, failed to load notes',

  //Validation Messages
  requiredField: 'Required Field',
  invalidEmail: 'Invalid Email',
  invalidPhone: 'Invalid Telephone',

  logout: 'Log out',
  logIn: 'Log in',
  enterCredentials: 'Enter your Credentials to access your account.',
  resetPassword: 'Reset Password',
  forgotPassword: 'Forgot Password?',
  insertUsername: 'Insert Username',
  insertPassword: 'Insert Password',
  insertEmail: 'Insert Email',
  enterYourEmailToResetYourPassword: 'Enter your email to reset your password.',
  enterNewPassword: 'Enter New Password',
  confirmPassword: 'Confirm Password',
  passwordMustBeAtLeast8CharactersAndContainAtLeastOneUppercaseLetterAndOneNumber:
    'Password must be at least 8 characters and contain at least one uppercase letter one number and one special character.',
  passwordsMustMatch: 'Passwords must match',

  'Select Position': 'Select Position',
  'Compliance Officer': 'Compliance Officer',
  Administrator: 'Administrator',
  HR: 'HR',
  COMPLIANCE_OFFICER: 'Compliance Officer',
  ADMINISTRATOR: 'Administrator',

  chooseResolutionOption: 'Choose resolution option',
  'Case Dismissed': 'Case Dismissed',
  Lawsuit: 'Lawsuit',
  'Report to Other Authorities': 'Report to Other Authorities',
  'Resolved Internally': 'Resolved Internally',

  IhaveReadAndAgreeWithThe: 'I have read and agree with the',
  termsAndConditions: 'Terms and Conditions',
  IhaveBeenInformedAboutTheProcessingOfMyData:
    'I have been informed about the processing of my personal data, pursuant to the',
  privacyStatement: 'Privacy Statement',
  Jan: 'Jan',
  Feb: 'Feb',
  Mar: 'Mar',
  Apr: 'Apr',
  May: 'May',
  Jun: 'Jun',
  Jul: 'Jul',
  Aug: 'Aug',
  Sep: 'Sep',
  Oct: 'Oct',
  Nov: 'Nov',
  Dec: 'Dec',

  CASE_DISMISSED: 'Case Dismissed',
  LAWSUIT: 'Lawsuit',
  REPORT_TO_OTHER_AUTHORITIES: 'Report to Other Authorities',
  RESOLVED_INTERNALLY: 'Resolved Internally',

  tooltipLogo: 'The company logo will be shown when a whistleblower submits a report to your company.',
}
