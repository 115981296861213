import { useUsersContext } from '../../context/users/users'
import { ReportNoteTypes } from '../../interfaces/reportNote'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

interface Props {
  reportNote: ReportNoteTypes
}

const ComplianceOfficerNote = ({ reportNote }: Props) => {
  const { t } = useTranslation()
  const { complianceOfficers } = useUsersContext()
  const complianceOfficer = complianceOfficers.find(
    officer => officer.id === reportNote?.compliance_officer?.id
  )
  const coOfficerName = `${complianceOfficer?.first_name.toLowerCase()} ${complianceOfficer?.last_name.toLowerCase()}`

  return (
    <div id='note'>
      <p className='text-base font-normal text-textBlack'>
        {t('complianceOffcerNoteCreatedBy')}{' '}
        <span className='capitalize'>{coOfficerName || ''}</span>
      </p>
      <p className='text-xs font-normal text-textBlack mt-[14px] mb-[6px]'>
        {moment(reportNote.date_created).format('DD/MM/yyyy HH:mm')}
      </p>
      <p className='bg-[#E1ECFC] p-5 text-textBlack text-sm rounded-lg whitespace-pre-line'>
        {reportNote.content}
      </p>
    </div>
  )
}

export default ComplianceOfficerNote
