import { useTranslation } from 'react-i18next'
import { CategoryTypes } from '../../interfaces/category'
import { BranchTypes } from '../../interfaces/organization'
import ErrorMsg from '../UI/ErrorMsg'
import Spinner from '../UI/Spinner'
import CompanyBranchCategory from './CompanyBranchCategory'

interface Props {
  data: CategoryTypes[] | BranchTypes[]
  isLoading: boolean
  type: 'category' | 'branch'
}

const CompanyBranchesCategories = ({ data = [], isLoading, type }: Props) => {
  const { t } = useTranslation()
  const centerClass = 'min-h-[500px] flex flex-col items-center justify-center'

  const displayCategories = data.map(data => <CompanyBranchCategory key={data.id} data={data} type={type} />)

  return (
    <div className='w-full min-h-[28.125rem] px-5 py-5 md:px-10 md:py-6 lg:py-10 bg-white rounded-20px shadow-form mt-7'>
      {isLoading && (
        <div className={centerClass}>
          <Spinner spinner='table' width='w-11' height='h-11' color='#0C75FF' />
        </div>
      )}
      {data.length === 0 && !isLoading && (
        <div className={centerClass}>
          <ErrorMsg text={t('noDataFound')} />
        </div>
      )}

      {!isLoading && <div className='grid grid-cols-1 lg:grid-cols-2 gap-5'>{displayCategories}</div>}
    </div>
  )
}

export default CompanyBranchesCategories
