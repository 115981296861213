import { useState } from 'react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { useSWRConfig } from 'swr'
import axios from '../../services/api'
import * as yup from 'yup'
import { useReportsContext } from '../../context/reports/reports'
import InputWrapper from '../Formik/InputWrapper'
import { BsCheckCircleFill, BsXCircleFill } from 'react-icons/bs'
import { useAuthContext } from '../../context/auth/auth'

const UpdatePolicy = () => {
  const { t } = useTranslation()
  const { mutate } = useSWRConfig()
  const { selectedOrg } = useReportsContext()
  const { token } = useAuthContext()
  const [isFocused, setIsFocused] = useState<boolean>(false)

  const validationSchema = yup.object({
    policy: yup.string().trim().url().required('Privacy Policy link is required'),
  })

  const formik = useFormik({
    initialValues: {
      policy: selectedOrg?.privacy_policy ? selectedOrg.privacy_policy.trim() : '',
    },
    validationSchema,
    onSubmit: async values => {
      try {
        const response = await axios.patch(
          `/organizations/organization/${selectedOrg?.id}/`,
          {
            privacy_policy: values.policy?.trim(),
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )

        if (response.status === 200) {
          toast.success(t('privacyPolicyHasBeenUpdated'))
          mutate('org')
          setIsFocused(false)
        }
      } catch (error) {
        toast(t('failedToUpdatePrivacyPolicy'))
      }
    },
  })

  const hasError = formik.touched.policy && formik.errors.policy ? 'border-red-500' : 'border-borderGray'

  return (
    <form className='flex flex-col gap-3' onSubmit={formik.handleSubmit}>
      <InputWrapper>
        <div
          onFocus={() => setIsFocused(true)}
          className={`${hasError} cursor-default  bg-transparent rounded-lg px-3 py-2 w-full md:w-[17.5rem] border-[1px] border-solid hover:border-primaryBlue transition-all`}
        >
          <input
            placeholder={t('enterLink')}
            autoComplete='off'
            className={`bg-transparent border-0 p-0 w-full scale-95 focus:outline-none font-bold placeholder:font-normal ${
              isFocused ? 'cursor-text' : 'cursor-default'
            }`}
            type='text'
            {...formik.getFieldProps('policy')}
          />
        </div>
      </InputWrapper>
      <div className='flex items-center gap-3 self-end'>
        {isFocused &&
          (formik.values.policy === null ||
            formik.values.policy.trim() !== selectedOrg?.privacy_policy?.trim()) &&
          formik.values.policy.trim().length !== 0 && (
            <>
              <button type='submit'>
                <BsCheckCircleFill className='text-2xl text-green-500 cursor-pointer' />
              </button>

              <BsXCircleFill
                className='text-2xl text-red-500 cursor-pointer'
                onClick={() => {
                  formik.setFieldValue(
                    'policy',
                    selectedOrg?.privacy_policy && selectedOrg.privacy_policy.trim()
                  )
                  setIsFocused(false)
                }}
              />
            </>
          )}
      </div>
    </form>
  )
}

export default UpdatePolicy
