import axios from 'axios'

const apiUrl =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_STAGING_API_URL
    : process.env.REACT_APP_PRODUCTION_API_URL

const instance = axios.create({
  baseURL: apiUrl,
})

export default instance
