import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ChangePasswordForm from '../../components/Forms/ChangePasswordForm'
import BackBtn from '../../components/Buttons/BackBtn'
import ContentSection from '../../components/UI/ContentSection'
import PageTitle from '../../components/UI/PageTitle'

const ChangePassword = () => {
  const { t } = useTranslation()
  useEffect(() => {
    document.title = `${t('changePassword')} - ReportAll`
  }, [t])

  return (
    <ContentSection>
      <BackBtn marginBottom='mb-5' />
      <PageTitle title={t('changePassword')} />
      <ChangePasswordForm />
    </ContentSection>
  )
}

export default ChangePassword
