interface Props {
  children: React.ReactNode
  width?: 'w-full' | 'w-auto'
  type?: 'admin' | 'reporter'
}

const InputWrapper = ({ children, width = 'w-auto', type = 'admin' }: Props) => {
  switch (type) {
    case 'admin':
      return <div className={`flex flex-col ${width}`}>{children}</div>
    case 'reporter':
      return <div className='my-9'>{children}</div>
  }
}

export default InputWrapper
