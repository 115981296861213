import { toast } from 'react-toastify'
import axios from './api'

interface DeleteResourceTypes {
  id: number
  closeDialog: () => void
  mutate: (value: string) => void
  api: string
  toastError: string
  toastSuccess: string
  swrKey: string
  token: string | null
}

export const deleteResource = async ({
  id,
  api,
  closeDialog,
  mutate,
  toastError,
  toastSuccess,
  swrKey,
  token,
}: DeleteResourceTypes) => {
  try {
    const response = await axios.delete(`${api}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (response.status === 204 || response.status === 200) {
      toast.success(toastSuccess)
      mutate(swrKey)
      closeDialog()
    }
  } catch (error) {
    toast.error(toastError)
  }
}
