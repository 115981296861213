import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { CategoryTypes } from '../../interfaces/category'
import { BranchTypes } from '../../interfaces/organization'
import Button from '../Buttons/Button'
import FormikForm from '../Formik/FormikForm'
import Input from '../Formik/Input'
import InputWrapper from '../Formik/InputWrapper'
import Label from '../Formik/Label'
import Spinner from '../UI/Spinner'

interface Props {
  onSubmit: (values: { title: string }) => void
  isLoading: boolean
  data?: CategoryTypes | BranchTypes
  label: string
  buttonText: string
}

const BranchesCategoryForm = ({ onSubmit, isLoading, data, label, buttonText }: Props) => {
  const { t } = useTranslation()

  const validationSchema = yup.object({
    title: yup.string().trim().required('Name is required'),
  })

  const formik = useFormik({
    initialValues: {
      title: data ? data.title : '',
    },
    validationSchema,
    onSubmit,
  })

  return (
    <FormikForm onSubmitHandler={formik.handleSubmit}>
      <InputWrapper>
        <Label text={t(label)} />
        <Input
          type='text'
          placeholder={t('insertName')}
          hasError={formik.touched.title && formik.errors.title ? true : false}
          {...formik.getFieldProps('title')}
        />
      </InputWrapper>
      <div className='flex justify-end mt-14 border-t-[1px] pt-7 border-solid'>
        <Button type='submit' text={t(buttonText)}>
          {isLoading && <Spinner spinner='form' />}
        </Button>
      </div>
    </FormikForm>
  )
}

export default BranchesCategoryForm
