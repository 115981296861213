import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import MostReportedCategories from '../../components/Charts/MostReportedCategories'
import NumberOfReports from '../../components/Charts/NumberOfReports'
import StatusReportNumbers from '../../components/StatusReportNumbers/StatusReportNumbers'
import ContentSection from '../../components/UI/ContentSection'
import PageTitle from '../../components/UI/PageTitle'

const Dashboard = () => {
  const { t } = useTranslation()
  useEffect(() => {
    document.title = `${t('dashboard')} - ReportAll`
  }, [t])

  return (
    <ContentSection>
      <PageTitle title={t('dashboard')} />
      <div className='h-[calc(100%-58px)] flex flex-col gap-4 md:gap-9 mt-4 '>
        <StatusReportNumbers />
        <div className='h-[calc(100%+340px)] 2xl:h-1/2 flex gap-4 md:gap-9 flex-col 2xl:flex-row'>
          <NumberOfReports />
          <MostReportedCategories />
        </div>
      </div>
    </ContentSection>
  )
}

export default Dashboard
