import { useReportsContext } from '../../context/reports/reports'
import Spinner from '../UI/Spinner'
import StatusNumber from './StatusNumber'

const StatusReportNumbers = () => {
  const { reports, isReportLoading } = useReportsContext()

  const getUnassignedReports = reports && reports.filter(report => report.status === 'UNASSIGNED')
  const getInProgressReports = reports && reports.filter(report => report.status === 'IN_PROGRESS')
  const getClosedReports = reports && reports.filter(report => report.status === 'CLOSED')

  return (
    <div
      className={`${
        isReportLoading ? 'grid-cols-1' : 'grid-cols-2'
      } w-full grid 2xl:h-1/2 min-h-[292px] gap-9 shadow-form rounded-20px py-9 md:grid-cols-none md:min-h-[164px] md:flex md:justify-around md:items-center`}
    >
      {isReportLoading ? (
        <Spinner spinner='table' height='h-11' width='w-11' color='#0C75FF' />
      ) : (
        <>
          <StatusNumber numberOfReports={getUnassignedReports?.length} status='UNASSIGNED' />
          <StatusNumber numberOfReports={getInProgressReports?.length} status='IN_PROGRESS' />
          <StatusNumber numberOfReports={getClosedReports?.length} status='CLOSED' />
          <StatusNumber numberOfReports={reports?.length} status='TOTAL' />
        </>
      )}
    </div>
  )
}

export default StatusReportNumbers
