interface Props {
  children: React.ReactNode
  [x: string]: any
}

const TableArrowBtn = ({ children, ...restProps }: Props) => {
  return (
    <button
      type='button'
      className='disabled:text-lightGray text-primaryBlue cursor-pointer'
      {...restProps}
    >
      {children}
    </button>
  )
}

export default TableArrowBtn
