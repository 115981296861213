import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import ForgotPasswordForm from '../../components/Forms/ForgotPasswordForm'
import PublicLayout from '../../containers/PublicLayout'

const ForgotPassword = () => {
  const { t } = useTranslation()
  useEffect(() => {
    document.title = `${t('forgotPassword')} - ReportAll`
  }, [t])

  return (
    <PublicLayout>
      <ForgotPasswordForm />
    </PublicLayout>
  )
}

export default ForgotPassword
