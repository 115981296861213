import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { BrowserRouter as Router } from 'react-router-dom'

import AuthProvider from './context/auth/auth'
import ModalProvider from './context/modal/modal'
import UsersProvider from './context/users/users'
import StepsProvider from './context/steps/StepsProvider'
import DataProvider from './context/dataProvider/DataProvider'
import ReportsProvider from './context/reports/reports'
import './index.css'
import LangProvider from './context/langProvider/langProvider'

ReactDOM.render(
  <React.StrictMode>
    <LangProvider>
      <AuthProvider>
        <DataProvider>
          <StepsProvider>
            <UsersProvider>
              <ReportsProvider>
                <ModalProvider>
                  <Router>
                    <App />
                  </Router>
                </ModalProvider>
              </ReportsProvider>
            </UsersProvider>
          </StepsProvider>
        </DataProvider>
      </AuthProvider>
    </LangProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
