import { useState } from 'react'
import Lightbox from 'react-image-lightbox'
import { ReportTypes } from '../../interfaces/report'

interface Props {
  images: ReportTypes['media']
}

const CaseImagesPreview = ({ images = [] }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [photoIndex, setPhotoIndex] = useState<number>(0)

  return (
    <div
      className='h-24 w-[90px] rounded-10px cursor-pointer relative before:absolute before:inset-0 before:bg-[rgba(0,0,0,0.20)] before:rounded-10px'
      onClick={() => setIsOpen(true)}
    >
      <img src={images[0]?.file} alt='' className='object-cover w-full h-full rounded-10px' />
      {images.length > 1 && (
        <p className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white text-3xl font-medium'>
          +{images.length}
        </p>
      )}
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex].file}
          onCloseRequest={() => setIsOpen(false)}
          nextSrc={images[(photoIndex + 1) % images.length].file}
          prevSrc={images[(photoIndex + images.length - 1) % images.length].file}
          onMovePrevRequest={() =>
            setPhotoIndex(prevState => (prevState + images.length - 1) % images.length)
          }
          onMoveNextRequest={() => setPhotoIndex(prevState => (prevState + 1) % images.length)}
        />
      )}
    </div>
  )
}

export default CaseImagesPreview
