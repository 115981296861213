interface Props {
  text?: string
}

const CaseText = ({ text }: Props) => {
  const isEmail = text?.includes('@') ? 'lowercase' : 'normal-case'
  return (
    <p
      className={`overflow-y-auto text-base text-[#3D3D3D] font-normal whitespace-pre-line ${isEmail} print:overflow-y-visible`}
    >
      {text}
    </p>
  )
}

export default CaseText
