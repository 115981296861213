interface Props {
  text: string
  isActive: boolean
  onClickHandler: () => void
}

const Tab = ({ text, isActive, onClickHandler }: Props) => {
  return (
    <button
      className={`w-[145px] xsm:w-[163px] rounded-t-10px border-solid border--b-2 font-semibold h-[52px] flex flex-col justify-between items-center ${
        isActive ? 'bg-white  text-primaryBlue ' : 'rounded-none bg-[#E1ECFC] text-[#9CA3AF] '
      }`}
      onClick={onClickHandler}
    >
      <div className='border-[1px] border-solid border-transparent' />
      {text}
      <div
        className={`border-solid border-[1px] w-full ${
          isActive ? 'border-primaryBlue' : 'border-[#E1ECFC]'
        }`}
      />
    </button>
  )
}

export default Tab
