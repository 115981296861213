import { useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { useModalContext } from '../../context/modal/modal'
import { toast } from 'react-toastify'
import { FiCopy } from 'react-icons/fi'
import { useDataProviderContext } from '../../context/dataProvider/DataProvider'
import { useStepContext } from '../../context/steps/StepsProvider'
import { useAuthContext } from '../../context/auth/auth'
import useWidth from '../../hooks/useWidth'
import ExportButton from '../Buttons/ExportButton'
import Button from '../Buttons/Button'
import Logo from '../UI/Logo'
import { useTranslation } from 'react-i18next'
import Backdrop from '../UI/Backdrop'
import Step4 from '../ReporterForm/Step4'
import PrintPolicy from '../UI/PrintPolicy'

const SaveTokenModal = () => {
  const { t } = useTranslation()
  const { width } = useWidth()
  const { uniqueCode } = useAuthContext()
  const { setShowSubmittedReportModal } = useModalContext()
  const reportData = useDataProviderContext()
  const { setStep } = useStepContext()
  const [isKeyCopied, setIsKeyCopied] = useState<boolean>(false)
  const keyText = useRef<HTMLParagraphElement>(null)

  const copyKeyToClipboard = async () => {
    await navigator.clipboard.writeText(keyText!.current!.textContent!)
    setIsKeyCopied(true)
    toast.success(t('copiedToClipboard'))
  }

  const printClasses = 'print:top-7 print:-translate-y-0 print:left-0 print:min-w-full print:-translate-x-0'

  return ReactDOM.createPortal(
    <>
      <Backdrop />
      <div
        className={`absolute sm:fixed z-50 bg-white max-w-[375px] xsm:max-w-[500px] sm:max-w-[600px] md:max-w-[787px] w-full px-5 sm:px-11  py-6 sm:py-9 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-20px ${printClasses}`}
      >
        <div className='hidden print:flex print:mb-8'>
          <Logo />
        </div>
        <h2 className='font-bold text-2xl'>{t('saveTokenTitle')}</h2>
        <p className='font-base text-black my-8'>
          <span className='text-[#E61B1B] font-bold'>{t('saveTokenImportant')}</span>{' '}
          {t('saveTokenImportantNote')}
        </p>
        <h3 className='font-semibold text-lg'>{t('saveTokenUniqueCodeLabel')}</h3>
        <div className='overflow-x-auto sm:overflow-x-visible'>
          <p
            onClick={copyKeyToClipboard}
            ref={keyText}
            className={`${
              isKeyCopied ? 'border-green-500' : 'border-lightGray'
            }  flex items-center justify-between text-lightGray flex-row gap-5 text-[0.8125rem] sm:text-base border-[1px] border-solid  w-full rounded-lg px-4 py-3 mt-[10px] mb-8 cursor-pointer print:w-max print:border-lightGray`}
          >
            {uniqueCode}
            <FiCopy className={`print:hidden text-xl ${isKeyCopied ? 'text-green-500' : 'text-lightGray'}`} />
          </p>
        </div>

        <p className='font-base text-lightGray'>
          {t('saveTokenReceivedReport')} <span className='print:hidden'>{t('saveTokenReportInfo')}</span>
        </p>
        <div className='mt-9 flex flex-col sm:flex-row gap-[14px] justify-between'>
          <ExportButton text={t('saveTokenExportBtn')} status='reporter' />
          <Button
            type='submit'
            text={t('saveTokenCompleteBtn')}
            width={width! >= 640 ? 'w-full' : 'min-w-full'}
            onClickHandler={() => {
              setShowSubmittedReportModal(false)
              setStep(1)
              // revoke the file preview urls to avoid memory leaks
              reportData.media.forEach((file: { preview: string }) => URL.revokeObjectURL(file.preview))
              reportData.resetFormData()
            }}
          />
        </div>
        <Step4 type='print' />
        <PrintPolicy />
      </div>
    </>,
    document.getElementById('overlay') as HTMLElement
  )
}

export default SaveTokenModal
