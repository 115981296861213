import { useTranslation } from 'react-i18next'
import { useSWRConfig } from 'swr'
import { useAuthContext } from '../../context/auth/auth'
import { useModalContext } from '../../context/modal/modal'
import { deleteResource } from '../../services/deleteResource'

interface Props {
  text: string
  api: string
  toastSuccess: string
  toastError: string
  swrKey: string
}

const ModalConfirmationDialog = ({ text, api, toastSuccess, toastError, swrKey }: Props) => {
  const { t } = useTranslation()
  const { token } = useAuthContext()
  const { resourceId, closeConfirmationDialogHandler } = useModalContext()
  const { mutate } = useSWRConfig()

  return (
    <div className='overflow-y-auto overflow-x-hidden fixed right-0 left-0 top-0 z-50 flex justify-center items-center md:inset-0 h-modal h-full bg-[rgba(0,0,0,0.1)]'>
      <div className='relative px-4 w-full  h-auto  max-w-md'>
        <div className='relative bg-white rounded-20px shadow dark:bg-mainBg'>
          <div className='flex justify-end p-2'>
            <button
              onClick={closeConfirmationDialogHandler}
              type='button'
              className='text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white transition-all'
            >
              <svg
                className='w-5 h-5'
                fill='currentColor'
                viewBox='0 0 20 20'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                  clipRule='evenodd'
                ></path>
              </svg>
            </button>
          </div>

          <div className='p-6 pt-0 text-center'>
            <svg
              className='mx-auto mb-4 w-14 h-14 text-red-600 dark:text-red-600'
              fill='none'
              stroke='currentColor'
              viewBox='0 0 24 24'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                d='M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
              ></path>
            </svg>

            <h3 className='mb-5 text-lg font-normal text-gray-600'>{text}</h3>

            <button
              onClick={() =>
                deleteResource({
                  api,
                  toastSuccess,
                  toastError,
                  swrKey,
                  id: resourceId!,
                  closeDialog: closeConfirmationDialogHandler,
                  mutate,
                  token,
                })
              }
              type='button'
              className='text-white font-medium rounded-lg text-base inline-flex items-center px-5 py-2.5 text-center mr-2 transition-all ${
               
                   bg-red-600 hover:bg-red-800 focus:ring-4 focus:ring-red-300'
            >
              {t('yesIamSure')}
            </button>

            <button
              onClick={closeConfirmationDialogHandler}
              type='button'
              className='text-white bg-white hover:bg-gray-100 focus:ring-4 focus:ring-gray-300 rounded-lg border border-gray-200 text-base font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:border-gray-500 dark:hover:text-white transition-all dark:hover:bg-gray-600'
            >
              {t('noCancel')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalConfirmationDialog
