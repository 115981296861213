import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PublicLayout from '../../containers/PublicLayout'
import CompanyCodeForm from '../../components/Forms/CompanyCodeForm'

const CompanyCode = () => {
  const { t } = useTranslation()
  useEffect(() => {
    document.title = `${t('companyCode')} - ReportAll`
  }, [t])
  return (
    <PublicLayout>
      <CompanyCodeForm />
    </PublicLayout>
  )
}

export default CompanyCode
