import { toast } from 'react-toastify'
import axios from './api'

interface interceptorTypes {
  logout: () => void
  toastMsg: string
}

const setupInterceptor = ({ logout, toastMsg }: interceptorTypes) => {
  axios.interceptors.response.use(
    response => response,

    error => {
      const originalConfig = error.config
      if (originalConfig.url !== '/login/' && error.response) {
        if (error.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true
          logout()
          toast.error(toastMsg, {
            toastId: 'logoutId',
          })
        }
      }
      return Promise.reject(error)
    }
  )
}

export default setupInterceptor
