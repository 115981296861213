import docImg from '../../assets/doc-img.png'
import docxImg from '../../assets/docx-img.png'
import pdfImg from '../../assets/pdf-img.png'

interface Props {
  media: { id: number; file: string }
}

const CaseFilePreview = ({ media }: Props) => {
  let icon

  if (media.file?.endsWith('.pdf')) {
    icon = pdfImg
  }
  if (media.file?.endsWith('.docx')) {
    icon = docxImg
  }
  if (media.file?.endsWith('.doc')) {
    icon = docImg
  }

  return (
    <a href={media.file} download target='_blank' rel='noreferrer' className='h-24 w-[90px]'>
      <img src={icon} alt='' className='object-cover w-full h-full' />
    </a>
  )
}

export default CaseFilePreview
